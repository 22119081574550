import React from 'react';
import { FaEdit, FaEye, FaTrash } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import iconEmployee from '../../assets/icon_employee.jpg';

const EmployeeCard = ({
  employee,
  handleDeleteEmployee,
  handleUpdateEmployee,
  handleViewEmployee,
}) => {
  return (
    <div className='employeecard-div-card'>
      <span className='employeecard-span-email'> {employee.email}</span>
      <div className='employeecard-div-body'>
        <div className='employeecard-div-image-container'>
          <img
            className='employeecard-img'
            src={employee.imageUrl ? employee.imageUrl : iconEmployee}
            alt=''
          />
          <h3>
            {employee.name.length > 20
              ? employee.name.substring(0, 20) + '...'
              : employee.name}
          </h3>

          <div className='employeecard-div-actions'>
            {/* <Link to={`/employees/view/${employee.rego}`}>
                    <FaEye className="action-icon view-icon" tooltip="view" />
                </Link> */}

            <div
              className='employeecard-div-btn'
              onClick={() => handleViewEmployee(employee)}
            >
              <div className='employeecard-div-btn-outer-rim'>
                <div className='employeecard-div-btn-inner-rim'>
                  <i className='bi bi-eye-fill view-icon'></i>
                </div>
              </div>
            </div>

            {/* <Link to={`/employees/update/${employee.rego}`}>
                    <FaEdit className="action-icon edit-icon" tooltip="edit" />
                </Link> */}

            <div
              className='employeecard-div-btn'
              onClick={() => handleUpdateEmployee(employee)}
            >
              <div className='employeecard-div-btn-outer-rim'>
                <div className='employeecard-div-btn-inner-rim'>
                  <i class='bi bi-pencil-square edit-icon'></i>
                </div>
              </div>
            </div>

            <div
              className='employeecard-div-btn'
              onClick={() => handleDeleteEmployee(employee.empId)}
            >
              <div className='employeecard-div-btn-outer-rim'>
                <div className='employeecard-div-btn-inner-rim'>
                  <i class='bi bi-trash3-fill delete-icon'></i>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='employeecard-div-details'>
          {/* <h3>{employee.name}</h3> */}

          <div className='employeecard-div-content'>
            <span className='employeecard-div-content-name'>EmpId:</span>
            <span className='employeecard-div-content-value'>
              {employee.empId}
            </span>
          </div>

          <div className='employeecard-div-content'>
            <span className='employeecard-div-content-name'>Rego:</span>
            <span className='employeecard-div-content-value'>
              {employee.rego}
            </span>
          </div>

          <div className='employeecard-div-content'>
            <span className='employeecard-div-content-name'>Phone:</span>
            <span className='employeecard-div-content-value'>
              {employee.phone}
            </span>
          </div>

          <div className='employeecard-div-content'>
            <span className='employeecard-div-content-name'>Passcode:</span>
            <span className='employeecard-div-content-value'>
              {employee.passcode}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeCard;
