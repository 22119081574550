// LoadingSpinner.js
import React from 'react';

const LoadingSpinner = () => (
  <div className='loading-container'>
    <div className='loading-content'>
      <i className='fas fa-spinner fa-spin'></i>
      <p>Loading data...</p>
    </div>
  </div>
);

export default LoadingSpinner;
