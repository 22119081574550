import React from 'react';
import './help.css';
import logoImage from '../images/trucktronix icon.png';

const Help = () => {
  return (
    <div className='help-wrapper'>
      <div className='history-div-header'>
        <img src={logoImage} alt='Trucktronix' className='logo-image' />
        <h4>Help</h4>
      </div>

      <div className='contact-container'>
        <h2 className='contact-heading'>Contact Us</h2>
        <div className='customer-service'>
          <p>
            At Trucktronix, customer service is an integral part of our
            offering. We take pride in providing expert advice and continuous
            technical support to our valued clients. For further details on our
            on-board scale solutions, please don't hesitate to reach out to us.
          </p>
        </div>
        <div className='contact-info'>
          <p className='contact-item'>
            <span className='contact-label'>Phone:</span>{' '}
            <a href='tel:1300342147'>1300 342 147</a>
          </p>
          <p className='contact-item'>
            <span className='contact-label'>Email:</span>{' '}
            <a href='mailto:sales@haulweigh.com'>sales@haulweigh.com</a>,{' '}
            <a href='mailto:support@haulweigh.com'>support@haulweigh.com</a>
          </p>
          <p className='contact-item'>
            <span className='contact-label'>Mail:</span> PO BOX 294, HORSLEY
            PARK, 2175, NSW, AUSTRALIA
          </p>
          <p className='contact-item'>
            <span className='contact-label'>Trading Hours:</span> MON - FRI: 9AM
            - 5PM (AEST)
          </p>
          <p className='contact-item'>
            <span className='contact-label'>International:</span>{' '}
            <a href='tel:+61473911911'>+61473911911</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Help;
