import React, { useRef, useState } from 'react';
import './GeoFenceAdvancedSettings.css';
import { toast } from 'react-toastify';

const GeoFenceAdvancedSettings = ({
  setAdvancedGeoFence,
  setMarkVehicle,
  doneAdvancedfleetPlan,
  createAdvancedFleetPlan,
  triggerAdvancedGeoFenceCancel,
  closeAdvancedGeofence,
  currentPolygonCoordinatesRef,
}) => {
  const [isTriggerOnExit, setIsTriggerOnExit] = useState(true);
  const [color, setColor] = useState('#3a5f0b');

  const organizationId = sessionStorage.getItem('org_id');
  const geoFenceNameRef = useRef('');
  const geoFenceDescriptionRef = useRef('');
  const geoFenceTypeRef = useRef('setArea');
  const geoFenceTriggerRadiusRef = useRef(2);
  const geoFenceTriggerOnVehicleExitRef = useRef(true);
  const geoFenceColorRef = useRef('#3a5f0b');
  const geoFenceId = useRef('');

  const handleTriggerVehicleExit = () => {
    setIsTriggerOnExit(!isTriggerOnExit);
  };

  const handleColorChange = (e) => {
    setColor(e.target.value);
  };

  //convert hex to rgb for polygon creation later
  const convertHexToRgb = (hexColor) => {
    //remove hash
    hexColor = hexColor.replace(/^#/, '');

    //parse hex to rgb
    const bigint = parseInt(hexColor, 16);

    //extract rgb values
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    // Return the RGB values as an object
    return { r, g, b };
  };

  const handleFenceOnMap = () => {
    document.querySelector(
      '.geofenceadvanced-div-container-form'
    ).style.height = '0px';

    document.querySelector(
      '.geofenceadvanced-div-container-create'
    ).style.height = '100px';

    document.querySelector(
      '.geofenceadvanced-div-container-done'
    ).style.height = '0px';

    const { r, g, b } = convertHexToRgb(geoFenceColorRef.current.value);

    //create geofence polygon
    setAdvancedGeoFence(
      geoFenceNameRef.current.value,
      geoFenceTypeRef.current.value,
      {
        r,
        g,
        b,
      }
    );
    document.body.classList.add('crosshair-cursor');
  };

  const handleAdvancedGeoFenceCreate = () => {
    document.body.classList.remove('crosshair-cursor');

    //also save geofence related data to server
    postAdvancedPolygonToServer();
  };

  const handleAdvancedGeoFenceCancel = () => {
    document.querySelector(
      '.geofenceadvanced-div-container-form'
    ).style.height = '325px';

    document.querySelector(
      '.geofenceadvanced-div-container-create'
    ).style.height = '0px';

    document.querySelector(
      '.geofenceadvanced-div-container-done'
    ).style.height = '0px';

    document.body.classList.remove('crosshair-cursor');

    triggerAdvancedGeoFenceCancel();
  };

  const onSuccessPostFleetPlanPolygonToServer = () => {
    document.querySelector(
      '.geofenceadvanced-div-container-form'
    ).style.height = '0px';
    document.querySelector(
      '.geofenceadvanced-div-container-create'
    ).style.height = '0px';
    document.querySelector(
      '.geofenceadvanced-div-container-done'
    ).style.height = '100px';

    setMarkVehicle();

    //todo until this is triggered show loading
    createAdvancedFleetPlan('setArea', geoFenceId.current);
  };

  const handleDoneAdvancedFleetPlan = () => {
    //todo save geofenced vehicle rego data to server
    // postFleetPlanVehicleRegosToServer();

    closeAdvancedGeofence();
    document.body.classList.remove('crosshair-cursor');
    doneAdvancedfleetPlan('setArea');
  };

  const handleCloseAdvancedFleetPlan = () => {
    closeAdvancedGeofence();
    document.body.classList.remove('crosshair-cursor');
    doneAdvancedfleetPlan('setArea');
  };

  const postAdvancedPolygonToServer = async () => {
    try {
      if (
        !geoFenceNameRef.current.value ||
        !geoFenceTypeRef.current.value ||
        !geoFenceColorRef.current.value
      ) {
        toast.error('Please fill mandatory field!');
        return;
      }
      const geofenceData = {
        organizationId: organizationId,
        polygonName: geoFenceNameRef.current.value,
        polygonType: geoFenceTypeRef.current.value,
        polygonDescription: geoFenceDescriptionRef.current.value,
        triggerOnExit: geoFenceTriggerOnVehicleExitRef.current.checked,
        radius: parseInt(geoFenceTriggerRadiusRef.current.value),
        backgroundColor: geoFenceColorRef.current.value,
        coordinates: currentPolygonCoordinatesRef.current.map((element) => [
          element.latitude,
          element.longitude,
        ]),
      };
      console.log('Geo fence obj : ', geofenceData);
      const server = process.env.REACT_APP_API_SERVER;
      const username = process.env.REACT_APP_API_USERNAME;
      const password = process.env.REACT_APP_API_PASSWORD;
      const credentials = btoa(`${username}:${password}`);
      const response = await fetch(`${server}/geofence`, {
        method: 'POST',
        headers: {
          Authorization: `Basic ${credentials}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(geofenceData),
      });
      if (response.ok) {
        //Geofence data saved
        toast.success('Geofence created successfully');
        geoFenceId.current = await response.text();
        console.log('ujj geofence response polygon id: ' + geoFenceId.current);

        onSuccessPostFleetPlanPolygonToServer();
      } else {
        //handle error response
        console.log(
          'ujj geofence Error in saving Geofence Data: ',
          response.statusText
        );
        toast.error(
          'ujj geofence Failed to create geofence. Please try again.'
        );
      }
    } catch (error) {
      //Handle network errors or other exceptions
      console.error('ujj geofence Error saving geofence data:', error.message);
      toast.error(
        'ujj geofence Please check your internet connection and try again.'
      );
    }
  };

  return (
    <div className='geofenceadvanced-div-container-main'>
      <span className='geofenceadvanced-span-container-header'>
        <i
          className='bi bi-gear'
          style={{ color: 'yellow', fontSize: '18px' }}
        ></i>
        Fleet Plan Advanced Control
        <i
          className='bi bi-x-lg'
          style={{
            color: '#EC2D01',
            zIndex: 11,
            cursor: 'pointer',
            fontSize: '18px',
          }}
          onClick={handleCloseAdvancedFleetPlan}
        ></i>
      </span>
      <div className='geofenceadvanced-div-container-body'>
        <div className='geofenceadvanced-div-container-form'>
          <label style={{ marginLeft: '8px' }}>Geofence Name</label>
          <input
            type='text'
            id='gf-name'
            style={{ margin: '0px 8px 8px 8px' }}
            ref={geoFenceNameRef}
          />
          <label style={{ marginLeft: '8px' }}>Geofence Description</label>
          <textarea
            id='gf-description'
            style={{ margin: '0px 8px 8px 8px' }}
            ref={geoFenceDescriptionRef}
          />
          <div>
            <label>Geofence Type</label>
            <select id='gf-type' ref={geoFenceTypeRef}>
              <option value='setArea'>Area</option>
              <option value='setLine'>Line</option>
              <option value='setPoint'>Point</option>
            </select>
          </div>
          <div>
            <span>Geofence Trigger radius (in m)</span>
            <input
              type='number'
              min='0'
              style={{ width: '100px', marginRight: '8px' }}
              ref={geoFenceTriggerRadiusRef}
            />
          </div>
          <div>
            <span>Geofence Trigger on vehicle exit</span>
            <input
              type='checkbox'
              checked={isTriggerOnExit}
              onChange={handleTriggerVehicleExit}
              style={{ marginRight: '10px', paddingTop: '4px' }}
              ref={geoFenceTriggerOnVehicleExitRef}
            />
          </div>
          <div>
            <span>Geofence color</span>
            <input
              type='color'
              style={{ width: '100px', marginRight: '8px' }}
              value={color}
              onChange={handleColorChange}
              ref={geoFenceColorRef}
            />
          </div>

          <div
            className='am-div-btn'
            style={{ width: '120px', alignSelf: 'center' }}
            onClick={handleFenceOnMap}
          >
            <div className='am-div-btn-outer-rim'>
              <div className='am-div-btn-inner-rim'>Fence on Map</div>
            </div>
          </div>
        </div>

        <div className='geofenceadvanced-div-container-create'>
          <span>Mark locations on map and press create...</span>

          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div
              className='am-div-btn'
              style={{ width: '120px', alignSelf: 'center' }}
              onClick={handleAdvancedGeoFenceCreate}
            >
              <div className='am-div-btn-outer-rim'>
                <div className='am-div-btn-inner-rim'>Create</div>
              </div>
            </div>

            <div
              className='am-div-btn'
              style={{ width: '120px', alignSelf: 'center' }}
              onClick={handleAdvancedGeoFenceCancel}
            >
              <div className='am-div-btn-outer-rim'>
                <div className='am-div-btn-inner-rim'>Cancel</div>
              </div>
            </div>
          </div>
        </div>

        <div className='geofenceadvanced-div-container-done'>
          <span>Select vehicles on map and press done...</span>

          <div
            className='am-div-btn'
            style={{ width: '120px', alignSelf: 'center' }}
            onClick={handleDoneAdvancedFleetPlan}
          >
            <div className='am-div-btn-outer-rim'>
              <div className='am-div-btn-inner-rim'>Done</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeoFenceAdvancedSettings;
