import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './MyProfile.css';
import { toast } from 'react-toastify';
import logoImage from '../images/trucktronix icon.png';
import iconEmployee from '../../assets/icon_employee.jpg';
import { icon } from 'leaflet';

const Profile = () => {
  const [info, setInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate('');

  const goBackHandler = () => {
    navigate('/');
  };

  const email = sessionStorage.getItem('user_email');
  const organizationId = sessionStorage.getItem('org_id');

  const fetchProfileData = async () => {
    try {
      const server = process.env.REACT_APP_API_SERVER;
      // const server = "http://localhost:4567"

      const username = process.env.REACT_APP_API_USERNAME;
      const password = process.env.REACT_APP_API_PASSWORD;
      const credentials = btoa(`${username}:${password}`);
      const response = await fetch(
        `${server}/orgs/${organizationId}/${email}`,
        {
          // const response = await fetch(`${server}/organization/${email}`, {
          method: 'GET',
          headers: {
            Authorization: `Basic ${credentials}`,
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        setInfo(data);
      } else {
        toast.error(`Error fetching data: ${response.status}`);
      }
    } catch (error) {
      toast.error(`Error fetching data: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProfileData();
  }, []);

  return (
    <div className='user-profile-div-container'>
      <div className='user-profile-div-header'>
        <img src={logoImage} alt='Trucktronix' className='logo-image' />
        <h4>Admin Profile</h4>
      </div>
      <div className='profile-container'>
        <div className='fleetmanager-div-body-details-outer-rim'>
          <div className='fleetmanager-div-body-details-inner-rim'>
            <div className='profile-header'>
              <Link to={`/profile/update`} style={{ textDecoration: 'none' }}>
                <div className='user-profile-btn'>
                  <div className='user-profile-div-btn-outer-rim'>
                    <div className='user-profile-div-btn-inner-rim'>
                      <span>Edit</span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>

            {loading ? (
              <div className='loading-container'>
                <div className='loading-content'>
                  <i className='fas fa-spinner fa-spin'></i>
                  <p>Loading data...</p>
                </div>
              </div>
            ) : info ? (
              <div className='profile-content'>
                <img
                  src={info.imageUrl ? info.imageUrl : iconEmployee}
                  alt={info.name || 'Profile'}
                  className='profile-img'
                />
                <div className='profile-details'>
                  <h2 className='profile-title'>{info.orgName}</h2>
                  <div className='detail-item'>
                    <span className='item-key'>Org ID:</span>
                    <span className='item-value'>
                      {info.organizationId || organizationId}
                    </span>
                  </div>
                  <div className='detail-item'>
                    <span className='item-key'>Email:</span>
                    <span className='item-value'>
                      {info.email ? info.email : email}
                    </span>
                  </div>

                  <div className='detail-item'>
                    <span className='item-key'> Name:</span>
                    <span className='item-value'>{info.name}</span>
                  </div>
                  <div className='detail-item'>
                    <span className='item-key'>Phone:</span>
                    <span className='item-value'>{info.phone}</span>
                  </div>
                  <div className='detail-item'>
                    <span className='item-key'>Address:</span>
                    <span className='item-value'>{info.address}</span>
                  </div>
                  <div className='detail-item'>
                    <span className='item-key'>Role:</span>
                    <span className='item-value'>{info.role}</span>
                  </div>
                  <div className='mp-button-container'>
                    <div
                      className='user-profile-div-btn add'
                      onClick={() => navigate('/password/update')}
                    >
                      <div className='user-profile-outer-rim'>
                        <div className='user-profile-inner-rim'>
                          Change Password
                        </div>
                      </div>
                    </div>

                    <div
                      className='user-profile-div-btn cancel'
                      onClick={goBackHandler}
                    >
                      <div className='user-profile-outer-rim'>
                        <div className='user-profile-inner-rim'>
                          Return Home
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <p>No data available.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
