// Import necessary dependencies
import React, { useEffect, useState } from 'react';
import './GeofenceListModal.css';
import { toast } from 'react-toastify';

const GeofenceListModal = ({
  listVehiclePolygonKeyValue,
  onClose,
  recenterMapToGeoFence,
  geoFenceDeleteFromList,
  removeVehiclePolygonPair,
}) => {
  const [geofenceData, setGeofenceData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const organizationId = sessionStorage.getItem('org_id');
  const [gflListVehiclePolygonKeyValue, setGflListVehiclePolygonKeyValue] =
    useState([]);

  useEffect(() => {
    console.log('ujj key' + listVehiclePolygonKeyValue.length);
    setGflListVehiclePolygonKeyValue(listVehiclePolygonKeyValue);
  }, [listVehiclePolygonKeyValue]);

  //for extra info in table
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [GFLInfoAnimation, setGFLInfoAnimation] = useState('');
  useEffect(() => {
    // Fetch geofence data from the server
    fetchGeofencesFromServer();
  }, []);

  const fetchGeofencesFromServer = async () => {
    try {
      const server = process.env.REACT_APP_API_SERVER;
      const username = process.env.REACT_APP_API_USERNAME;
      const password = process.env.REACT_APP_API_PASSWORD;

      const credentials = btoa(`${username}:${password}`);
      const response = await fetch(`${server}/geofence/${organizationId}`, {
        method: 'GET',
        headers: {
          Authorization: `Basic ${credentials}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setGeofenceData(data);
      } else if (response.status === 404) {
        toast.info('Please add geofence first!');
      } else {
        toast.error(
          `An error occurred while fetching geofence data: ${response.status}`
        );
      }
    } catch (error) {
      toast.error(`Error: ${error.message}`);
      // console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleView = (geofenceId, e) => {
    e.stopPropagation(); // Stop propagation to prevent handleInfoView from firing
    //Find the geofence with the given ID
    const geofence = geofenceData.find(
      (geofence) => geofence.polygonId === geofenceId
    );

    //Check if the geofence is found
    if (geofence) {
      //Show the coordinates in an alert or any other desired way
      // alert(`Coordinates: ${geofence.coordinates}`);
      //   alert(JSON.stringify(geofence.coordinates));
      recenterMapToGeoFence(geofence.coordinates);
    } else {
      alert('No such Geofence Found');
    }
  };

  const handleInfoView = (geofenceId, index) => {
    setSelectedRowId(geofenceId === selectedRowId ? null : geofenceId);
    console.log('ujj index: ' + index);
    setGFLInfoAnimation(''); //reset

    setTimeout(() => {
      if (selectedRowId !== geofenceId) {
        setGFLInfoAnimation('visible');

        var rows = document.querySelectorAll('#id-gfm-table tr');

        // line is zero-based
        // line is the row number that you want to see into view after scroll
        //need to do +2 to focus after trial
        rows[index + 2].scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      } else {
        setGFLInfoAnimation('');
      }
    }, 300); // Ensure this matches the transition duration
  };

  const handleDelete = async (geofenceId, e) => {
    e.stopPropagation(); // Stop propagation to prevent handleInfoView from firing
    // Show confirmation dialog before proceeding with the deletion
    if (!window.confirm('Are you sure you want to delete this geofence?'))
      return;

    geoFenceDeleteFromList(geofenceId);
    //Remove the deleted geofence from the local state
    setGeofenceData((prevGeofences) =>
      prevGeofences.filter((geofence) => geofence.polygonId !== geofenceId)
    );
  };

  const handleRemoveVehicleFromPolygon = (vehicleId) => {
    deleteServerVehicleFromPolygon(vehicleId);
  };

  const deleteServerVehicleFromPolygon = async (vehicleId) => {
    if (vehicleId !== '') {
      try {
        const server = process.env.REACT_APP_API_SERVER;
        const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const credentials = btoa(`${username}:${password}`);
        const response = await fetch(
          `${server}/association/${organizationId}/${vehicleId}`,
          {
            method: 'DELETE',
            headers: {
              Authorization: `Basic ${credentials}`,
            },
          }
        );

        if (response.ok) {
          toast.success(vehicleId + ' has been removed');
          //remove geo fence association from map too

          //todo
          removeVehiclePolygonPair(vehicleId);
        }
      } catch (error) {
        console.error('error deleting geo fences' + error);
      }
    }
  };

  const filteredGeofences = geofenceData.filter(
    (geofence) =>
      geofence.polygonId.toLowerCase().includes(searchQuery.toLowerCase()) ||
      geofence.polygonName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className='geofencelist-div-container-main'>
      <span className='geofencelist-span-container-header'>
        Geofence List
        <input
          type='text'
          className='gfm-search-bar'
          placeholder='Search by Polygon ID or Name ...'
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <i
          className='bi bi-x-lg'
          style={{
            color: '#EC2D01',
            zIndex: 11,
            cursor: 'pointer',
            fontSize: '18px',
          }}
          onClick={onClose}
        ></i>
      </span>
      <div
        className={`geofencelist-div-container-body ${isLoading ? '' : 'data'}`}
      >
        {isLoading ? (
          // Render a loading indicator while data is being fetched
          <div>Loading...</div>
        ) : (
          <table id='id-gfm-table' className='gfm-table'>
            <thead>
              <tr>
                <th>Polygon ID</th>
                <th>Name</th>
                <th>Type</th>
                <th>Description</th>
                <th>Radius</th>
                {/* <th>Regos</th> */}
                {/* <th>Coordinates</th> */}
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredGeofences.map((geofence, index) => (
                <React.Fragment key={index}>
                  <tr onClick={() => handleInfoView(geofence.polygonId, index)}>
                    <td>{geofence.polygonId}</td>
                    <td>{geofence.polygonName}</td>
                    <td>{geofence.polygonType}</td>
                    <td>{geofence.polygonDescription}</td>
                    <td>{geofence.radius}</td>
                    {/* <td>{geofence.regos?.join(", ")}</td> */}
                    {/* <td>{geofence.coordinates.join(', ')}</td> */}
                    <td
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                      }}
                    >
                      <div
                        className='gfm-div-button'
                        style={{ top: '8px', right: '20px' }}
                        onClick={(e) => handleView(geofence.polygonId, e)}
                      >
                        <div className='button-div-btn-outer-rim'>
                          <div className='button-div-btn-inner-rim view-btn'>
                            <i className='bi bi-eye-fill'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className='gfm-div-button'
                        style={{ top: '8px', right: '20px' }}
                        onClick={(e) => handleDelete(geofence.polygonId, e)}
                      >
                        <div className='button-div-btn-outer-rim'>
                          <div className='button-div-btn-inner-rim delete-btn'>
                            <i className='bi bi-trash3-fill'></i>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  {selectedRowId === geofence.polygonId && (
                    <tr
                      key={`${geofence.polygonId}-info-box`}
                      className={`gfl-div-info-box ${GFLInfoAnimation}`}
                    >
                      <td colSpan='6'>
                        <div className='gfl-span-info-box-header'>
                          Vehicles associated
                        </div>
                        <div className='gfl-div-info-vehicles-box'>
                          {gflListVehiclePolygonKeyValue
                            .filter((pair) => pair.value === geofence.polygonId)
                            .map((pair) => (
                              <div
                                key={pair.key}
                                className='gfl-div-info-vehicle-box-card'
                              >
                                <span>{pair.key}</span>

                                <div
                                  className='gfl-div-info-vehicle-remove'
                                  onClick={() => {
                                    handleRemoveVehicleFromPolygon(pair.key);
                                  }}
                                >
                                  <span>Remove</span>
                                </div>
                              </div>
                            ))}
                        </div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default GeofenceListModal;
