import React from 'react';
import './CustomPrompt.css';

const CustomPrompt = ({ message, onClose, onDone }) => {
  return (
    <div className='customprompt-div-container'>
      <div className='customprompt-div-header'>
        <span>{message}</span>
      </div>
      <div className='customprompt-div-body'>
        <div className='customprompt-div-footer'>
          <div
            className='user-profile-div-btn cancel'
            style={{ height: '40px' }}
            onClick={onClose}
          >
            <div className='user-profile-outer-rim'>
              <div
                className='user-profile-inner-rim'
                style={{ color: '#0f4703', background: 'white' }}
              >
                Yes
              </div>
            </div>
          </div>

          <div
            className='user-profile-div-btn add'
            style={{ height: '40px' }}
            onClick={onDone}
          >
            <div className='user-profile-outer-rim'>
              <div className='user-profile-inner-rim'>Done</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomPrompt;
