import React, { useEffect, useState } from 'react';
import AddVehicleModal from './AddVehicleModal';
import './VehicleTable.css';
import defaultTruckImg from '../../assets/logo_vehicle.png';
import { toast } from 'react-toastify';
import ViewVehicleModal from './ViewVehicleModal';
import UpdateVehicleModal from './UpdateVehicleModal';

const VehicleTable = ({ updateVehiclesCount }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [vehicles, setVehicles] = useState([]);
  const [searchVehicle, setSearchVehicle] = useState('');
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [modalAnimation, setModalAnimation] = useState(''); //for close animation

  const openUpdateModal = (vehicle) => {
    setSelectedVehicle(vehicle);
    setIsUpdateModalOpen(true);
    setModalAnimation('');
  };

  const closeUpdateModal = () => {
    setModalAnimation('slideOut');
    setTimeout(() => {
      setIsUpdateModalOpen(false);
      setSelectedVehicle(null);
    }, 600); // Ensure this matches the transition duration
  };

  const organizationId = sessionStorage.getItem('org_id');

  const openViewModal = (vehicle) => {
    setSelectedVehicle(vehicle);
    setIsViewModalOpen(true);
    setModalAnimation('');
  };

  const closeViewModal = () => {
    setModalAnimation('slideOut');
    setTimeout(() => {
      setSelectedVehicle(null);
      setIsViewModalOpen(false);
    }, 600);
  };

  const fetchData = async () => {
    try {
      const server = process.env.REACT_APP_API_SERVER;
      // const server = 'http://localhost:4567';

      const username = process.env.REACT_APP_API_USERNAME;
      const password = process.env.REACT_APP_API_PASSWORD;

      const credentials = btoa(`${username}:${password}`);
      const response = await fetch(`${server}/vehicles/${organizationId}`, {
        method: 'GET',
        headers: {
          Authorization: `Basic ${credentials}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setVehicles(data);
        updateVehiclesCount(data.length);
      } else if (response.status === 404) {
        toast.info('Please add vehicles first!');
      } else {
        toast.error(
          `An error occurred while fetching employee data: ${response.status}`
        );
      }
    } catch (error) {
      toast.error(`Error: ${error.message}`);
      // console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleVehicleAdded = () => {
    // This function will be called when a new vehicle is added
    // Refresh the vehicle list by fetching the data again
    fetchData();
  };
  const handleUpdateVehicle = (updatedVehicle) => {
    // Update the vehicles list with the updated vehicle
    fetchData();
  };

  const handleSearchVehicle = (e) => {
    setSearchVehicle(e.target.value);
  };
  //Filter Vehicle based on the search term
  const filteredVehicles = vehicles.filter(
    (vehicle) =>
      vehicle.vehicleId.toLowerCase().includes(searchVehicle.toLowerCase())
    //   ||
    //   vehicle.rego.toLowerCase().includes(searchVehicle.toLowerCase())
  );
  // useEffect(() => {
  //   console.log('Search Term:', searchVehicle);
  //   console.log('Filtered vehicle:', filteredVehicles);
  // }, [searchVehicle, filteredVehicles]);

  useEffect(() => {
    fetchData();
  }, []);

  const openVehicleModal = () => {
    setIsModalOpen(true);
    setModalAnimation('');
  };
  const closeVehicleModal = () => {
    setModalAnimation('slideOut');
    setTimeout(() => {
      setIsModalOpen(false);
    }, 600); // Ensure this matches the transition duration
  };
  const handleDeleteVehicle = async (rego) => {
    if (!window.confirm('Are you sure you want to delete this vehicle?'))
      return;
    try {
      const server = process.env.REACT_APP_API_SERVER;
      // const server = 'http://localhost:4567';
      const username = process.env.REACT_APP_API_USERNAME;
      const password = process.env.REACT_APP_API_PASSWORD;

      const credentials = btoa(`${username}:${password}`);

      const response = await fetch(
        `${server}/vehicles/${organizationId}/${rego}`,
        {
          method: 'DELETE',
          headers: {
            Authorization: `Basic ${credentials}`,
          },
        }
      );
      if (response.ok) {
        setVehicles((prevVehicles) =>
          prevVehicles.filter((vehicle) => vehicle.vehicleId !== rego)
        );
        updateVehiclesCount(vehicles.length - 1);
        toast.success('Vehicle deleted successfully!');
      } else if (response.status === 404) {
        toast.error('Vehicle not found in db.');
      } else {
        toast.error('Failed to delete vehicle!');
      }
    } catch (error) {
      toast.error(`Error:${error.message}`);
    }
  };

  return (
    <div className='vehiclestable-div-main-container'>
      <div className='vehiclestable-div-header'>
        <span>Vehicles</span>
        {/* <div className='logo-container'>
                    <img
                        src={logoImage}
                        alt="Company Logo"
                        className="logo-image"
                    />
                    <h4>Fleet Manager </h4>
                </div>  */}
        <input
          className='vehiclestable-input-search'
          type='text'
          placeholder='Search Vehicle by rego...'
          value={searchVehicle}
          onChange={handleSearchVehicle}
        />

        <div
          className='vehiclestable-btn-add-vehicle'
          onClick={openVehicleModal}
        >
          <div className='vehiclecard-div-btn-outer-rim'>
            <div className='vehiclecard-div-btn-inner-rim'>Add Vehicle</div>
          </div>
        </div>
      </div>
      {isLoading ? (
        <div className='loading-container'>
          <div className='loading-content'>
            <i className='fas fa-spinner fa-spin'></i>
            <p>Loading data...</p>
          </div>
        </div>
      ) : filteredVehicles.length === 0 ? (
        <p className='vc-no-data'>No vehicles to display</p>
      ) : (
        <div className='vehiclestable-div-list'>
          {filteredVehicles.map((vehicle) => (
            <VehicleCard
              key={vehicle.vehicleId}
              vehicle={vehicle}
              handleDeleteVehicle={handleDeleteVehicle}
              onViewClick={() => openViewModal(vehicle)}
              onUpdateClick={() => openUpdateModal(vehicle)}
            />
          ))}
        </div>
      )}
      {isModalOpen && (
        <div className={`modal ${modalAnimation}`}>
          <AddVehicleModal
            onClose={closeVehicleModal}
            onVehicleAdded={handleVehicleAdded} // Pass the callback function
          />
        </div>
      )}

      {isViewModalOpen && (
        <div className={`modal ${modalAnimation}`}>
          <ViewVehicleModal
            vehicle={selectedVehicle}
            onClose={closeViewModal}
          />
        </div>
      )}

      {isUpdateModalOpen && (
        <div className={`modal ${modalAnimation}`}>
          <UpdateVehicleModal
            onClose={closeUpdateModal}
            onUpdate={handleUpdateVehicle}
            vehicle={selectedVehicle}
          />
        </div>
      )}
    </div>
  );
};
const VehicleCard = ({
  vehicle,
  handleDeleteVehicle,
  onViewClick,
  onUpdateClick,
}) => {
  return (
    <div className='vehiclecard-div-card'>
      <div className='vehiclecard-div-image-container'>
        <img
          className='vehiclecard-img'
          src={vehicle.imageUrl ? vehicle.imageUrl : defaultTruckImg}
          alt=''
        />
        <h3>{vehicle.vehicleId}</h3>

        <div className='vehiclecard-div-actions'>
          <div className='vehiclecard-div-btn' onClick={onViewClick}>
            <div className='vehiclecard-div-btn-outer-rim'>
              <div className='vehiclecard-div-btn-inner-rim'>
                <i className='bi bi-eye-fill view-icon'></i>
              </div>
            </div>
          </div>
          <div className='vehiclecard-div-btn' onClick={onUpdateClick}>
            <div className='vehiclecard-div-btn-outer-rim'>
              <div className='vehiclecard-div-btn-inner-rim'>
                <i class='bi bi-pencil-square edit-icon'></i>
              </div>
            </div>
          </div>

          <div
            className='vehiclecard-div-btn'
            onClick={() => handleDeleteVehicle(vehicle.vehicleId)}
          >
            <div className='vehiclecard-div-btn-outer-rim'>
              <div className='vehiclecard-div-btn-inner-rim'>
                <i class='bi bi-trash3-fill delete-icon'></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='vehiclecard-div-details'>
        <div className='vehiclecard-div-content-body'>
          <div className='vehiclecard-div-content'>
            <span className='vehiclecard-div-content-name'>
              Steer Max Weight:
            </span>
            <span className='vehiclecard-div-content-value'>
              {vehicle.steerMaxWeight}{' '}
              <span style={{ color: '#87ceeb', fontSize: '12px' }}>
                {vehicle.weightUnit}
              </span>
            </span>
          </div>

          <div className='vehiclecard-div-content'>
            <span className='vehiclecard-div-content-name'>
              Drive Max Weight:
            </span>
            <span className='vehiclecard-div-content-value'>
              {vehicle.driveMaxWeight}{' '}
              <span style={{ color: '#87ceeb', fontSize: '12px' }}>
                {vehicle.weightUnit}
              </span>
            </span>
          </div>

          <div className='vehiclecard-div-content'>
            <span className='vehiclecard-div-content-name'>
              Trailer A Max Weight:
            </span>
            <span className='vehiclecard-div-content-value'>
              {vehicle.trailerAMaxWeight}{' '}
              <span style={{ color: '#87ceeb', fontSize: '12px' }}>
                {vehicle.weightUnit}
              </span>
            </span>
          </div>

          <div className='vehiclecard-div-content'>
            <span className='vehiclecard-div-content-name'>
              Trailer B Max Weight:
            </span>
            <span className='vehiclecard-div-content-value'>
              {vehicle.trailerBMaxWeight}{' '}
              <span style={{ color: '#87ceeb', fontSize: '12px' }}>
                {vehicle.weightUnit}
              </span>
            </span>
          </div>

          <div className='vehiclecard-div-content'>
            <span className='vehiclecard-div-content-name'>
              Working Voltage:
            </span>
            <span className='vehiclecard-div-content-value'>
              {vehicle.workingVoltage}{' '}
              <span style={{ color: '#87ceeb', fontSize: '12px' }}>
                {vehicle.voltageUnit}
              </span>
            </span>
          </div>

          <div className='vehiclecard-div-content'>
            <span className='vehiclecard-div-content-name'>
              Max Fuel Capacity:
            </span>
            <span className='vehiclecard-div-content-value'>
              {vehicle.maxFuelCapacity}{' '}
              <span style={{ color: '#87ceeb', fontSize: '12px' }}>
                {vehicle.fuelCapacityUnit}
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default VehicleTable;
