import Login from './pages/Login/Login';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NewUser from './pages/Login/NewUser';
import ResetPassword from './pages/Login/ResetPassword';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import LiveMap from './pages/homepage/LiveMap';
import PrivateRoute from './PrivateRoute';
import FleetTable from './pages/fleet-table/FleetTable';
import Profile from './pages/profile/MyProfile';
import UpdateProfile from './pages/profile/UpdateProfile';
import ChangePassword from './pages/profile/ChangePassword';
import History from './pages/history/History';
import FleetManager from './pages/fleet-manager/FleetManager';
import PlayHistory from './pages/history/PlayHistory';
import HelpPage from './pages/help/Help';

function App() {
  return (
    <div className='App'>
      <ToastContainer
        position='top-right'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='light'
      />

      <Router>
        <Routes>
          <Route exact path='/login' element={<Login />} />
          <Route path='/newuser' element={<NewUser />} />
          <Route path='/reset' element={<ResetPassword />} />
          <Route element={<PrivateRoute />}>
            <Route exact path='/' element={<LiveMap />} />
            <Route exact path='/fleet-table' element={<FleetTable />} />
            <Route exact path='/history' element={<History />} />
            <Route path='/my-profile' element={<Profile />} />
            <Route path='/profile/update/' element={<UpdateProfile />} />
            <Route path='/password/update' element={<ChangePassword />} />
            <Route path='/play-history' element={<PlayHistory />} />
            <Route path='/fleet-manager' element={<FleetManager />} />
            <Route path='/help' element={<HelpPage />} />
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
