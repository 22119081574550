import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import vehicleLogo from '../../assets/logo_vehicle.png';
import iconEmployee from '../../assets/icon_employee.jpg';
import moment from 'moment-timezone';

const MarkerBox = ({
  markerData,
  onClose,
  markerPosition,
  updatedMarkerData,
}) => {
  const organizationId = sessionStorage.getItem('org_id');
  const [currentEmployee, setCurrentEmployee] = useState(null);

  // const [formattedAddress, setFormattedAddress] = useState('');
  const [isVisible, setIsVisible] = useState(false);
  const [isFirstVisible, setisFirstVisible] = useState(false);

  const [position, setPosition] = useState({
    top: markerPosition.top,
    left: markerPosition.left,
  });
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    // Initial state
    const initialAnimationTimeout = setTimeout(() => {
      setIsVisible(true);
      setisFirstVisible(true);
    }, 100); // Adjust the delay based on your needs

    // Animation to 'scaleY(1) translateY(0)' on first load
    const firstLoadAnimationTimeout = setTimeout(() => {
      setIsVisible(true);
      setisFirstVisible(false);
    }, 500); // Adjust the delay based on the duration of your animation

    fetchDataByRego(markerData.empId);

    // Cleanup function to clear timeouts if the component unmounts
    return () => {
      clearTimeout(initialAnimationTimeout);
      clearTimeout(firstLoadAnimationTimeout);
    };
  }, []);

  const fetchDataByRego = async (empId) => {
    try {
      const server = process.env.REACT_APP_API_SERVER;
      const username = process.env.REACT_APP_API_USERNAME;
      const password = process.env.REACT_APP_API_PASSWORD;
      const credentials = btoa(`${username}:${password}`);

      const response = await fetch(
        `${server}/employees/${organizationId}/${empId}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Basic ${credentials}`,
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        setCurrentEmployee(data);
        // Handle the retrieved data as needed
      } else {
        // console.log(`Error fetching data: ${response.status}`);
      }
    } catch (error) {
      // console.log(`Error fetching data: ${error.message}`);
      setCurrentEmployee(null);
    }
  };

  //convert utc timestamp from server to localtimestamp
  const getLocalTimestamp = (utcTimestamp) => {
    return moment
      .utc(utcTimestamp, 'MMM D, YYYY, h:mm:ss A')
      .local()
      .format('MMM D, YYYY, h:mm:ss A');
  };

  useEffect(() => {
    // Delay the setting of isVisible to true to allow for the transition effect

    if (isDragging) {
      document.addEventListener('mouseup', handleMouseUp);
    }

    return () => {
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging]);

  const handleMouseDown = (e) => {
    e.preventDefault();
    setDragStart({ x: e.clientX, y: e.clientY });
    setIsDragging(true);
  };

  const handleMouseMove = (e) => {
    if (isDragging) {
      const newPosition = {
        top: position.top + e.clientY - dragStart.y,
        left: position.left + e.clientX - dragStart.x,
      };
      // Ensure the marker stays within the screen boundaries
      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;

      if (newPosition.left < 0) {
        newPosition.left = 0;
      } else if (newPosition.left + 50 > screenWidth) {
        newPosition.left = screenWidth - 50;
      }

      if (newPosition.top < 0) {
        newPosition.top = 0;
      } else if (newPosition.top + 50 > screenHeight) {
        newPosition.top = screenHeight - 50;
      }

      setPosition(newPosition);
      setDragStart({ x: e.clientX, y: e.clientY });
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  let markerStatus;

  if (updatedMarkerData) {
    markerStatus = updatedMarkerData.status;
  } else {
    markerStatus = markerData.status;
  }

  const activeStatusStyle = {
    backgroundColor: '#2d425f',
    color: 'white',
  };

  const inActiveStatusStyle = {
    backgroundColor: '#daebfb',
    color: 'black',
  };

  const markerBoxContainerStyle = {
    top: position.top,
    left: position.left,
    transform: isVisible
      ? isFirstVisible
        ? 'scaleY(1) translateY(0)'
        : 'none'
      : 'scaleY(0.3) translateY(-230px)',
    opacity: isVisible ? 1 : 0,
    transition: isVisible
      ? isFirstVisible
        ? 'all 0.5s ease'
        : 'none'
      : 'all 0.5s ease',
  };

  return (
    <div
      className={`marker-details-container ${isVisible ? 'active' : ''}`}
      id='dragdiv'
      style={markerBoxContainerStyle}
    >
      <div
        id='dragdivheader'
        className='marker-details-header'
        onMouseDown={handleMouseDown}
      >
        <i className='bi bi-info-circle-fill' style={{ color: '#cccc00' }}></i>

        <span>Fleet &#xB7; {markerData.vehicleId}</span>
        <i
          className='bi bi-x-lg'
          style={{ color: '#EC2D01', zIndex: 11, cursor: 'pointer' }}
          onClick={onClose}
        ></i>
      </div>

      <div className='marker-details-body'>
        <div className='marker-details-vehicle'>
          <div className='marker-details-image'>
            {currentEmployee && currentEmployee.imageUrl ? (
              <img
                src={currentEmployee.imageUrl}
                alt={currentEmployee.name}
                style={{ objectFit: 'contain' }}
              />
            ) : (
              <img
                src={iconEmployee}
                alt='employee'
                style={{ objectFit: 'contain' }}
              />
            )}
          </div>

          <img src={vehicleLogo} alt='Vehicle Logo' />
        </div>
        <span>
          {updatedMarkerData ? updatedMarkerData.address : markerData?.address}
          {/* {updatedMarkerData ? updatedMarkerData.address : formattedAddress} */}
        </span>

        <div></div>
      </div>
      <div className='marker-details-foot'>
        <div className='marker-status-rectangle'>
          <div
            className='marker-status-rectangle-text'
            style={{
              ...(markerStatus === 'online'
                ? activeStatusStyle
                : inActiveStatusStyle),
            }}
          >
            <div
              className={`marker-status-circle ${
                markerStatus === 'online' ? 'online' : ''
              }`}
            ></div>
            Online
          </div>
          <div
            className='marker-status-rectangle-text'
            style={{
              ...(markerStatus === 'paused'
                ? activeStatusStyle
                : inActiveStatusStyle),
            }}
          >
            <div
              className={`marker-status-circle ${
                markerStatus === 'paused' ? 'paused' : ''
              }`}
            ></div>
            Paused
          </div>
          <div
            className='marker-status-rectangle-text'
            style={{
              ...(markerStatus === 'offline'
                ? activeStatusStyle
                : inActiveStatusStyle),
            }}
          >
            <div
              className={`marker-status-circle ${
                markerStatus === 'offline' ? 'offline' : ''
              }`}
            ></div>
            Offline
          </div>
        </div>
        <span>
          Last Update:{' '}
          {getLocalTimestamp(
            updatedMarkerData
              ? updatedMarkerData.timestamp
              : markerData?.timestamp
          )}
        </span>
      </div>

      {isDragging && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            cursor: 'grabbing',
          }}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
        />
      )}
    </div>
  );
};

MarkerBox.propTypes = {
  markerData: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  markerPosition: PropTypes.object.isRequired,
};

export default MarkerBox;
