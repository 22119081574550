import React from 'react';
import './ViewEmployeeModal.css'; // Import the corresponding CSS file for styling

const ViewEmployeeModal = ({ onClose, employee }) => {
  return (
    <div className='view-employee-modal'>
      {/* <h3>View Employee</h3> */}
      <div className='vem-img-container'>
        <img
          className='vem-img-Employee'
          src={
            employee.imageUrl ||
            'https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg'
          }
          alt=''
        />
      </div>
      <div className='vem-input-container'>
        <label>Org ID:</label>
        <span>{employee.organizationId}</span>
      </div>
      <div className='vem-input-container'>
        <label>Emp ID:</label>
        <span>{employee.empId}</span>
      </div>
      <div className='vem-input-container'>
        <label>Name:</label>
        <span>{employee.name}</span>
      </div>
      <div className='vem-input-container'>
        <label>Email:</label>
        <span>{employee.email}</span>
      </div>
      <div className='vem-input-container'>
        <label>Rego:</label>
        <span>{employee.rego}</span>
      </div>
      <div className='vem-input-container'>
        <label>Phone:</label>
        <span>{employee.phone}</span>
      </div>
      <div className='vem-input-container'>
        <label>Passcode:</label>
        <span>{employee.passcode}</span>
      </div>
      {employee.addedOn ? (
        <div className='vem-input-container'>
          <label>Added On:</label>
          <span>{employee.addedOn}</span>
        </div>
      ) : (
        ''
      )}
      {employee.lastUpdatedAt ? (
        <div className='vem-input-container'>
          <label>Last Updated At:</label>
          <span>{employee.lastUpdatedAt}</span>
        </div>
      ) : (
        ''
      )}
      {/* Add other details as needed */}
      <div className='vem-btnContainer'>
        <div className='aem-div-btn cancel' onClick={onClose}>
          <div className='aem-outer-rim'>
            <div className='aem-inner-rim'>Close</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewEmployeeModal;
