import React from 'react';
import defaultTruckImg from '../../assets/logo_vehicle.png';

import './ViewVehicleModal.css'; // Create a corresponding CSS file for styling

const ViewVehicleModal = ({ vehicle, onClose }) => {
  // Define a function to format the date
  function formatDate(dateString) {
    const date = new Date(dateString);
    // Extract year, month, and day components
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Add leading zero if needed
    const day = String(date.getDate()).padStart(2, '0'); // Add leading zero if needed
    // Combine components to form the formatted date string
    return `${year}-${month}-${day}`;
  }
  return (
    <div className='view-vehicle-modal'>
      {/* <h3>View Vehicle</h3> */}

      <div className='vvm-img-container'>
        <img
          className='vvm-img-Vehicle'
          // src={vehicle.imageUrl}
          src={vehicle.imageUrl ? vehicle.imageUrl : defaultTruckImg}
          alt='Vehicle'
        />
      </div>
      <div className='vvm-details-container'>
        <div className='vvm-field'>
          <label>Rego:</label>
          <span>{vehicle.vehicleId}</span>
        </div>
        <div className='vvm-field'>
          <label>Weight Unit:</label>
          <span>{vehicle.weightUnit}</span>
        </div>
        <div className='vvm-field'>
          <label>Steer Max Weight:</label>
          <span>{vehicle.steerMaxWeight}</span>
        </div>
        <div className='vvm-field'>
          <label>Drive Max Weight:</label>
          <span>{vehicle.driveMaxWeight}</span>
        </div>
        <div className='vvm-field'>
          <label>Trailer A Max Weight:</label>
          <span>{vehicle.trailerAMaxWeight}</span>
        </div>
        <div className='vvm-field'>
          <label>Trailer B Max Weight:</label>
          <span>{vehicle.trailerBMaxWeight}</span>
        </div>
        <div className='vvm-field'>
          <label>Voltage Unit:</label>
          <span>{vehicle.voltageUnit}</span>
        </div>
        <div className='vvm-field'>
          <label>Working Voltage:</label>
          <span>{vehicle.workingVoltage}</span>
        </div>
        <div className='vvm-field'>
          <label>Fuel Capacity Unit:</label>
          <span>{vehicle.fuelCapacityUnit}</span>
        </div>
        <div className='vvm-field'>
          <label>Max Fuel Capacity:</label>
          <span>{vehicle.maxFuelCapacity}</span>
        </div>
        {vehicle.lastServicedOn ? (
          <div className='vvm-field'>
            <label>Last Serviced On:</label>
            <span>{formatDate(vehicle.lastServicedOn)}</span>
          </div>
        ) : null}
        {vehicle.nextServiceDue ? (
          <div className='vvm-field'>
            <label>Next Service Due:</label>
            <span>{formatDate(vehicle.nextServiceDue)}</span>
          </div>
        ) : null}
        {vehicle.addedOn ? (
          <div className='vvm-field'>
            <label>Added on:</label>
            <span>{vehicle.addedOn}</span>
          </div>
        ) : null}
        {vehicle.lastUpdatedAt ? (
          <div className='vvm-field'>
            <label>Last Updated At:</label>
            <span>{vehicle.lastUpdatedAt}</span>
          </div>
        ) : null}
      </div>
      <div className='vvm-btnContainer'>
        <div className='aem-div-btn cancel' onClick={onClose}>
          <div className='aem-outer-rim'>
            <div className='aem-inner-rim'>Close</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewVehicleModal;
