import React, { useRef, useState } from 'react';
import './GeoFenceSettings.css';
import { toast } from 'react-toastify';

const GeoFenceSettings = ({
  setQuickGeoFence,
  fleetPlanOnMapDone,
  showGeoFenceList,
  showAdvancedControl,
  currentPolygonIdRef,
  currentPolygonCoordinatesRef,
  deleteGeoFence,
}) => {
  const organizationId = sessionStorage.getItem('org_id');
  const [isLoading, setIsLoading] = useState(false);
  const [isQuickFence, setIsQuickFence] = useState(false);
  const fleetPlanTypeRef = useRef('');
  const geoFenceId = useRef('');

  const handleFleetPlanOnMapDone = () => {
    if (!isLoading) {
      if (isQuickFence) {
        //if is on quick fence mode then save data to server of coordinates of polygon is available
        setIsLoading(true);
        postQuickPolygonToServer();
      } else {
        //reset
        setIsQuickFence(false);

        fleetPlanTypeRef.current = '';
        document.body.classList.remove('crosshair-cursor');

        fleetPlanOnMapDone('setArea', true, '');
      }
    }
  };

  const onSuccessPostQuickPolygonToServer = () => {
    //reset
    setIsQuickFence(false);

    fleetPlanTypeRef.current = '';
    document.body.classList.remove('crosshair-cursor');

    fleetPlanOnMapDone('setArea', true, geoFenceId.current);
  };

  const handleQuickFenceClick = () => {
    setIsQuickFence(!isQuickFence);

    if (!isQuickFence) {
      fleetPlanTypeRef.current = 'setArea';
      setQuickGeoFence(fleetPlanTypeRef.current);

      document.body.classList.add('crosshair-cursor');
    } else {
      fleetPlanTypeRef.current = '';

      document.body.classList.remove('crosshair-cursor');
      fleetPlanOnMapDone('setArea', false, '');
    }
  };

  const postQuickPolygonToServer = async () => {
    try {
      // if (
      //   !geoFenceNameRef.current.value ||
      //   !geoFenceTypeRef.current.value ||
      //   !geoFenceColorRef.current.value
      // ) {
      //   toast.error('Please fill mandatory field!');
      //   return;
      // }
      if (currentPolygonCoordinatesRef.current.length === 0) {
        return;
      }
      const geofenceData = {
        organizationId: organizationId,
        polygonName: currentPolygonIdRef.current,
        polygonType: 'setArea',
        polygonDescription: new Date().toLocaleDateString,
        triggerOnExit: true,
        radius: 2,
        backgroundColor: '#3a5f0b',
        // regos: vehicleListGeoFenceWarnRef.current, // Assuming this is an array of registration numbers
        coordinates: currentPolygonCoordinatesRef.current.map((element) => [
          element.latitude,
          element.longitude,
        ]),
      };
      console.log('Geo fence obj : ', geofenceData);
      const server = process.env.REACT_APP_API_SERVER;
      const username = process.env.REACT_APP_API_USERNAME;
      const password = process.env.REACT_APP_API_PASSWORD;
      const credentials = btoa(`${username}:${password}`);
      const response = await fetch(`${server}/geofence`, {
        method: 'POST',
        headers: {
          Authorization: `Basic ${credentials}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(geofenceData),
      });
      if (response.ok) {
        //Geofence data saved
        toast.success('Geofence created successfully');
        geoFenceId.current = await response.text();
        console.log('ujj response polygon id: ' + geoFenceId.current);
        onSuccessPostQuickPolygonToServer();
      } else {
        //handle error response
        console.log('Error in saving Geofence Data: ', response.statusText);
        toast.error('Failed to create geofence. Please try again.');
      }
      setIsLoading(false);
    } catch (error) {
      //Handle network errors or other exceptions
      console.error('Error saving geofence data:', error.message);
      toast.error('Please check your internet connection and try again.');
      setIsLoading(false);
    }
  };

  return (
    <div className='geofencesettings-div-container'>
      {/* main container */}
      <div className='geofencesettings-div-header'>
        <div className='vehicle-card-section-header-outer'>
          <div
            className='vehicle-card-section-header-inner'
            style={{ gap: '6px' }}
          >
            <span>Geo Fence</span>
            <div
              className='geofencesettings-div-btn'
              title='Delete Fence'
              onClick={deleteGeoFence}
            >
              <div className='button-div-btn-outer-rim'>
                <div className='button-div-btn-inner-rim'>
                  <i
                    className='bi bi-trash3-fill'
                    style={{
                      fontSize: '1.2rem',
                      color: '#ec2d01',
                      position: 'absolute',
                    }}
                  ></i>
                </div>
              </div>
            </div>

            <div
              className='geofencesettings-div-btn'
              title='View Fences'
              onClick={() => {
                showGeoFenceList(true);
              }}
            >
              <div className='button-div-btn-outer-rim'>
                <div className='button-div-btn-inner-rim'>
                  <i
                    className='bi bi-list'
                    style={{
                      fontSize: '1.2rem',
                      color: '#fff',
                      position: 'absolute',
                    }}
                  ></i>
                </div>
              </div>
            </div>

            <div
              className='geofencesettings-div-btn'
              title='Advanced Fence'
              onClick={() => {
                showAdvancedControl(true);
              }}
            >
              <div className='button-div-btn-outer-rim'>
                <div className='button-div-btn-inner-rim'>
                  <i
                    className='bi bi-pencil-fill'
                    style={{
                      fontSize: '1.2rem',
                      color: '#55718a',
                      position: 'absolute',
                    }}
                  ></i>
                </div>
              </div>
            </div>

            <div
              className='geofencesettings-div-btn quick-fence'
              title='Quick Fence'
              onClick={handleQuickFenceClick}
            >
              {/* <div className='button-div-btn-outer-rim'> */}
              <div
                className={`button-div-btn-outer-rim ${
                  isQuickFence ? 'active' : ''
                }`}
              >
                <div className='button-div-btn-inner-rim'>
                  <i
                    className='bi bi-lightning-charge-fill'
                    style={{
                      fontSize: '1.2rem',
                      color: '#55718a',
                      position: 'absolute',
                    }}
                  ></i>
                </div>
              </div>
            </div>

            <div
              className='geofencesettings-div-btn'
              title='Done'
              onClick={handleFleetPlanOnMapDone}
            >
              <div className='button-div-btn-outer-rim'>
                <div className='button-div-btn-inner-rim'>
                  {!isLoading ? (
                    <>
                      <i
                        className='bi bi-check-lg'
                        style={{
                          fontSize: '1.2rem',
                          position: 'absolute',
                          color: '#228d0a',
                        }}
                      ></i>
                    </>
                  ) : (
                    <i
                      className='fas fa-spinner fa-spin'
                      style={{ marginTop: '12px' }}
                    ></i>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeoFenceSettings;
