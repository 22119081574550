import { useState } from 'react';
import VehicleCard from '../../pages/homepage/VehicleCard';

const VehicleListCard = ({
  showVehicleList,
  recentLocations,
  vehicleListGeoFenceWarn,
  onClose,
  onVehicleDataUpdateMap,
  onVehicleClickMap,
  streetViewVehiclePass,
  showVehicleSettings,
  onRecenterMap,
  onSelectedVehicleId,
}) => {
  const [filterText, setFilterText] = useState('');
  const [selectedVehicle, setSelectedVehicle] = useState(null);

  const onVehicleDataUpdatePass = (updatedVehicleData) => {
    onVehicleDataUpdateMap(updatedVehicleData);
  };

  const onVehicleClickPass = (vehicleCardData) => {
    onVehicleClickMap(vehicleCardData);
  };

  const filteredVehicles = recentLocations
    ? Object.values(recentLocations).filter((vehicle) =>
        vehicle.vehicleId.toLowerCase().includes(filterText.toLowerCase())
      )
    : [];

  const handleSelect = (vehicleId) => {
    if (selectedVehicle === vehicleId) {
      setSelectedVehicle(null);
      onSelectedVehicleId(null);
    } else {
      setSelectedVehicle(vehicleId);
      onSelectedVehicleId(vehicleId);
    }
  };

  const VehicleCards =
    filteredVehicles.length > 0 ? (
      filteredVehicles.map((vehicle) => (
        <VehicleCard
          key={vehicle.vehicleId}
          vehicle={vehicle}
          vehicleListGeoFenceWarn={vehicleListGeoFenceWarn}
          onVehicleDataUpdate={onVehicleDataUpdatePass}
          onVehicleClick={onVehicleClickPass}
          streetViewVehicle={streetViewVehiclePass}
          isSelected={selectedVehicle === vehicle.vehicleId}
          onSelect={handleSelect}
        />
      ))
    ) : (
      <div className='vehiclelistcard-div-nodata'>
        <span>Waiting for GPS signals</span>
        <div className='vehiclelistcard-div-nodata-loader'></div>
      </div>
    );

  return (
    <div
      className={`vehicle-card-section ${showVehicleList ? 'visible' : 'gone'}`}
    >
      <div className='vehicle-card-section-header'>
        <div className='vehicle-card-section-header-outer'>
          <div className='vehicle-card-section-header-inner'>
            <input
              type='text'
              placeholder='Search vehicle rego...'
              value={filterText}
              onChange={(e) => setFilterText(e.target.value)}
            />

            <div
              className='vehiclelistcard-div-btn'
              onClick={showVehicleSettings}
            >
              <div className='button-div-btn-outer-rim'>
                <div className='button-div-btn-inner-rim'>
                  <i className='bi bi-gear-fill' title='Vehicle Settings'></i>
                </div>
              </div>
            </div>

            <div className='vehiclelistcard-div-btn' onClick={onRecenterMap}>
              <div className='button-div-btn-outer-rim'>
                <div className='button-div-btn-inner-rim'>
                  <i className='bi bi-globe2' title='Recenter'></i>
                </div>
              </div>
            </div>

            <div className='vehiclelistcard-div-btn' onClick={onClose}>
              <div className='button-div-btn-outer-rim'>
                <div className='button-div-btn-inner-rim'>
                  <i className='bi bi-x-lg' title='Close'></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {VehicleCards}
    </div>
  );
};

export default VehicleListCard;
