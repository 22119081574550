import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { storage } from '../../Firebase';
import {
  getDownloadURL,
  ref as storageRef,
  uploadBytesResumable,
} from 'firebase/storage';
import { FaCloudUploadAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import './AddVehicleModal.css';

const AddVehicleModal = ({ onClose, onVehicleAdded }) => {
  const organizationId = sessionStorage.getItem('org_id');
  const [image, setImage] = useState(null);
  const [updating, setUpdating] = useState(false);

  const [newVehicle, setNewVehicle] = useState({
    organizationId: organizationId,
    weightUnit: 'kg',
    fuelCapacityUnit:'liters',
    voltageUnit:'volt',
    imageUrl: '',
  });

  // Create a ref for the hidden file input
  const fileInputRef = useRef(null);

  /**
   * Set chosen image to a variable
   * @param {*} e
   */
  const handleImgChoose = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewVehicle({ ...newVehicle, [name]: value });
  };

  /**
   *
   */
  const handleAddVehicle = async () => {
    console.log('new vehicle obj ', newVehicle);
    if (image) {
      const imageRef = storageRef(
        storage,
        `vehicles/${organizationId}/${newVehicle.vehicleId}/` + image.name
      );
      //upload image
      await uploadBytesResumable(imageRef, image);

      //get download url
      const imageUrl = await getDownloadURL(imageRef);
      newVehicle.imageUrl = imageUrl;
    }

    if (
      newVehicle.weightUnit &&
      newVehicle.fuelCapacityUnit &&
      newVehicle.vehicleId &&
      organizationId &&
      newVehicle.voltageUnit
    ) {
      try {
        setUpdating(true);
        const server = process.env.REACT_APP_API_SERVER;
        const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;
        const credentials = btoa(`${username}:${password}`);

        const response = await fetch(server + '/vehicles', {
          method: 'POST',
          body: JSON.stringify(newVehicle),

          headers: {
            Authorization: `Basic ${credentials}`,
            'Content-Type': 'application/json',
          },
        });
        if (response.ok) {
          toast.success('Vehicle added successfully!');
          onClose();
          // Reload the page after successful addition
          onVehicleAdded();
        } else {
          //Error occurred while adding new vehicle
          if (response.status === 400) {
            toast.error('Invalid vehicle data');
          } else if (response.status === 409) {
            toast.error(
              'Vehicle with rego already exists ' + newVehicle.vehicleId
            );
          } else {
            const errorMessage = await response.text();
            toast.error(`Failed to add vehicle: ${errorMessage}`);
          }
        }
      } catch (error) {
        toast.error(error.message);
      } finally {
        setUpdating(false);
      }
    } else {
      toast.error('Required parameters are missing!');
    }
  };

  const handleImageClick = () => {
    // Trigger the hidden file input when the image is clicked
    fileInputRef.current.click();
  };

  const currentDate = new Date().toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format


  return (
    <div className='vehicle-add-modal'>
      <div className='vam-header-container'>
        <h3>Add Vehicle</h3>
        <div className='vam-img-container'>
          <input
            type='file'
            id='file'
            onChange={handleImgChoose}
            ref={fileInputRef}
            style={{ display: 'none' }}
          />
          {/* <label htmlFor="file"
                            className="vam-img-label"
                        >
                            Image:
                            <FaCloudUploadAlt
                                className='vam-icon' />
                        </label> */}
          <div className='vam-uploadContainer' onClick={handleImageClick}>
            <img
              className='vam-img-Vehicle'
              src={
                image
                  ? URL.createObjectURL(image)
                  : 'https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg'
              }
              alt=''
            />
          </div>
        </div>
      </div>
      <div className='vam-input-container'>
        <label>Rego:</label>
        <input
          type='text'
          name='vehicleId'
          value={newVehicle.vehicleId}
          onChange={handleChange}
          // required
        />
      </div>
      <div className="vam-input-container">
        <label htmlFor='weightUnit'>Weight Unit:</label>
        <select
          id='weightUnit'
          name='weightUnit'
          value={newVehicle.weightUnit}
          onChange={handleChange}
          required>
          <option value='kg'>Kilogram (kg)</option>
          <option value='lb'>Pound (lb)</option>
        </select>
      </div>
      {/* <div className='vam-input-container'>
        <label>Weight Unit:</label>
        <input
          type='text'
          name='weightUnit'
          value={newVehicle.weightUnit}
          onChange={handleChange}
          required

        />
      </div> */}
      <div className='vam-input-container'>
        <label>Steer Max Weight:</label>
        <input
          type='number'
          name='steerMaxWeight'
          value={newVehicle.steerMaxWeight}
          onChange={handleChange}
        />
      </div>

      <div className='vam-input-container'>
        <label>Drive Max Weight:</label>
        <input
          type='number'
          name='driveMaxWeight'
          value={newVehicle.driveMaxWeight}
          onChange={handleChange}
        />
      </div>

      <div className='vam-input-container'>
        <label>Trailer A Max Weight:</label>
        <input
          type='number'
          name='trailerAMaxWeight'
          value={newVehicle.trailerAMaxWeight}
          onChange={handleChange}
        />
      </div>
      <div className='vam-input-container'>
        <label>Trailer B Max Weight:</label>
        <input
          type='number'
          name='trailerBMaxWeight'
          value={newVehicle.trailerBMaxWeight}
          onChange={handleChange}
        />
      </div>
      <div className="vam-input-container">
        <label htmlFor='voltageUnit'>Voltage Unit:</label>
        <select
          id='voltageUnit'
          name='voltageUnit'
          value={newVehicle.voltageUnit}
          onChange={handleChange}
          required>
          <option value='volt'>Volt (V)</option>
        </select>
      </div>

      {/* <div className='vam-input-container'>
        <label>Voltage Unit:</label>
        <input
          type='text'
          name='voltageUnit'
          value={newVehicle.voltageUnit}
          required
          onChange={handleChange}
        />
      </div> */}
      <div className='vam-input-container'>
        <label>Working Voltage:</label>
        <input
          type='number'
          name='workingVoltage'
          value={newVehicle.workingVoltage}
          onChange={handleChange}
        />
      </div>
      <div className="vam-input-container">
        <label htmlFor='fuelCapacityUnit'>Fuel Capacity Unit:</label>
        <select
          name='fuelCapacityUnit'
          id='fuelCapacityUnit'
          value={newVehicle.fuelCapacityUnit}
          onChange={handleChange}
          required>
          <option value='liters'>Liters (L)</option>
          <option value='gallons'>Gallons (gal)</option>
        </select>
      </div>
      {/* <div className='vam-input-container'>
        <label>Fuel Capacity Unit:</label>
        <input
          type='text'
          name='fuelCapacityUnit'
          value={newVehicle.fuelCapacityUnit}
          required
          onChange={handleChange}
        />
      </div> */}
      <div className='vam-input-container'>
        <label>Max Fuel Capacity:</label>
        <input
          type='number'
          name='maxFuelCapacity'
          value={newVehicle.maxFuelCapacity}
          onChange={handleChange}
        />
      </div>
      <div className='vam-input-container'>
        <label>Serviced On:</label>
        <input
          type='date'
          name='lastServicedOn'
          value={newVehicle.lastServicedOn}
          onChange={handleChange}
          max={currentDate} //past date
        />
      </div>
      <div className='vam-input-container'>
        <label>Next Service Due:</label>
        <input
          type='date'
          name='nextServiceDue'
          value={newVehicle.nextServiceDue}
          onChange={handleChange}
          min={currentDate}           //future date
        />
      </div>
      <div className='vam-btnContainer'>
        <div className='vam-div-btn add' onClick={handleAddVehicle}>
          <div className='vam-outer-rim'>
            <div className='vam-inner-rim'>
              {updating ? 'Adding...' : 'Add Vehicle'}
            </div>
          </div>
        </div>
        <div className='vam-div-btn cancel' onClick={onClose}>
          <div className='vam-outer-rim'>
            <div className='vam-inner-rim'>Cancel</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddVehicleModal;
