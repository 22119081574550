import React, { useEffect, useRef, useState } from 'react';
import './VehicleCard.css';
import vehicleLogo from '../../assets/logo_vehicle.png';
import iconEmployee from '../../assets/icon_employee.jpg';
import moment from 'moment-timezone';

const VehicleCard = ({
  vehicle,
  vehicleListGeoFenceWarn,
  onVehicleDataUpdate,
  onVehicleClick,
  streetViewVehicle,
  isSelected,
  onSelect,
}) => {
  const organizationId = sessionStorage.getItem('org_id');
  const [currentEmployee, setCurrentEmployee] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isGeoFenceTrigger, setIsGeoFenceTrigger] = useState(false);

  const fetchDataByRego = async (empId) => {
    try {
      const server = process.env.REACT_APP_API_SERVER;
      const username = process.env.REACT_APP_API_USERNAME;
      const password = process.env.REACT_APP_API_PASSWORD;

      const credentials = btoa(`${username}:${password}`);

      const response = await fetch(
        `${server}/employees/${organizationId}/${empId}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Basic ${credentials}`,
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        setCurrentEmployee(data);
        // Handle the retrieved data as needed
      } else {
        // toast.error(`Error fetching data: ${response.status}`);
      }
    } catch (error) {
      // toast.error(`Error fetching data: ${error.message}`);
      // console.log(error.message);
      setCurrentEmployee(null);
    } finally {
      setLoading(false);
    }
  };

  const handleVehicleCardClick = () => {
    const vehicleCardData = {
      latitude: vehicle.latitude,
      longitude: vehicle.longitude,
      vehicleId: vehicle.vehicleId,
      timestamp: vehicle.timestamp,
      status: vehicle.status,
      address: vehicle.address,
      empId: vehicle.empId,
      left: 20,
      top: 80,
    };

    onVehicleClick(vehicleCardData);
  };

  //vehicle.timestamp is in utc
  const getLocalTimestamp = (utcTimestamp) => {
    return moment
      .utc(utcTimestamp, 'MMM D, YYYY, h:mm:ss A')
      .local()
      .format('MMM D, YYYY, h:mm:ss A');
  };
  //if admin sees user's last location update 1 min old then set to offline to that user via fleet api
  const offlineStatusCheck = () => {
    const lastTimeStamp = new Date(
      getLocalTimestamp(vehicle.timestamp)
    ).getTime();
    const currentTimeStamp = new Date().getTime();

    //check in milliseconds if difference is more than a minute
    if (currentTimeStamp - lastTimeStamp > 1 * 60 * 1000) {
      //post to fleet master that its offline
      updateStatusToOffline();
    }
  };

  const updateStatusToOffline = async () => {
    try {
      const server = process.env.REACT_APP_API_SERVER;
      const username = process.env.REACT_APP_API_USERNAME;
      const password = process.env.REACT_APP_API_PASSWORD;
      const credentials = btoa(`${username}:${password}`);

      const response = await fetch(
        `${server}/recent/locations/${organizationId}/${vehicle.vehicleId}`,
        {
          method: 'PUT',
          headers: {
            Authorization: `Basic ${credentials}`,
          },
        }
      );

      if (response.ok) {
        console.log('status offline for ' + vehicle.vehicleId);
      }
    } catch (error) {
      // console.error('ujj error fetching data: ', error);
    }
  };

  useEffect(() => {
    //check and set offline only when not offline else NOT NEEDED
    if (vehicle.status !== 'offline') {
      offlineStatusCheck();
    }

    if (vehicleListGeoFenceWarn) {
      if (vehicleListGeoFenceWarn.length === 0) {
        setIsGeoFenceTrigger(false);
      } else {
        console.log('ujj length is: ' + vehicleListGeoFenceWarn.length);
        setIsGeoFenceTrigger(false); //first reset

        vehicleListGeoFenceWarn.forEach((element) => {
          console.log('ujj warned vehicles: ' + element);
          if (element === vehicle.vehicleId.toUpperCase()) {
            setIsGeoFenceTrigger(true);
          }
        });
      }
    } else {
      setIsGeoFenceTrigger(false);
    }

    //todo vehicle.id is always undefined
    const updatedVehicleData = {
      id: vehicle.id,
      rego: vehicle.vehicleId,
      status: vehicle.status,
      address: vehicle.address,
      timestamp: vehicle.timestamp,
    };
    onVehicleDataUpdate(updatedVehicleData);
  }, [vehicle]);

  useEffect(() => {
    fetchDataByRego(vehicle.empId);
  }, []);

  const handleStreetView = () => {
    if (vehicle && vehicle.latitude !== 0 && vehicle.longitude !== 0) {
      streetViewVehicle(vehicle.latitude, vehicle.longitude);
    }
  };

  return (
    <>
      {loading ? (
        <div className='vehiclecard-div-loading'>
          <span>Loading</span>
          <div className='vehiclecard-div-loading-icon-container'>
            <span className='vehiclecard-span-loading-icon'></span>
            <span className='vehiclecard-span-loading-icon'></span>
            <span className='vehiclecard-span-loading-icon'></span>
          </div>
          <br />
        </div>
      ) : (
        <div
          className='vehicle-card-container'
          onClick={handleVehicleCardClick}
        >
          {/* show image if available else default icon */}
          <div className='employee-image-container'>
            {currentEmployee &&
              (currentEmployee.imageUrl ? (
                <img
                  className={`${isGeoFenceTrigger ? 'geo-fence-warn' : ''}`}
                  src={currentEmployee.imageUrl}
                  alt={currentEmployee.name}
                />
              ) : (
                <img
                  className={`${isGeoFenceTrigger ? 'geo-fence-warn' : ''}`}
                  src={iconEmployee}
                  alt='employee'
                />
              ))}
          </div>

          {/* vehicle details card */}
          <div className='vehicle-card'>
            <div className='vehicle-card-header'>
              {/* name of employee with white line */}
              <div className='employee-name'>
                <span>{currentEmployee ? currentEmployee.name : '-'}</span>
                <div className='white-line'></div>
              </div>

              {/* vehicle rego and online status of employee */}
              <div className='vehicle-status'>
                <span>{vehicle.vehicleId}</span>
                <div
                  className={`status-circle ${vehicle.status}`}
                  title={`Status: ${vehicle.status}`}
                ></div>
              </div>
            </div>
            {/* end of vehicle-card-header */}

            {/* vehicle weight section */}
            <div className='vehicle-weight'>
              <div className='vehiclecard-input-track'>
                <i
                  className='bi bi-eye-fill'
                  style={{ color: 'white' }}
                  onClick={handleStreetView}
                ></i>

                <label>
                  <input
                    type='checkbox'
                    checked={isSelected}
                    onChange={() => onSelect(vehicle.vehicleId)}
                  />
                  <span>Track</span>
                </label>
              </div>

              <div className='vehicle-total-weight'>
                <img src={vehicleLogo} alt='Vehicle Logo' style={{}} />

                <span>
                  <div className='vehicle-total-weight-name'>Total</div>
                  <div className='vehicle-total-weight-value'>
                    <div>{vehicle?.totalWeight ?? 0}</div>
                    <div
                      style={{
                        color: '#87ceeb',
                        fontSize: '12px',
                        marginLeft: '6px',
                      }}
                    >
                      {vehicle?.scaleUnit}
                    </div>
                  </div>
                </span>
              </div>

              <div className='vehicle-axle-weight'>
                {/* steer */}
                <div className='axle'>
                  <span className='axle-name'>Steer </span>
                  <span className='axle-weight'>
                    {vehicle.steerWeight ?? 0}&nbsp;
                    <span style={{ color: '#87ceeb', fontSize: '12px' }}>
                      {vehicle?.scaleUnit}
                    </span>
                  </span>
                  <div className='orange-line'></div>
                </div>

                {/* drive */}
                <div className='axle'>
                  <span className='axle-name'>Drive </span>
                  <span className='axle-weight'>
                    {vehicle.driveWeight ?? 0}
                    &nbsp;
                    <span style={{ color: '#87ceeb', fontSize: '11px' }}>
                      {vehicle?.scaleUnit}
                    </span>
                  </span>
                  <div className='orange-line'></div>
                </div>

                {/* Trailer A */}
                <div className='axle'>
                  <span className='axle-name'>Trailer A </span>
                  <span className='axle-weight'>
                    {vehicle.trailerAWeight ?? 0}
                    &nbsp;
                    <span style={{ color: '#87ceeb', fontSize: '11px' }}>
                      {vehicle?.scaleUnit}
                    </span>
                  </span>
                  <div className='orange-line'></div>
                </div>

                {/* Trailer B */}
                <div className='axle'>
                  <span className='axle-name'>Trailer B </span>
                  <span className='axle-weight'>
                    {vehicle.trailerBWeight ?? 0}
                    &nbsp;
                    <span style={{ color: '#87ceeb', fontSize: '11px' }}>
                      {vehicle?.scaleUnit}
                    </span>
                  </span>
                  <div className='orange-line'></div>
                </div>
              </div>
            </div>
            {/* end of vehicle weight section */}

            {/* show location of user */}
            <div className='vehicle-address'>
              <span>{vehicle?.address}</span>
              <i className='bi bi-geo-alt-fill'></i>
            </div>
            {/* end of vehicle address */}
          </div>
          {/* end of  div vehicle-card */}
        </div> //end of main div vehicle-card-container
      )}
    </>
  );
};

export default VehicleCard;
