import React, { useEffect, useState } from 'react';
import './changepassword.css';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import logoImage from '../images/trucktronix icon.png';

function ChangePassword() {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [authenticating, setAuthenticating] = useState(false);
  const navigate = useNavigate();

  const goBackHandler = () => {
    window.history.back();
  };

  const email = sessionStorage.getItem('user_email');
  const orgId = sessionStorage.getItem('org_id');

  const authenticateUser = async () => {
    try {
      setAuthenticating(true);

      const server = process.env.REACT_APP_API_SERVER;
      // const server = "http://localhost:4567";

      const username = process.env.REACT_APP_API_USERNAME;
      const password = process.env.REACT_APP_API_PASSWORD;
      const credentials = btoa(`${username}:${password}`);

      const response = await fetch(server + '/loginOrg', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Basic ${credentials}`,
        },
        body: JSON.stringify({
          organizationId: orgId,
          email: email,
          pwd: currentPassword,
        }),
      });

      if (response.ok) {
        return true;
      } else {
        if (response.status === 401) {
          toast.error('Invalid current password.');
        } else {
          toast.error('An error occurred during authentication.');
        }
        return false;
      }
    } catch (error) {
      console.error(`Error during authentication: ${error.message}`);
      toast.error('An error occurred during authentication.');
    } finally {
      setAuthenticating(false);
    }
  };

  /**
   * Change password of current user
   * @param {*} e
   */
  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log("email and org id ", email, orgId)

    if (newPassword !== confirmPassword) {
      toast.error("New password and confirm password don't match!");
      return;
    }

    // Step 1: Authenticate the user
    const isAuthenticated = await authenticateUser();

    // Step 2: If authentication is successful, proceed with changing the password
    if (isAuthenticated) {
      try {
        setLoading(true);

        const server = process.env.REACT_APP_API_SERVER;
        // const server = "http://localhost:4567";
        const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const credentials = btoa(`${username}:${password}`);

        // const response = await fetch(server + '/organization/password/change', {
        const response = await fetch(`${server}/orgs/${orgId}/${email}`, {
          // method: 'PUT',
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Basic ${credentials}`,
          },
          body: JSON.stringify({
            organizationId: orgId,
            email: email,
            pwd: newPassword,
          }),
        });

        if (response.ok) {
          // Successful login, you can redirect to a new page or update the UI accordingly.
          toast.success('Password changed successfully!');
          navigate('/my-profile');
        } else {
          // Login failed, display an error message.
          if (response.status === 400) {
            toast.error('Invalid user data.');
          } else if (response.status === 404) {
            toast.error("User doesn't exist in the system.");
          } else {
            toast.error('An error occurred during password change.');
          }
        }
      } catch (error) {
        console.error(error.message);
        toast.error(error.message);
      } finally {
        setLoading(false);
      }
    } else {
      toast.error(
        'Authentication failed. Please provide the correct current password.'
      );
    }
  };

  return (
    <div className='change-password-container'>
      <div className='user-profile-div-header'>
        <img src={logoImage} alt='Trucktronix' className='logo-image' />
        <h4>Change Password</h4>
      </div>
      <div className='change-password-wrapper'>
        <div className='fleetmanager-div-body-details-outer-rim'>
          <div className='fleetmanager-div-body-details-inner-rim'>
            <div className='change-password-form'>
              <form onSubmit={handleSubmit}>
                <label>Current Password</label>
                <input
                  type='password'
                  required
                  placeholder='Current Password'
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                />
                <label>New Password</label>
                <input
                  type='password'
                  required
                  placeholder='New Password'
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
                <label>Confirm New Password</label>
                <input
                  type='password'
                  required
                  placeholder='Confirm New Password'
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <div className='change-pw-div-btn add'>
                  <div className='change-pw-outer-rim'>
                    <div className='change-pw-inner-rim'>
                      <button
                        className='cp-changepw'
                        type='submit'
                        disabled={loading || authenticating}
                      >
                        {loading ? 'Changing Password...' : 'Change Password'}
                      </button>
                    </div>
                  </div>
                </div>

                <div className='change-pw-div-btn cancel'>
                  <div className='change-pw-outer-rim'>
                    <div className='change-pw-inner-rim'>
                      <button className='cp-btn-back' onClick={goBackHandler}>
                        Go Back
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChangePassword;
