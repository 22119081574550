import React, { useEffect, useState } from 'react';
import { saveAs } from 'file-saver';
import { toast } from 'react-toastify';
import LoadingSpinner from '../../component/LoadingSpinner';
import moment from 'moment-timezone';

const ITEMS_PER_PAGE = 50; // Number of items to display per page

const FleetRegoTable = (props) => {
  const { rego } = props;

  const [fleetData, setFleetData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc');
  let [startDate, setStartDate] = useState('');
  let [endDate, setEndDate] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');

  const organizationId = sessionStorage.getItem('org_id');
  useEffect(() => {
    const currentDate = new Date();
    // const currentDateTime = currentDate.toISOString().slice(0, 16);
    const sevenDaysAgo = currentDate.getTime() - 7 * 24 * 60 * 60 * 1000;
    const sevenDaysAgoDate = new Date(sevenDaysAgo);
    const sevenDaysAgoDateTime = sevenDaysAgoDate.toISOString().slice(0, 16);

    // Set start date 7 days ago
    setStartDate(sevenDaysAgoDateTime);
    // Set max attribute for end date input
    setEndDate(getCurrentDateTime());
  }, []);

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const getLocalTimestamp = (utcTimestamp) => {
    // Convert to local time from utc
    return moment.utc(utcTimestamp).local().format('MMM D, YYYY, h:mm:ss A');
  };
  // Get current date and time in the required format
  const getCurrentDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };
  const isDateInRange = (date, start, end) => {
    const currentDate = new Date(date);
    const startDate = start ? new Date(start) : null;
    const endDate = end ? new Date(end) : null;

    return (
      (!startDate || currentDate >= startDate) &&
      (!endDate || currentDate <= endDate)
    );
  };
  const handleSearch = async () => {
    try {
      // Validate start and end date
      startDate = new Date(startDate).toISOString();
      endDate = new Date(endDate).toISOString();
      if (!startDate) {
        toast.error('Please select a start date.');
        return;
      }

      if (!endDate) {
        toast.error('Please select an end date.');
        return;
      }

      if (new Date(startDate) > new Date(endDate)) {
        console.log('Selected date range: ', startDate, endDate);
        toast.error('End date should be equal to or after the start date.');
        return;
      }
      setIsLoading(true);

      const server = process.env.REACT_APP_API_SERVER;
      // const server = "http://localhost:4567"
      const username = process.env.REACT_APP_API_USERNAME;
      const password = process.env.REACT_APP_API_PASSWORD;
      const credentials = btoa(`${username}:${password}`);
      console.log('rego', rego.rego);
      const url = new URL(
        `${server}/fleettable/${organizationId}/${rego.rego}`
      );
      url.searchParams.append('startDate', startDate);
      url.searchParams.append('endDate', endDate);
      console.log('url', url);
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Basic ${credentials}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const data = await response.json();
        setFleetData(data);
      } else if (response.status === 404) {
        console.log('No data found in the given date range.');
        setFleetData([]);
      } else {
        console.error('Failed to fetch fleet data: ', response.status);
        toast.error('Failed to fetch fleet data.');
      }
    } catch (error) {
      toast.error(`An unexpected error occurred: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchFleetData = async ({ rego }) => {
    if (rego === undefined) {
      // alert("no rego selected")
      setFleetData([]);
      setIsLoading(false);
      return;
    }
    try {
      // Set loading to true before making the request
      setIsLoading(true);
      const server = process.env.REACT_APP_API_SERVER;
      // const server = "http://localhost:4567"
      const username = process.env.REACT_APP_API_USERNAME;
      const password = process.env.REACT_APP_API_PASSWORD;
      const credentials = btoa(`${username}:${password}`); // Base64 encode username and password

      // Fetch fleet data
      // const url = new URL(`${server}/locations/${organizationId}/${rego}`)

      const url = new URL(`${server}/fleettable/${organizationId}/${rego}`);

      url.searchParams.append('startDate', new Date(startDate).toISOString());
      url.searchParams.append('endDate', new Date(endDate).toISOString());

      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Basic ${credentials}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const newFleetData = await response.json();
        setFleetData(newFleetData);
      } else if (response.status === 404) {
        console.log('No Data Found');
        setFleetData([]);
      } else {
        console.error('Failed to fetch fleet data: ', response.status);
        toast.error('Failed to fetch fleet data.');
      }
    } catch (error) {
      // console.error('Error in fetching fleet data: ', error);
      toast.error(`An unexpected error occurred: ${error.message}`);
    } finally {
      // Set loading to false regardless of success or failure
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // Fetch fleet data when the component mounts
    fetchFleetData(rego);
  }, [rego]);

  const handleDownload = (format) => {
    try {
      const formattedData = JSON.stringify(filteredAndSortedData, null, 2);
      const blob = new Blob([formattedData], { type: 'application/json' });
      if (format === 'json') {
        saveAs(blob, 'fleet_data.json');
      } else if (format === 'csv') {
        const csvData = filteredAndSortedData
          .map((item) => Object.values(item).join(','))
          .join('\n');
        const csvBlob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
        saveAs(csvBlob, 'fleet_data.csv');
      }
    } catch (error) {
      // console.error('Error in downloading data: ', error);
      toast.error('Failed to download data.');
    }
  };
  // const handleSort = (columnName) => {
  //   if (sortColumn === columnName) {
  //     setSortOrder((order) => (order === 'asc' ? 'desc' : 'asc'));
  //   } else {
  //     setSortColumn(columnName);
  //     setSortOrder('asc');
  //   }
  // };

  const filteredAndSortedData = fleetData
    .filter(
      (item) =>
        Object.values(item).join(' ').toLowerCase().includes(searchTerm) ||
        item.organizationId.toLowerCase().includes(searchTerm) ||
        item.vehicleId.toLowerCase().includes(searchTerm)
    )
    .filter((item) => isDateInRange(item.timestamp, startDate, endDate))
    .sort((a, b) => {
      const aValue = sortColumn ? a[sortColumn] : null;
      const bValue = sortColumn ? b[sortColumn] : null;

      if (aValue === bValue) {
        return 0;
      }

      return sortOrder === 'asc'
        ? aValue > bValue
          ? 1
          : -1
        : aValue < bValue
        ? 1
        : -1;
    });

  // const rowGetter = ({ index }) => filteredAndSortedData[index];

  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = Math.min(
    startIndex + ITEMS_PER_PAGE,
    filteredAndSortedData.length
  );

  const paginatedData = filteredAndSortedData.slice(startIndex, endIndex);
  const totalItems = filteredAndSortedData.length;
  const totalPages = Math.ceil(totalItems / ITEMS_PER_PAGE);

  const renderPaginationControls = () => {
    const renderPageButtons = () => {
      const pageButtons = [];
      const maxButtonsToShow = 5;

      for (let i = 1; i <= totalPages; i++) {
        if (
          i === 1 ||
          i === totalPages ||
          Math.abs(i - currentPage) < maxButtonsToShow / 2
        ) {
          pageButtons.push(
            <button
              key={i}
              onClick={() => setCurrentPage(i)}
              disabled={i === currentPage}
            >
              {i}
            </button>
          );
        } else if (pageButtons[pageButtons.length - 1] !== '...') {
          pageButtons.push('...');
        }
      }
      return pageButtons;
    };
    return (
      <div className='pagination-controls'>
        <button onClick={() => setCurrentPage(1)} disabled={currentPage === 1}>
          First
        </button>
        <button
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {renderPageButtons()}
        <button
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
        <button
          onClick={() => setCurrentPage(totalPages)}
          disabled={currentPage === totalPages}
        >
          Last
        </button>
        {/* <span>{`Page ${currentPage} of ${totalPages}`}</span> */}
        {/* <span>{`Total items: ${totalItems}`}</span> */}
        {/* <br /> <span>{`Total items: ${totalItems}`}</span> */}
      </div>
    );
  };

  return (
    <div className='fleetregotable-div-container'>
      {rego ? (
        <div className='fleetregotable-div-container-body'>
          <div className='fleetregotable-div-container-controls'>
            <div className='fleetregotable-div-date-range'>
              <label>Start Date:</label>
              <input
                type='datetime-local'
                value={startDate}
                onChange={handleStartDateChange}
                max={getCurrentDateTime()} // Set max attribute to current date and time
              />
            </div>
            <div className='fleetregotable-div-date-range'>
              <label>End Date:</label>
              <input
                type='datetime-local'
                value={endDate}
                onChange={handleEndDateChange}
                max={getCurrentDateTime()} // Set max attribute to current date and time
              />
            </div>

            <div className='div-btn' onClick={handleSearch}>
              <div className='div-btn-outer-rim'>
                <div className='div-btn-inner-rim'>
                  <div style={{ padding: '6px 10px' }}>
                    <i className='bi bi-search'></i>
                    &nbsp;&nbsp;Search
                  </div>
                </div>
              </div>
            </div>

            <div className='fleetregotable-div-container-download'>
              <label htmlFor='downloadDropdown'>Download:</label>
              <select
                id='downloadDropdown'
                onChange={(e) => handleDownload(e.target.value)}
                disabled={fleetData.length === 0}
              >
                <option value='json'>JSON</option>
                <option value='csv'>CSV</option>
              </select>
            </div>
          </div>

          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <>
              {paginatedData.length > 0 && (
                // <div className='history-table-div-container-table'>
                <table className='fleetregotable-table-container'>
                  <thead>
                    <tr>
                      <th>Timestamp</th>
                      <th>Latitude</th>
                      <th>Longitude</th>
                      <th>Total Weight</th>
                      <th>Steer Weight</th>
                      <th>Drive Weight</th>
                      <th>Trailer A Weight</th>
                      <th>Trailer B Weight</th>
                    </tr>
                  </thead>
                  <tbody>
                    {paginatedData.map((item) => (
                      <tr key={`${item.id}-${item.timestamp}`}>
                        <td>
                          {getLocalTimestamp(item.timestamp)} {item.id}
                        </td>
                        <td>{item.latitude}</td>
                        <td>{item.longitude}</td>
                        <td>{item.totalWeight}</td>
                        <td>{item.steerWeight}</td>
                        <td>{item.driveWeight}</td>
                        <td>{item.trailerAWeight}</td>
                        <td>{item.trailerBWeight}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
              {paginatedData.length > 0 && renderPaginationControls()}
              {paginatedData.length > 0 && (
                <span>{`Total items: ${totalItems}`}</span>
              )}
              {paginatedData.length === 0 && (
                <span className='no-data'>No data available.</span>
              )}
            </>
          )}
        </div>
      ) : (
        <p className='history-table-p-loading-message'>
          Please select rego to view locations.
        </p>
      )}
    </div>
  );
};

export default FleetRegoTable;
