import React, { useState } from 'react';
import { toast } from 'react-toastify';
import logoFM from '../images/logo_fleet_master.png';
import './ResetPassword.css';

const ResetPassword = () => {
  const [email, setEmail] = useState('');
  const [orgId, setOrgId] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const server = process.env.REACT_APP_API_SERVER;
      // const server = "http://localhost:4567"

      const response = await fetch(server + '/reset', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: email,
          organizationId: orgId,
        }),
      });

      if (response.ok) {
        // Successful login, you can redirect to a new page or update the UI accordingly.
        toast.success('Reset email sent!');
      } else {
        // Login failed, display an error message.
        // console.log("response ", response);
        if (response.status === 400) {
          toast.error('Invalid user data.');
        } else if (response.status === 404) {
          toast.error('User not found.');
        } else {
          toast.error('An error occurred during password reset.');
        }
      }
    } catch (error) {
      console.error(error.message);
      toast.error('An unexpected error occurred: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='pageReset'>
      <div className='containerReset'>
        <form className='reset-form' onSubmit={handleSubmit} id='resetAccount'>
          <img src={logoFM} alt='Logo' className='login-logo-image' />
          <h1 className='form__title'>Forgot Password</h1>
          <div className='form__message form__message--error'></div>
          <div className='form__input-group'>
            <input
              type='email'
              className='form__input'
              autoFocus
              placeholder='Enter your email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className='form__input-group'>
            <input
              type='text'
              className='form__input'
              autoFocus
              placeholder='Organization Id'
              value={orgId}
              onChange={(e) => setOrgId(e.target.value)}
              required
            />
            <div className='form__input-error-message'></div>
          </div>
          <button className='form__button' type='submit' disabled={loading}>
            {loading ? 'Resetting...' : 'Reset'}
          </button>

          <p className='form__text'>
            <a href='/login' className='form__link' id='linkLogin'>
              Return to Login
            </a>
          </p>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
