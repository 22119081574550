// UpdateVehicleModal.js
import React, { useState, useRef, useEffect } from 'react';
import { toast } from 'react-toastify';
import { storage } from '../../Firebase';
import {
  getDownloadURL,
  ref as storageRef,
  uploadBytesResumable,
} from 'firebase/storage';
import { FaCloudUploadAlt } from 'react-icons/fa';
import './UpdateVehicleModal.css';

const UpdateVehicleModal = ({ onClose, onUpdate, vehicle }) => {

  const organizationId = sessionStorage.getItem('org_id');
  const [image, setImage] = useState(null);
  const [updating, setUpdating] = useState(false);

  const [updatedVehicle, setUpdatedVehicle] = useState({
    organizationId: organizationId,
    imageUrl: vehicle.imageUrl || '',
    vehicleId: vehicle.vehicleId || '',
    weightUnit: vehicle.weightUnit || '',
    steerMaxWeight: vehicle.steerMaxWeight || 0,
    driveMaxWeight: vehicle.driveMaxWeight || 0,
    trailerAMaxWeight: vehicle.trailerAMaxWeight || 0,
    trailerBMaxWeight: vehicle.trailerBMaxWeight || 0,
    voltageUnit: vehicle.voltageUnit || '',
    workingVoltage: vehicle.workingVoltage || 0,
    fuelCapacityUnit: vehicle.fuelCapacityUnit || '',
    maxFuelCapacity: vehicle.maxFuelCapacity || 0,
    lastServicedOn: vehicle.lastServicedOn || null,
    nextServiceDue: vehicle.nextServiceDue || null,
  });

  // Create a ref for the hidden file input
  const fileInputRef = useRef(null);

  const handleImgChoose = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUpdatedVehicle({ ...updatedVehicle, [name]: value });
  };

  const handleUpdateVehicle = async () => {
    if (image) {
      setUpdating(true);
      const imageRef = storageRef(
        storage,
        `vehicles/${organizationId}/${updatedVehicle.vehicleId}/` + image.name
      );
      try {
        // Upload image
        await uploadBytesResumable(imageRef, image);

        // Get download url
        const imageUrl = await getDownloadURL(imageRef);
        updatedVehicle.imageUrl = imageUrl;
        console.log('img url: old and new', imageUrl, updatedVehicle.imageUrl);
        // Update the data server only if the image upload is successful
        await updateDataServer();
      } catch (error) {
        console.error('Error uploading image:', error);
        toast.error('Failed to upload image.');
      }
    } else {
      // No image to upload, update data server directly
      await updateDataServer();
    }
  };

  const updateDataServer = async () => {
    if (
      updatedVehicle.weightUnit &&
      updatedVehicle.fuelCapacityUnit &&
      updatedVehicle.vehicleId &&
      organizationId &&
      updatedVehicle.voltageUnit
    ) {
      try {
        // console.log(updatedVehicle.imageUrl);
        setUpdating(true);
        // const server = 'http://localhost:4567';
        const server = process.env.REACT_APP_API_SERVER;
        const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;
        const credentials = btoa(`${username}:${password}`);

        const response = await fetch(
          `${server}/vehicles/${organizationId}/${updatedVehicle.vehicleId}`,
          {
            method: 'PUT',
            body: JSON.stringify(updatedVehicle),
            headers: {
              Authorization: `Basic ${credentials}`,
              'Content-Type': 'application/json',
            },
          }
        );

        if (response.ok) {
          toast.success('Vehicle updated successfully!');
          onClose();
          onUpdate(); // Notify parent component about the update
        } else {
          if (response.status === 400) {
            toast.error('Invalid vehicle data');
          } else if (response.status === 404) {
            toast.error(
              'Vehicle with rego not found ' + updatedVehicle.vehicleId
            );
          } else {
            const errorMessage = await response.text();
            toast.error(`Failed to update vehicle: ${errorMessage}`);
          }
          // const errorMessage = await response.text();
          // toast.error(`Failed to update vehicle: ${errorMessage}`);
        }
      } catch (error) {
        toast.error(error.message);
      } finally {
        setUpdating(false);
      }
    } else {
      toast.error('Required parameters are missing!');
    }
  };

  const handleImageClick = () => {
    fileInputRef.current.click();
  };
  
  // Define a function to format the date
  function formatDate(dateString) {
    if(dateString == null){
      return;
    }
    const date = new Date(dateString);
    // Extract year, month, and day components
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Add leading zero if needed
    const day = String(date.getDate()).padStart(2, '0'); // Add leading zero if needed
    // Combine components to form the formatted date string
    return `${year}-${month}-${day}`;
  }
  const currentDate = new Date().toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format

  return (
    <div className='update-vehicle-modal'>
      <div className='uvm-header-container'>
        <h3>Update Vehicle</h3>
        <div className='uvm-img-container'>
          <input
            type='file'
            id='file'
            onChange={handleImgChoose}
            ref={fileInputRef}
            style={{ display: 'none' }}
          />
          {/* <label htmlFor='file' className='uvm-img-label'>
          <FaCloudUploadAlt className='uvm-icon' />
          </label> */}
          <div className='uvm-uploadContainer' onClick={handleImageClick}>
            <img
              className='uvm-img-Vehicle'
              src={
                image
                  ? URL.createObjectURL(image)
                  : updatedVehicle.imageUrl ||
                  'https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg'
              }
              alt=''
            />
          </div>
        </div>
      </div>
      <div className='uvm-input-container'>
        <label>Rego:</label>
        <input
          type='text'
          name='vehicleId'
          value={updatedVehicle.vehicleId}
          onChange={handleChange}
          disabled
        />
      </div>
      <div className="uvm-input-container">
        <label htmlFor='weightUnit'>Weight Unit:</label>
        <select
          id='weightUnit'
          name='weightUnit'
          value={updatedVehicle.weightUnit}
          onChange={handleChange}
          required>
          <option value='kg'>Kilogram (kg)</option>
          <option value='lb'>Pound (lb)</option>
        </select>
      </div>
      {/* <div className='uvm-input-container'>
        <label>Weight Unit:</label>
        <input
          type='text'
          name='weightUnit'
          value={updatedVehicle.weightUnit}
          onChange={handleChange}
        />
      </div> */}
      <div className='uvm-input-container'>
        <label>Steer Max Weight:</label>
        <input
          type='number'
          name='steerMaxWeight'
          value={updatedVehicle.steerMaxWeight}
          onChange={handleChange}
        />
      </div>
      <div className='uvm-input-container'>
        <label>Drive Max Weight:</label>
        <input
          type='number'
          name='driveMaxWeight'
          value={updatedVehicle.driveMaxWeight}
          onChange={handleChange}
        />
      </div>
      <div className='uvm-input-container'>
        <label>Trailer A Max Weight:</label>
        <input
          type='number'
          name='trailerAMaxWeight'
          value={updatedVehicle.trailerAMaxWeight}
          onChange={handleChange}
        />
      </div>
      <div className='uvm-input-container'>
        <label>Trailer B Max Weight:</label>
        <input
          type='number'
          name='trailerBMaxWeight'
          value={updatedVehicle.trailerBMaxWeight}
          onChange={handleChange}
        />
      </div>
      <div className="uvm-input-container">
        <label htmlFor='voltageUnit'>Voltage Unit:</label>
        <select
          id='voltageUnit'
          name='voltageUnit'
          value={updatedVehicle.voltageUnit}
          onChange={handleChange}
          required>
          <option value='volt'>Volt (V)</option>
        </select>
      </div>
      {/* <div className='uvm-input-container'>
        <label>Voltage Unit:</label>
        <input
          type='text'
          name='voltageUnit'
          value={updatedVehicle.voltageUnit}
          onChange={handleChange}
        />
      </div> */}
      <div className='uvm-input-container'>
        <label>Working Voltage:</label>
        <input
          type='number'
          name='workingVoltage'
          value={updatedVehicle.workingVoltage}
          onChange={handleChange}
        />
      </div>
      <div className="uvm-input-container">
        <label htmlFor='fuelCapacityUnit'>Fuel Capacity Unit:</label>
        <select
          name='fuelCapacityUnit'
          id='fuelCapacityUnit'
          value={updatedVehicle.fuelCapacityUnit}
          onChange={handleChange}
          required>
          <option value='liters'>Liters (L)</option>
          <option value='gallons'>Gallons (gal)</option>
        </select>
      </div>

      {/* <div className='uvm-input-container'>
        <label>Fuel Capacity Unit:</label>
        <input
          type='text'
          name='fuelCapacityUnit'
          value={updatedVehicle.fuelCapacityUnit}
          onChange={handleChange}
        />
      </div> */}
      <div className='uvm-input-container'>
        <label>Max Fuel Capacity:</label>
        <input
          type='number'
          name='maxFuelCapacity'
          value={updatedVehicle.maxFuelCapacity}
          onChange={handleChange}
        />
      </div>
      <div className='vam-input-container'>
        <label>Serviced On:</label>
        <input
          type='date'
          name='lastServicedOn'
          value={formatDate(updatedVehicle.lastServicedOn)}
          onChange={handleChange}
          max={currentDate} //past date
        />
      </div>
      <div className='vam-input-container'>
        <label>Next Service Due:</label>
        <input
          type='date'
          name='nextServiceDue'
          value={formatDate(updatedVehicle.nextServiceDue)}
          onChange={handleChange}
          min={currentDate}           //future date
        />
      </div>
      <div className='uvm-btnContainer'>
        <div className='aem-div-btn add' onClick={handleUpdateVehicle}>
          <div className='aem-outer-rim'>
            <div className='aem-inner-rim'>
              {updating ? 'Updating...' : 'Update Vehicle'}
            </div>
          </div>
        </div>
        <div className='aem-div-btn cancel' onClick={onClose}>
          <div className='aem-outer-rim'>
            <div className='aem-inner-rim'>Cancel</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(UpdateVehicleModal);
