import React, { useState } from 'react';
import { toast } from 'react-toastify';

const NewUser = () => {
  const [state, setState] = useState({});
  /**
   * Update the value of input field on change
   * @param {*} e
   */
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
    console.log(state);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('create user ', state);
    try {
      // Send a POST request to your backend for login
      const server = 'http://localhost:4567';
      const response = await fetch(server + '/users', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(state),
      });

      if (response.ok) {
        // Successful login, you can redirect to a new page or update the UI accordingly.
        toast.success('Signup success');
      } else {
        // Login failed, display an error message.
        console.log('response ', response);
        if (response.status === 400) {
          toast.error('Invalid user data.');
        } else if (response.status === 409) {
          toast.error('User already exist in the system.');
        } else {
          toast.error('An error occurred during sign up.');
        }
      }
    } catch (error) {
      console.error(error.message);
      toast.error(error);
    }
  };

  return (
    <div className='page'>
      <div className='container'>
        <form className='form' onSubmit={handleSubmit} id='createAccount'>
          <h1 className='form__title'>Create Account</h1>
          <div className='form__message form__message--error'></div>

          <div className='form__input-group'>
            <input
              type='email'
              id='signupEmail'
              className='form__input'
              autoFocus
              placeholder='Email Address'
              name='email'
              value={state.email}
              required
              onChange={handleInputChange}
            />
          </div>
          <div className='form__input-group'>
            <input
              type='password'
              id='password'
              className='form__input'
              name='pwd'
              value={state.pwd}
              onChange={handleInputChange}
              placeholder='Password'
            />
          </div>
          <div className='form__input-group'>
            <input
              type='text'
              id='name'
              value={state.name}
              className='form__input'
              name='name'
              onChange={handleInputChange}
              placeholder='Name '
            />
          </div>
          <div className='form__input-group'>
            <input
              type='text'
              id='phone'
              className='form__input'
              name='phone'
              value={state.phone}
              onChange={handleInputChange}
              placeholder='Phone '
            />
          </div>
          <button className='form__button' type='submit'>
            Continue
          </button>
          <p className='form__text'>
            <a href='/login' className='form__link' id='linkLogin'>
              {' '}
              Already have an account? Sign in
            </a>
          </p>
        </form>
      </div>
    </div>
  );
};

export default NewUser;
