// UpdateEmployeeModal.js
import React, { useState, useEffect, useRef } from 'react';
import './UpdateEmployeeModal.css';
import { toast } from 'react-toastify';
import { storage } from '../../Firebase';
import {
  getDownloadURL,
  ref as storageRef,
  uploadBytesResumable,
} from 'firebase/storage';
import { FaCloudUploadAlt } from 'react-icons/fa';
import Select from 'react-select';

const UpdateEmployeeModal = ({ onClose, onUpdate, employee }) => {
  const organizationId = sessionStorage.getItem('org_id');
  const [image, setImage] = useState(null);
  const [updating, setUpdating] = useState(false);
  const [vehicleOptions, setVehicleOptions] = useState([]);
  // Define custom styles for the Select component
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: '1px solid #ccc',
      borderRadius: '4px',
      boxShadow: state.isFocused ? '0 0 0 1px #3498db' : 'none',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#3498db' : '#fff',
      color: state.isSelected ? '#fff' : '#333',
      cursor: 'pointer',
    }),
  };

  const [updatedEmployee, setUpdatedEmployee] = useState({
    organizationId: organizationId,
    empId: employee.empId || '',
    name: employee.name || '',
    rego: employee.rego || '',
    email: employee.email || '',
    phone: employee.phone || '',
    passcode: employee.passcode || '',
    imageUrl: employee.imageUrl || '',
  });

  // Create a ref for the hidden file input
  const fileInputRef = useRef(null);
  const handleImgChoose = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };
  useEffect(() => {
    //Fetch the list of vehicles and populate the dropdown options
    const fetchVehicles = async () => {
      try {
        const server = process.env.REACT_APP_API_SERVER;
        // const server = 'http://localhost:4567';
        const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;
        const credentials = btoa(`${username}:${password}`);

        const response = await fetch(`${server}/vehicles/${organizationId}`, {
          method: 'GET',
          headers: {
            Authorization: `Basic ${credentials}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          console.log('vehicle data ', data);
          const options = data.map((vehicle) => ({
            value: vehicle.vehicleId,
            label: vehicle.vehicleId,
          }));
          setVehicleOptions(options);
        } else {
          const errorMessage = await response.text();
          toast.error(`Failed to fetch vehicles: ${errorMessage}`);
        }
      } catch (error) {
        toast.error(error.message);
      }
    };
    fetchVehicles();
  }, [organizationId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUpdatedEmployee({ ...updatedEmployee, [name]: value });
  };

  const handleUpdateEmployee = async () => {
    if (image) {
      setUpdating(true);
      const imageRef = storageRef(
        storage,
        `employees/${organizationId}/${updatedEmployee.rego}/` + image.name
      );
      try {
        // Upload image
        await uploadBytesResumable(imageRef, image);

        // Get download url
        const imageUrl = await getDownloadURL(imageRef);
        updatedEmployee.imageUrl = imageUrl;
        await updateDataServer();
      } catch (error) {
        console.error('Error uploading image:', error);
        toast.error('Failed to upload image.');
      }
    } else {
      // No image to upload, update data server directly
      await updateDataServer();
    }
  };
  const updateDataServer = async () => {
    if (
      updatedEmployee.name &&
      // updatedEmployee.rego &&
      updatedEmployee.email &&
      updatedEmployee.phone &&
      organizationId &&
      updatedEmployee.passcode
    ) {
      try {
        setUpdating(true);
        const server = process.env.REACT_APP_API_SERVER;
        const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;
        const credentials = btoa(`${username}:${password}`);

        const response = await fetch(
          `${server}/employees/${organizationId}/${updatedEmployee.empId}`,
          {
            method: 'PUT',
            body: JSON.stringify(updatedEmployee),
            headers: {
              Authorization: `Basic ${credentials}`,
              'Content-Type': 'application/json',
            },
          }
        );

        if (response.ok) {
          toast.success('Employee updated successfully!');
          onClose();
          onUpdate(); // Notify parent component about the update
        } else {
          if (response.status === 400) {
            toast.error('Invalid employee data');
          } else if (response.status === 404) {
            toast.error('Employee with rego not found ' + updatedEmployee.rego);
          } else {
            const errorMessage = await response.text();
            toast.error(`Failed to update employee: ${errorMessage}`);
          }
        }
      } catch (error) {
        toast.error(error.message);
      } finally {
        setUpdating(false);
      }
    } else {
      toast.error('Required parameters are missing!');
    }
  };

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div className='update-employee-modal'>
      <div className='uem-header-container'>
        <h3>Update Employee</h3>
        <div className='uem-img-container'>
          <input
            type='file'
            id='file'
            onChange={handleImgChoose}
            ref={fileInputRef}
            style={{ display: 'none' }}
          />
          {/* <label htmlFor='file' className='uem-img-label'>
            <FaCloudUploadAlt className='uem-icon' />
          </label> */}
          <div className='uem-uploadContainer' onClick={handleImageClick}>
            <img
              className='uem-img-Employee'
              src={
                image
                  ? URL.createObjectURL(image)
                  : updatedEmployee.imageUrl ||
                    'https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg'
              }
              alt=''
            />
          </div>
        </div>
      </div>
      <div className='uem-input-container'>
        <label>Rego:</label>
        {/* <input
                        type="text"
                        name="rego"
                        value={updatedEmployee.rego}
                        onChange={handleChange}
                    /> */}
        <Select
          options={vehicleOptions}
          value={{ value: updatedEmployee.rego, label: updatedEmployee.rego }}
          onChange={(selectedOption) =>
            setUpdatedEmployee({
              ...updatedEmployee,
              rego: selectedOption.value,
            })
          }
          styles={customStyles}
          className='react-select-container' // Remove unnecessary classnames
        />
      </div>
      <div className='uem-input-container'>
        <label>Name:</label>
        <input
          type='text'
          name='name'
          value={updatedEmployee.name}
          onChange={handleChange}
        />
      </div>
      <div className='uem-input-container'>
        <label>Email:</label>
        <input
          type='text'
          name='email'
          value={updatedEmployee.email}
          onChange={handleChange}
        />
      </div>
      <div className='uem-input-container'>
        <label>Phone:</label>
        <input
          type='text'
          name='phone'
          value={updatedEmployee.phone}
          onChange={handleChange}
        />
      </div>
      <div className='uem-input-container'>
        <label>Passcode:</label>
        <input
          type='text'
          name='passcode'
          value={updatedEmployee.passcode}
          onChange={handleChange}
        />
      </div>
      <div className='uem-btnContainer'>
        <div className='aem-div-btn add' onClick={handleUpdateEmployee}>
          <div className='aem-outer-rim'>
            <div className='aem-inner-rim'>
              {updating ? 'Updating...' : 'Update Employee'}
            </div>
          </div>
        </div>

        <div className='aem-div-btn cancel' onClick={onClose}>
          <div className='aem-outer-rim'>
            <div className='aem-inner-rim'>Cancel</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(UpdateEmployeeModal);
