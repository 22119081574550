import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import './AccountModal.css';
import LoadingSpinner from '../LoadingSpinner';
import iconEmployee from '../../assets/icon_employee.jpg';

const AccountModal = ({ isAccountModalOpen, closeAccountModal }) => {
  const navigate = useNavigate();
  const [info, setInfo] = useState(null);

  const email = sessionStorage.getItem('user_email');
  const organizationId = sessionStorage.getItem('org_id');

  // const [modalAnimation, setModalAnimation] = useState(''); //for close animation
  const [isLoading, setIsLoading] = useState(true);

  const fetchProfileData = async () => {
    try {
      // const server = "http://localhost:4567";
      const server = process.env.REACT_APP_API_SERVER;
      const username = process.env.REACT_APP_API_USERNAME;
      const password = process.env.REACT_APP_API_PASSWORD;
      const credentials = btoa(`${username}:${password}`);

      const response = await fetch(
        `${server}/orgs/${organizationId}/${email}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Basic ${credentials}`,
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        setInfo(data);
      } else {
        toast.error(`Error fetching data: ${response.status}`);
      }
    } catch (error) {
      toast.error(`Error fetching data: ${error.message}`);
      // console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchProfileData();
  }, []);

  const handleLogout = () => {
    sessionStorage.setItem('user_email', '');
    toast.success('Logout successful!');
    navigate('/login');
  };

  const onMyProfileClick = () => {
    const profileURL = '/my-profile'; // Update with the correct URL or path

    // Open the Fleet Table in a new tab
    window.open(profileURL, '_blank');
    // closeFleetTable();
  };

  return (
    <>
      {isAccountModalOpen && (
        <div className='am-account-modal'>
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <>
              <img
                src={info && info.imageUrl ? info.imageUrl : iconEmployee}
                alt={(info && info.name) || 'Profile'}
                className='am-itemImg'
              />
              <div className='am-user-details'>
                <h3>{info && info.name ? info.name : ''}</h3>
                <span>{info && info.email ? info.email : ''}</span>

                <div className='am-btn-container'>
                  <div className='am-div-btn' onClick={onMyProfileClick}>
                    <div className='am-div-btn-outer-rim'>
                      <div className='am-div-btn-inner-rim'>My Profile</div>
                    </div>
                  </div>

                  <div className='am-div-btn' onClick={handleLogout}>
                    <div className='am-div-btn-outer-rim'>
                      <div className='am-div-btn-inner-rim'>Logout</div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='close-modal' onClick={closeAccountModal}>
                <span>&times;</span>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default AccountModal;
