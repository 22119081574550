import React, { useEffect, useState } from 'react';
import './FleetManager.css';
import VehicleTable from '../../component/vehicles/VehiclesTable';
import EmployeeData from '../../component/employee/EmployeeData';
import logoImage from '../images/trucktronix icon.png';

const FleetManager = () => {
  // const organizationId = sessionStorage.getItem("org_id");
  const [employeesCount, setEmployeesCount] = useState(0);
  const [vehiclesCount, setVehiclesCount] = useState(0);

  const updateEmployeesCount = (count) => {
    setEmployeesCount(count);
  };

  const updateVehiclesCount = (count) => {
    setVehiclesCount(count);
  };

  return (
    <div className='fleetmanager-div-container'>
      <div className='fleetmanager-div-header'>
        <img src={logoImage} alt='Trucktronix' className='logo-image' />
        <h4>Fleet Manager</h4>
      </div>
      <div className='fleetmanager-div-body'>
        <div className='fleetmanager-div-body-details'>
          <div className='fleetmanager-div-body-details-outer-rim'>
            <div className='fleetmanager-div-body-details-inner-rim'>
              <span className='fleetmanager-span-details-header'>
                Fleet Overview
              </span>
              <div className='white-line'></div>
              <div className='fleetmanager-div-body-details-body'>
                <div className='fleetmanager-div-body-details-card'>
                  <div className='fleetmanager-div-body-details-card-outer'>
                    <div className='fleetmanager-div-body-details-card-inner'>
                      <span>Employees</span>
                      <span>{employeesCount}</span>
                    </div>
                  </div>
                </div>

                <div className='fleetmanager-div-body-details-card'>
                  <div className='fleetmanager-div-body-details-card-outer'>
                    <div className='fleetmanager-div-body-details-card-inner'>
                      <span>Vehicles</span>
                      <span>{vehiclesCount}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='fleetmanager-div-body-employees-vehicles'>
          <VehicleTable updateVehiclesCount={updateVehiclesCount} />
          <EmployeeData updateEmployeesCount={updateEmployeesCount} />
        </div>
      </div>
    </div>
  );
};

export default FleetManager;
