import React, { useEffect, useState, useRef } from 'react';
import icon_trucktronix from '../images/icon_trucktronix.png';
import './LiveMap.css';
import FloatingMenus from '../../component/homepage/FloatingMenus';
import AccountModal from '../../component/homepage/AccountModal';
import VehicleListCard from '../../component/homepage/VehicleListCard';
import MarkerBox from '../../component/homepage/MarkerBox';
import GeoFenceSettings from '../../component/homepage/GeoFenceSettings';
import GeoFenceAdvancedSettings from '../../component/homepage/GeoFenceAdvancedSettings';
import GeofenceListModal from '../../component/homepage/GeofenceListModal';
import { toast } from 'react-toastify';

//for geocoding
import L from 'leaflet';
import 'leaflet-control-geocoder/dist/Control.Geocoder.css';
import 'leaflet-control-geocoder';
import CustomPrompt from '../../component/homepage/CustomPrompt';
import { duration } from 'moment-timezone';
import { decode, encode } from '../../helper/PolylineEncodeDecode';

const LiveMap = () => {
  const INTERVAL_SEC = 5000; //in milliesecond value for vehicle tracking interval
  const [showVehicleList, setShowVehicleList] = useState(true);
  //for searching address on map
  const [isShowAutoCompleteResults, setIsShowAutoCompleteResults] =
    useState(false);
  const [autocompleteResults, setAutoCompleteResults] = useState([]);
  const searchAddressControlRef = useRef(null);
  const geocoderMapRef = useRef(null);

  const [updatedVehicleData, setUpdatedVehicleData] = useState(null); //data obtained from vehiclecard.
  const [isAccountModalOpen, setAccountModalOpen] = useState(false);
  const [isShowVehicleSettings, setIsShowVehicleSettings] = useState(false);
  const [isShowGeoFenceSettings, setIsShowGeoFenceSettings] = useState(false);
  const [geoFenceModalAnimation, setGeoFenceModalAnimation] = useState(''); //for close animation

  const [isShowAdvancedGeoFenceSettings, setIsShowAdvancedGeoFenceSettings] =
    useState(false);
  const [isShowGeoFenceList, setIsShowGeoFenceList] = useState(false);

  const [geoFenceModalAdvancedAnimation, setGeoFenceModalAdvancedAnimation] =
    useState(''); //for close animation
  const [geoFenceListAnimation, setGeoFenceListAnimation] = useState(''); //for close animation
  //to track selected vehicle from vehiclecard on map
  const selectedTrackVehicleIdRef = useRef(null);
  const tempSelectedTrackVehicleIdRef = useRef('');
  const isStartTrackingRef = useRef(false);

  const orgId = sessionStorage.getItem('org_id');
  const [recentLocations, setRecentLocations] = useState(null);
  const mapRef = useRef(null);

  //fleet plan for geofence
  const [vehicleListGeoFenceWarn, setVehicleListGeoFenceWarn] = useState([]);
  const vehicleListGeoFenceWarnRef = useRef([]);
  const [listVehiclePolygonKeyValue, setListVehiclePolygonKeyValue] = useState(
    []
  );

  //for line fence
  const polylineFleetPlanRef = useRef(null);

  //for area fence
  const verticeMainGroupListRef = useRef([]);
  const mainGroupRef = useRef(null);
  const mainGroupListRef = useRef([]);
  const verticeGroupRef = useRef();

  const currentPolygonIdRef = useRef('');
  const currentPolygonCoordinatesRef = useRef([]);
  const polygonLineStringRef = useRef(); //needed to generate currentPolygonRef
  const currentPolygonRef = useRef(null);

  const isMarkVehicleRef = useRef(false); //needed to check later if each vehicle is to be triggered for geofencing
  const polygonListGeofenceRef = useRef([]);

  const polygonColorRef = useRef({ r: 58, g: 95, b: 11 });
  const fleetPlanTypeRef = useRef(null);

  const routesGroupRef = useRef(null);

  //for marker
  const markers = useRef([]);
  const vehiclesDataRef = useRef([]);
  let isVehiclesDataLoaded = false;
  const tempVehiclesDataLength = useRef(0);
  const [selectedMarker, setSelectedMarker] = useState(null);

  //custom prompt
  const [customPromptAnimation, setCustomPromptAnimation] = useState('');
  const [isShowCustomPrompt, setIsShowCustomPrompt] = useState(false);

  const gpsIcon = `<path d="M12 0C7.27 0 3 4.28 3 9c0 5.11 7.55 13.85 8.14 14.36a.5.5 0 0 0 .72 0C13.45 22.85 21 14.11 21 9 21 4.28 16.73 0 12 0zm0 14c-1.45 0-2.62-1.34-2.62-3S10.55 8 12 8s2.62 1.34 2.62 3-1.17 3-2.62 3z" fill='FILL'> <animateTransform attributeName="transform" type="translate"  dur="1.5s" values="6 0;6 12;6 0" keySplines=".6 .1 .8 .1; .1 .8 .1 1" keyTimes="0;0.4;1" calcMode="spline" repeatCount="indefinite" /></path>`;

  const onAccountIconClick = () => {
    setAccountModalOpen((prevAccountModal) => !prevAccountModal);
  };
  const onVehicleIconClick = () => {
    setShowVehicleList((prevShowVehicleList) => !prevShowVehicleList);
  };

  const onHistoryIconClick = () => {
    // Specify the URL or path of the History component
    const fleetTableURL = '/history'; // Update with the correct URL or path
    // Open the Fleet Table in a new tab
    window.open(fleetTableURL, '_blank');
  };

  const onFleetTableIconClick = () => {
    // Specify the URL or path of the Fleet Table component
    const fleetTableURL = '/fleet-table'; // Update with the correct URL or path

    // Open the Fleet Table in a new tab
    window.open(fleetTableURL, '_blank');
  };

  const onFleetManagerIconClick = () => {
    // setEmployeesPageOpen(true);
    const fleetManager = '/fleet-manager';

    //open employee in new tab
    window.open(fleetManager, '_blank');
  };

  const onHelpIconClick = () => {
    const helpUrl = '/help';
    window.open(helpUrl, '_blank');
  };

  const fetchVehiclesData = async () => {
    try {
      const server = process.env.REACT_APP_API_SERVER;
      const username = process.env.REACT_APP_API_USERNAME;
      const password = process.env.REACT_APP_API_PASSWORD;
      const credentials = btoa(`${username}:${password}`);

      const response = await fetch(`${server}/recent/locations/${orgId}`, {
        headers: {
          Authorization: `Basic ${credentials}`,
        },
      });

      const data = await response.json();

      if (typeof data === 'object' && data !== null) {
        vehiclesDataRef.current = data;

        setRecentLocations(vehiclesDataRef.current);

        if (!isVehiclesDataLoaded) {
          isVehiclesDataLoaded = true; //recenter map on first load of vehicles data
          recenterMap();

          //initialise geofences from server
          //todo ujj is this the best place to initiate??
          fetchGeoFenceFromServer();
          fetchRegosForGeoFenceFromServer();
        }
      }
    } catch (error) {
      // console.error('ujj error fetching data: ', error);
    }
  };

  useEffect(() => {
    // Add meta tag to disable zooming
    const meta = document.createElement('meta');
    meta.name = 'viewport';
    meta.content =
      'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no';
    document.head.appendChild(meta);
    let geocoderMap = null;
    let hereMap = null;

    if (!mapRef.current) {
      // Map initialization code
      const platform = new window.H.service.Platform({
        apikey: process.env.REACT_APP_API_KEY,
      });

      const defaultLayers = platform.createDefaultLayers();

      hereMap = new window.H.Map(
        document.getElementById('map'),
        defaultLayers.vector.normal.map,
        // defaultLayers.vector.normal.truck,
        {
          center: { lat: -33.865143, lng: 151.2093 },
          zoom: 14,
          pixelRatio: window.devicePixelRatio || 1,
        }
      );

      // make the map interactive
      // MapEvents enables the event system
      // Behavior implements default interactions for pan/zoom (also on mobile touch environments)
      const behavior = new window.H.mapevents.Behavior(
        new window.H.mapevents.MapEvents(hereMap)
      );

      const ui = window.H.ui.UI.createDefault(hereMap, defaultLayers);
      const mapSettings = new window.H.ui.MapSettingsControl({
        alignment: 'top-left',
        baseLayers: [
          {
            label: 'Map',
            layer: defaultLayers.vector.normal.map,
          },
          {
            label: 'Truck Restrictions',
            layer: defaultLayers.vector.normal.truck,
          },
        ],
        layers: [
          {
            label: 'layer.traffic',
            layer: defaultLayers.vector.normal.traffic,
          },
          {
            label: 'layer.incidents',
            layer: defaultLayers.vector.normal.trafficincidents,
          },
        ],
      });

      // store scalebar
      // ui.removeControl('scalebar');
      ui.removeControl('zoom');
      ui.removeControl('mapsettings');
      ui.addControl('custom', mapSettings);

      // add a resize listener to make sure that the map occupies the whole container
      window.addEventListener('resize', () => {
        hereMap.getViewPort().resize();
      });

      mapRef.current = hereMap;
      routesGroupRef.current = new window.H.map.Group({
        visibility: true,
      });
      mapRef.current.addObject(routesGroupRef.current);

      //##########for autocomplete search######
      //create a separate leaflet map for the geocoder control
      geocoderMap = L.map(geocoderMapRef.current).setView(
        [-33.865143, 151.2093],
        2
      );

      // Add OpenStreetMap tile layer to geocoder map
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; OpenStreetMap contributors',
      }).addTo(geocoderMap);

      const geocodeControl = L.Control.geocoder({
        defaultMarkGeocode: false,
        collapsed: false,
        geocoder: L.Control.Geocoder.nominatim({
          geocodingQueryParams: {
            countrycodes: 'au',
          },
        }),
      }).addTo(geocoderMap);

      searchAddressControlRef.current = geocodeControl;

      //###end of geocoder leaflet

      let lineString = new window.H.geo.LineString();

      // event listener for marker on map click
      mapRef.current.addEventListener('tap', (event) => {
        const target = event.target;
        if (target instanceof window.H.map.DomMarker) {
          closeMarkerDetailsBox();
          //template for marker data that has been set
          //  .setData({
          //    vehicleId: vehicle.vehicleId,
          //    timestamp: vehicle.timestamp,
          //    latitude: vehicle.latitude,
          //    longitude: vehicle.longitude,
          //    status: vehicle.status,
          //  });
          const markerData = target.getData();

          //todo ujj handle update mark vehicle state for geo fencing
          if (
            markerData &&
            isMarkVehicleRef.current &&
            currentPolygonIdRef.current !== ''
          ) {
            // const updatedMarkers = markers.current.map((marker) => {
            //   if (
            //     marker.getData().vehicleId !== '' &&
            //     marker.getData().vehicleId === markerData.vehicleId
            //   ) {
            //     //update marker value for geofencing
            //     const updatedMarkerData = {
            //       ...markerData,
            //       state: 'marked', // Update the state to 'marked' or any other value
            //       polygonId: currentPolygonIdRef.current,
            //     };
            //     marker.setData(updatedMarkerData);
            //     console.log(
            //       'Marker clicked, updated marker data:',
            //       updatedMarkerData
            //     );
            //     return marker;
            //   } else {
            //     return marker;
            //   }
            // });
            // markers.current = updatedMarkers;

            //save selected marker to server for geofencing
            postFleetPlanVehicleRegosToServer(markerData.vehicleId);

            updateMarkerGeoFenceState(
              markerData.vehicleId.toUpperCase(),
              currentPolygonIdRef.current
            );

            //update key value list
            // listVehiclePolygonKeyValueRef.current.push({
            //   key: markerData.vehicleId,
            //   value: currentPolygonIdRef.current,
            // });
            // isMarkVehicleRef.current = false; //reset
          }

          console.log('Marker clicked, updated marker data:', markerData);

          const screenWidth =
            window.innerWidth ||
            document.documentElement.clientWidth ||
            document.body.clientWidth;
          const screenHeight =
            window.innerHeight ||
            document.documentElement.clientHeight ||
            document.body.clientHeight;

          const vehicleCardData = {
            latitude: markerData.latitude,
            longitude: markerData.longitude,
            vehicleId: markerData.vehicleId,
            timestamp: markerData.timestamp,
            address: markerData.address,
            status: markerData.status,
            empId: markerData.empId,
            top: screenHeight / 2 - 320,
            left: screenWidth / 2 - 110,
            // top: event.currentPointer.viewportY - 300,
            // left: event.currentPointer.viewportX - 120,
          };

          showMarkerBoxOnMap(vehicleCardData);
        } else {
          const coord = mapRef.current.screenToGeo(
            event.currentPointer.viewportX,
            event.currentPointer.viewportY
          );

          console.log(
            'ujj clicked at: ' +
              coord.lat.toFixed(6) +
              ', ' +
              coord.lng.toFixed(6) +
              'for fleetplan' +
              fleetPlanTypeRef.current
          );

          if (fleetPlanTypeRef.current === 'setPaths') {
            //for other tap implement fleet-plans
            //todo
            lineString.pushPoint({
              lat: coord.lat.toFixed(6),
              lng: coord.lng.toFixed(6),
            });

            if (polylineFleetPlanRef.current) {
              mapRef.current.removeObject(polylineFleetPlanRef.current);
            } else {
              //create linestring 2 times first time because line needs 2 points
              lineString.pushPoint({
                lat: coord.lat,
                lng: coord.lng,
              });
            }

            polylineFleetPlanRef.current = new window.H.map.Polyline(
              lineString,
              {
                style: { lineWidth: 4 },
              }
            );

            mapRef.current.addObject(polylineFleetPlanRef.current);
          } else if (fleetPlanTypeRef.current === 'setArea') {
            currentPolygonCoordinatesRef.current.push({
              latitude: coord.lat.toFixed(6),
              longitude: coord.lng.toFixed(6),
            });

            polygonLineStringRef.current.pushPoint(coord);
            const polygonGeometry = new window.H.geo.Polygon(
              polygonLineStringRef.current
            );

            updatePolygon(polygonGeometry);
          }
        }
      });
    }

    //for geofence polygon
    function updatePolygon(polygonGeometry) {
      if (!currentPolygonRef.current) {
        console.log('ujj geofence create new polygon ');
        createPolygonResizable(mapRef.current, polygonGeometry);
      } else {
        // Update the existing polygon's geometry
        currentPolygonRef.current.setGeometry(polygonGeometry);
      }
    }

    //for markers on map
    const resetMarkersForUsers = () => {
      mapRef.current.removeObjects(markers.current);
      markers.current = [];
    };

    const createMarkersForUsers = () => {
      resetMarkersForUsers();

      Object.values(vehiclesDataRef.current).forEach((vehicle) => {
        createMarkers(mapRef.current, vehicle);
      });
    };

    const createMarkers = (map, vehicle) => {
      const svg = `<svg
                    version='1.1'
                    xmlns='http://www.w3.org/2000/svg'
                    style="width: 80px; height: 100px; margin: -25px 0 0 -25px; cursor: pointer;" viewBox="0 0 100 100">
                      <text x="25%" y="0%" text-anchor="middle" dy="0.2em" fill="rgb(24, 89, 29)" font-weight="bold" font-size="12">${vehicle.vehicleId}</text>

                      <ellipse fill='#000' cx='38' cy='35' rx='6' ry='2'>
                        <animate
                          attributeName='cx'
                          from='38'
                          to='38'
                          begin='0s'
                          dur='1.5s'
                          values='38;20;38'
                          keySplines='.6 .1 .8 .1; .1 .8 .1 1'
                          keyTimes='0;0.4;1'
                          calcMode='spline'
                          repeatCount='indefinite'
                        />

                        <animate
                          attributeName='rx'
                          from='10'
                          to='10'
                          begin='0s'
                          dur='1.5s'
                          values='10;4;10'
                          keySplines='.6 .0 .8 .0; .0 .8 .0 1'
                          keyTimes='0;0.4;1'
                          calcMode='spline'
                          repeatCount='indefinite'
                        />

                        <animate
                          attributeName='opacity'
                          from='.2'
                          to='.2'
                          begin='0s'
                          dur='1.5s'
                          values='.1;.7;.1'
                          keySplines=' .6.0 .8 .0; .0 .8 .0 1'
                          keyTimes=' 0;0.4;1'
                          calcMode='spline'
                          repeatCount='indefinite'
                        />
                      </ellipse>

                      <g transform="translate(5 0)">
                        ${gpsIcon}
                      </g>
            </svg>`;

      let domIcon;
      if (vehicle.status === 'online') {
        domIcon = new window.H.map.DomIcon(svg.replace('FILL', '#3A5F0B'));
      } else if (vehicle.status === 'paused') {
        domIcon = new window.H.map.DomIcon(svg.replace('FILL', '#fa7f00'));
      } else {
        //offline
        domIcon = new window.H.map.DomIcon(svg.replace('FILL', '#EC2D01'));
      }

      const domMarker = new window.H.map.DomMarker(
        { lat: vehicle.latitude, lng: vehicle.longitude },
        { icon: domIcon }
      );

      //set vehicleId as data for the marker
      domMarker.setData({
        vehicleId: vehicle.vehicleId,
        timestamp: vehicle.timestamp,
        latitude: vehicle.latitude,
        longitude: vehicle.longitude,
        status: vehicle.status,
        address: vehicle.address,
        empId: vehicle.empId,
        state: '',
        polygonId: '',
      });

      addMarker(map, domMarker);
    };

    const addMarker = (map, domMarker) => {
      markers.current.push(domMarker);
      map.addObject(domMarker);
    };

    const updateMarkersPosition = (vehicle) => {
      //update markers
      markers.current.forEach((marker) => {
        if (
          marker.getData() &&
          marker.getData().vehicleId === vehicle.vehicleId
        ) {
          //##also check if marker is outside the geo fence
          polygonListGeofenceRef.current.forEach((polygon) => {
            if (
              marker.getData().state != null &&
              marker.getData().state === 'marked' &&
              polygon
            ) {
              if (marker.getData().polygonId === polygon.data) {
                checkMarkerOnPolygon(marker, polygon); //todo ujj repeating too much
              }
            }
          });

          //##update marker
          const newPoint = { lat: vehicle.latitude, lng: vehicle.longitude };
          // update marker's position within ease function callback
          ease(marker.getGeometry(), newPoint, 5000, (coord) => {
            marker.setGeometry(coord);

            //if user has selected a particular vehicle then track that vehicle on map as well
            if (selectedTrackVehicleIdRef.current) {
              if (
                selectedTrackVehicleIdRef.current === vehicle.vehicleId &&
                isStartTrackingRef.current
              ) {
                recenterToVehicle(coord.lat, coord.lng, 300);
                // mapRef.current.setCenter(coord);
              }
            }
          });

          if (marker instanceof window.H.map.DomMarker) {
            //this will update domMarker data
            marker.setData({
              vehicleId: vehicle.vehicleId,
              timestamp: vehicle.timestamp,
              latitude: vehicle.latitude,
              longitude: vehicle.longitude,
              status: vehicle.status,
              address: vehicle.address,
              empId: vehicle.empId,
              state: marker.getData().state,
              polygonId: marker.getData().polygonId,
            });
            //update domMarker status color
            const svg = `<svg
                    version='1.1'
                    xmlns='http://www.w3.org/2000/svg'
                    style="width: 80px; height: 100px; margin: -25px 0 0 -25px; cursor: pointer;" viewBox="0 0 100 100">
                      <text x="25%" y="0%" text-anchor="middle" dy="0.2em" fill="rgb(24, 89, 29)" font-weight="bold" font-size="12">${vehicle.vehicleId}</text>

                      <ellipse fill='#000' cx='38' cy='35' rx='6' ry='2'>
                        <animate
                          attributeName='cx'
                          from='38'
                          to='38'
                          begin='0s'
                          dur='1.5s'
                          values='38;20;38'
                          keySplines='.6 .1 .8 .1; .1 .8 .1 1'
                          keyTimes='0;0.4;1'
                          calcMode='spline'
                          repeatCount='indefinite'
                        />

                        <animate
                          attributeName='rx'
                          from='10'
                          to='10'
                          begin='0s'
                          dur='1.5s'
                          values='10;4;10'
                          keySplines='.6 .0 .8 .0; .0 .8 .0 1'
                          keyTimes='0;0.4;1'
                          calcMode='spline'
                          repeatCount='indefinite'
                        />

                        <animate
                          attributeName='opacity'
                          from='.2'
                          to='.2'
                          begin='0s'
                          dur='1.5s'
                          values='.1;.7;.1'
                          keySplines=' .6.0 .8 .0; .0 .8 .0 1'
                          keyTimes=' 0;0.4;1'
                          calcMode='spline'
                          repeatCount='indefinite'
                        />
                      </ellipse>

                      <g transform="translate(5 0)">
                        ${gpsIcon}
                      </g>
            </svg>`;

            let domIcon;
            if (vehicle.status === 'online') {
              domIcon = new window.H.map.DomIcon(
                svg.replace('FILL', '#3A5F0B')
              );
            } else if (vehicle.status === 'paused') {
              domIcon = new window.H.map.DomIcon(
                svg.replace('FILL', '#fa7f00')
              );
            } else {
              //offline
              domIcon = new window.H.map.DomIcon(
                svg.replace('FILL', '#EC2D01')
              );
            }

            marker.setIcon(domIcon);
          }
        }
      });

      //for zooming properly to tracked vehicle
      //first reset isStartTracking for new track
      if (
        tempSelectedTrackVehicleIdRef.current !==
        selectedTrackVehicleIdRef.current
      ) {
        tempSelectedTrackVehicleIdRef.current =
          selectedTrackVehicleIdRef.current;
        isStartTrackingRef.current = false;
      }

      //second center to vehicle to zoom properly then only set isStartTracking to true
      if (selectedTrackVehicleIdRef.current) {
        if (
          selectedTrackVehicleIdRef.current === vehicle.vehicleId &&
          !isStartTrackingRef.current
        ) {
          recenterToVehicle(vehicle.latitude, vehicle.longitude, 1000);

          setTimeout(() => {
            isStartTrackingRef.current = true;
          }, 3000);
        }
      }
    };

    const ease = (
      startCoord = { lat: 0, lng: 0 },
      endCoord = { lat: 1, lng: 1 },
      durationMs = 200,
      onStep = console.log,
      onComplete = () => {}
    ) => {
      const raf =
        window.requestAnimationFrame || ((f) => window.setTimeout(f, 16));
      const stepCount = durationMs / 16;
      const valueIncrementLat = (endCoord.lat - startCoord.lat) / stepCount;
      const valueIncrementLng = (endCoord.lng - startCoord.lng) / stepCount;
      const sinValueIncrement = Math.PI / stepCount;

      let currentValueLat = startCoord.lat;
      let currentValueLng = startCoord.lng;
      let currentSinValue = 0;

      const step = () => {
        currentSinValue += sinValueIncrement;
        currentValueLat +=
          valueIncrementLat * Math.pow(Math.sin(currentSinValue), 2) * 2;
        currentValueLng +=
          valueIncrementLng * Math.pow(Math.sin(currentSinValue), 2) * 2;

        if (currentSinValue < Math.PI) {
          onStep({ lat: currentValueLat, lng: currentValueLng });
          raf(step);
        } else {
          onStep(endCoord);
          onComplete();
        }
      };

      raf(step);
    };

    const updateUsersLocation = async () => {
      await fetchVehiclesData();
      const values = Object.values(vehiclesDataRef.current);

      if (values.length > tempVehiclesDataLength.current) {
        //if new users added found then create
        tempVehiclesDataLength.current = values.length;

        createMarkersForUsers();
      } else {
        //if same old users then update
        Object.values(vehiclesDataRef.current).forEach((vehicle) => {
          updateMarkersPosition(vehicle);
        });
      }
    };

    //interval fetch
    const intervalId = setInterval(updateUsersLocation, INTERVAL_SEC);

    return () => {
      document.head.removeChild(meta);
      clearInterval(intervalId);
      // map.dispose();
      mapRef.current.removeEventListener('tap', () => {});
      if (routesGroupRef.current) {
        routesGroupRef.current.removeAll();
      }
      window.removeEventListener('resize', () => {
        mapRef.current.getViewPort().resize();
      });

      if (mainGroupRef.current) {
        mainGroupRef.current.removeEventListener('pointerenter', () => {});
        mainGroupRef.current.removeEventListener('pointerleave', () => {});
      }

      if (verticeGroupRef.current) {
        verticeGroupRef.current.removeEventListener('pointerenter', () => {});
        verticeGroupRef.current.removeEventListener('pointerleave', () => {});
      }

      if (geocoderMap) {
        geocoderMap.remove();
      }

      // if (hereMap) {
      //   hereMap.remove();
      // }
    };
  }, []);

  const handleRecenterMapToGeoFence = (geofenceCoordinateArray) => {
    if (geofenceCoordinateArray.length > 0 && mapRef.current) {
      let minLat = geofenceCoordinateArray[0][0];
      let maxLat = geofenceCoordinateArray[0][0];
      let minLng = geofenceCoordinateArray[0][1];
      let maxLng = geofenceCoordinateArray[0][1];

      geofenceCoordinateArray.forEach((coord) => {
        const [lat, lng] = coord;
        console.log('ujj array: lat' + lat + ' ,lng: ' + lng);

        // Update min and max values for latitude
        if (lat < minLat) {
          minLat = lat;
        }
        if (lat > maxLat) {
          maxLat = lat;
        }
        // Update min and max values for longitude
        if (lng < minLng) {
          minLng = lng;
        }
        if (lng > maxLng) {
          maxLng = lng;
        }
      });
      console.log('ujj array: min lat' + minLat + ' ,min lng: ' + minLng);
      console.log('ujj array: max lat' + maxLat + ' ,max lng: ' + maxLng);

      // Create a bounding box
      const boundingBox = new window.H.geo.Rect(minLat, minLng, maxLat, maxLng);

      mapRef.current.getViewModel().setLookAtData(
        {
          bounds: boundingBox,
        },
        {
          duration: 1000,
          ease: window.H.util.animation.easeInQuad,
        }
      );
    }
  };

  //centers map to around available vehicles
  const recenterMap = () => {
    if (mapRef.current) {
      let minLat = Number.MAX_VALUE;
      let maxLat = -Number.MAX_VALUE;
      let minLng = Number.MAX_VALUE;
      let maxLng = -Number.MAX_VALUE;

      Object.values(vehiclesDataRef.current).forEach((vehicle) => {
        minLat = Math.min(minLat, vehicle.latitude - 0.005);
        maxLat = Math.max(maxLat, vehicle.latitude + 0.005);
        minLng = Math.min(minLng, vehicle.longitude - 0.005);
        maxLng = Math.max(maxLng, vehicle.longitude + 0.005);
      });

      // Create a bounding box
      const boundingBox = new window.H.geo.Rect(minLat, minLng, maxLat, maxLng);

      closeMarkerDetailsBox();

      mapRef.current.getViewModel().setLookAtData(
        {
          bounds: boundingBox,
        },
        {
          duration: 1000,
          ease: window.H.util.animation.easeInQuad,
        }
      );
    }
  };

  // marker box with vehicle details is shown on map on clicking vehicle card or marker on map
  const showMarkerBoxOnMap = (vehicleCardData) => {
    if (mapRef.current) {
      closeMarkerDetailsBox();

      //  domMarker.setData({
      //    vehicleId: vehicle.vehicleId,
      //    timestamp: vehicle.timestamp,
      //    latitude: vehicle.latitude,
      //    longitude: vehicle.longitude,
      //    status: vehicle.status,
      //  });

      recenterToVehicle(
        vehicleCardData.latitude,
        vehicleCardData.longitude,
        1000
      );

      // Set a timeout to handle the end of the animation
      setTimeout(() => {
        const markerData = {
          vehicleId: vehicleCardData.vehicleId,
          timestamp: vehicleCardData.timestamp,
          latitude: vehicleCardData.latitude,
          longitude: vehicleCardData.longitude,
          status: vehicleCardData.status,
          address: vehicleCardData.address,
          empId: vehicleCardData.empId,
        };

        // Calculate the positioning values for MarkerBox
        const left = vehicleCardData.left;
        const top = vehicleCardData.top;

        setSelectedMarker({ markerData, position: { top, left } });
      }, 500);
    }
  };

  //centers map to the selected vehicle. zoom level is closer to map than before
  const recenterToVehicle = (latitude, longitude, durationMS) => {
    if (mapRef.current) {
      //change boundingbox to change zoom level
      const boundingBox = new window.H.geo.Rect(
        latitude - 0.001,
        longitude - 0.001,
        latitude + 0.001,
        longitude + 0.001
      );

      mapRef.current.getViewModel().setLookAtData(
        {
          bounds: boundingBox,
        },
        {
          duration: durationMS,
          ease: window.H.util.animation.easeInQuad,
        }
      );
    }
  };

  const updateMarkerBoxData = (updatedVehicleData) => {
    if (selectedMarker) {
      let selectedRego = selectedMarker.markerData.vehicleId;
      let updatedRego = updatedVehicleData.rego;

      if (selectedRego === updatedRego) {
        setUpdatedVehicleData(updatedVehicleData);
      }
    }
  };

  const closeMarkerDetailsBox = () => {
    setSelectedMarker(null);
  };

  // ### geofencing

  //delete geofence from server
  const deleteGeoFenceFromServer = async (geoFenceId) => {
    if (geoFenceId !== '') {
      try {
        const server = process.env.REACT_APP_API_SERVER;
        const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const credentials = btoa(`${username}:${password}`);
        const response = await fetch(
          `${server}/geofence/${orgId}/${geoFenceId}`,
          {
            method: 'DELETE',
            headers: {
              Authorization: `Basic ${credentials}`,
            },
          }
        );

        if (response.ok) {
          toast.success('Geofence has been deleted');
          //remove geo fences from map too
          console.log(
            'ujj length on delete: ' + vehicleListGeoFenceWarn.length
          );
        }
      } catch (error) {
        console.error('error deleting geo fences' + error);
      }
    }
  };

  //retrieve all regos saved in server for geo fencing
  const fetchRegosForGeoFenceFromServer = async () => {
    try {
      const server = process.env.REACT_APP_API_SERVER;
      const username = process.env.REACT_APP_API_USERNAME;
      const password = process.env.REACT_APP_API_PASSWORD;

      const credentials = btoa(`${username}:${password}`);
      const response = await fetch(`${server}/association/${orgId}`, {
        method: 'GET',
        headers: {
          Authorization: `Basic ${credentials}`,
        },
      });

      if (response.ok) {
        const regoGeoFenceArray = await response.json();
        regoGeoFenceArray.forEach((obj) => {
          const key = Object.keys(obj)[0];
          const value = obj[key];
          console.log('ujj geofence associated vehicles: ' + key + ',' + value);

          updateMarkerGeoFenceState(key, value);
        });
      }
    } catch (error) {
      console.error('error fetching geofence regos list' + error);
    }
  };

  const updateMarkerGeoFenceState = (vehicleId, polygonId) => {
    //check if the key already exists
    // let keyExists = listVehiclePolygonKeyValue.some(
    //   (existingPair) => existingPair.key === vehicleId
    // );
    const index = listVehiclePolygonKeyValue.findIndex(
      (existingPair) => existingPair.key === vehicleId
    );

    if (index === -1) {
      //add key value list
      setListVehiclePolygonKeyValue((prevPairs) => [
        ...prevPairs,
        {
          key: vehicleId,
          value: polygonId,
        },
      ]);
    } else {
      //update key value list
      setListVehiclePolygonKeyValue((prevPairs) => {
        const updatedPairs = [...prevPairs];
        updatedPairs[index].value = polygonId;
        return updatedPairs;
      });
    }

    markers.current.forEach((marker) => {
      const data = marker.getData();

      //ujj had to do toUppercase since vehicleId in Geofence is saved in uppercase and vehicleId in Vehicle is saved in anycase
      if (data && data.vehicleId.toUpperCase() === vehicleId.toUpperCase()) {
        data.state = 'marked';
        data.polygonId = polygonId;
      }
    });
  };

  //for fetching geofences from server
  const fetchGeoFenceFromServer = async () => {
    try {
      const server = process.env.REACT_APP_API_SERVER;
      const username = process.env.REACT_APP_API_USERNAME;
      const password = process.env.REACT_APP_API_PASSWORD;

      const credentials = btoa(`${username}:${password}`);
      const response = await fetch(`${server}/geofence/${orgId}`, {
        method: 'GET',
        headers: {
          Authorization: `Basic ${credentials}`,
        },
      });

      if (response.ok) {
        const geoFenceArray = await response.json();
        geoFenceArray.forEach((element) => {
          console.log('ujj geofence from server: ' + element.polygonId);

          createServerGeoFencePolygon(mapRef.current, element);
        });
      }
    } catch (error) {
      console.error('error fetchihng geo fences' + error);
    }
  };

  //convert hex to rgb for polygon creation later
  const convertHexToRgb = (hexColor) => {
    //remove hash
    hexColor = hexColor.replace(/^#/, '');

    //parse hex to rgb
    const bigint = parseInt(hexColor, 16);

    //extract rgb values
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    // Return the RGB values as an object
    return { r, g, b };
  };

  const createServerGeoFencePolygon = (map, element) => {
    const polygonLineString = new window.H.geo.LineString();

    const coordinates = element.coordinates;
    coordinates.forEach((coordinate) => {
      const lat = coordinate[0];
      const lng = coordinate[1];
      polygonLineString.pushPoint({ lat, lng });
    });

    const { r, g, b } = convertHexToRgb(element.backgroundColor);

    const polygon = new window.H.map.Polygon(polygonLineString, {
      style: {
        strokeColor: '#f18d11',
        lineWidth: 2,
        fillColor: `rgba(${r}, ${g}, ${b}, 0.5)`,
      },
      data: element.polygonId,
    });

    polygonListGeofenceRef.current.push(polygon);
    // currentPolygonRef.current.setGeometry(polygonGeometry); //todo ujj check with createPolygonResizable if needed

    verticeGroupRef.current = new window.H.map.Group({
      visibility: false,
    });

    const mainGroup = new window.H.map.Group({
      volatility: true, // mark the group as volatile for smooth dragging of all it's objects
      objects: [polygon, verticeGroupRef.current],
      data: element.polygonId,
    });

    // ensure that the polygon can receive drag events
    polygon.draggable = true;

    // add group with polygon and it's vertices (markers) on the map
    map.addObject(mainGroup);

    //also add maingroup to list so that i can be deleted later if needed
    mainGroupListRef.current.push(mainGroup);

    polygon.addEventListener('tap', (evt) => {
      //todo ujj maybe first reset the polygons attributes and maingroupref??
      console.log('ujj geofence tapped polygon is: ' + evt.target.getData());

      mainGroupListRef.current.forEach((maingroup) => {
        if (maingroup.getData() === evt.target.getData()) {
          console.log('ujj maingroup: ' + maingroup.getData());
          mainGroupRef.current = maingroup;
        }
      });

      //if not marking vehicle / if not any polygon selected then select polygon and markvehicle true
      isMarkVehicleRef.current = true;
      currentPolygonIdRef.current = evt.target.getData();

      handleShowGeoFenceSettings();
    });
  };

  // create geo fences according to fleet plan type
  const handleDoneFleetPlan = (fleetPlanType, isClose, geofenceId) => {
    console.log('ujj geofence done for fleet plan type ' + fleetPlanType);

    if (fleetPlanType === 'setArea') {
      //recreate markers for updated vertices

      if (currentPolygonRef.current) {
        //todo ujj check if its needed
        let svgCircle =
          '<svg width="20" height="20" version="1.1" xmlns="http://www.w3.org/2000/svg">' +
          '<circle cx="10" cy="10" r="7" fill="transparent" stroke="#f18d11" stroke-width="4"/>' +
          '</svg>';

        currentPolygonRef.current
          .getGeometry()
          .getExterior()
          .eachLatLngAlt(function (lat, lng, alt, index) {
            var vertice = new window.H.map.Marker(
              { lat, lng },
              {
                icon: new window.H.map.Icon(svgCircle, {
                  anchor: { x: 10, y: 10 },
                }),
              }
            );
            if (vertice) {
              vertice.draggable = true;
              vertice.setData({ verticeIndex: index });
              verticeGroupRef.current.addObject(vertice);
            }
          });

        verticeMainGroupListRef.current.push({ mainGroupRef, verticeGroupRef });

        if (geofenceId !== '') {
          //UPDATE the id obtained from server response
          currentPolygonRef.current.setData(geofenceId);
          mainGroupRef.current.setData(geofenceId); //todo is this best way to update the maingroupref data??
        }
      }

      fleetPlanTypeRef.current = '';
      isMarkVehicleRef.current = false;
    }

    if (isClose) {
      handleHideGeoFenceSettings();
    } else {
      // if not isClose then it the uncheck of quick fence hence remove the temp polygon created on map
      if (currentPolygonRef.current && mainGroupRef.current) {
        mapRef.current.removeObject(mainGroupRef.current);
        //reset
        currentPolygonCoordinatesRef.current = [];
      }
    }
  };

  const handleDoneAdvancedFleetPlan = (fleetPlanType) => {
    if (fleetPlanType === 'setArea') {
      fleetPlanTypeRef.current = '';
      isMarkVehicleRef.current = false;
    }
  };

  const handleCreateAdvancedFleetPlan = (fleetPlanType, geoFenceId) => {
    if (fleetPlanType === 'setArea') {
      fleetPlanTypeRef.current = '';
      console.log('ujj geofence creating polygon with id:' + geoFenceId);
      //update the id obtained from server response
      currentPolygonRef.current.setData(geoFenceId);
      //UPDATE currentpolygonIdref as well
      currentPolygonIdRef.current = geoFenceId;
      //update maingroupref id too
      const indexToUpdate = mainGroupListRef.current.findIndex(
        (maingroupElement) => maingroupElement === mainGroupRef.current
      );

      if (indexToUpdate !== -1) {
        //update in list
        mainGroupListRef.current[indexToUpdate].setData(geoFenceId);
      } else {
        console.error('mainGroupListRef update failed. cannot find index');
      }
    }
  };

  const handleAdvancedGeoFenceCancel = () => {
    if (currentPolygonRef.current && mainGroupRef.current) {
      mapRef.current.removeObject(mainGroupRef.current);
      //reset
      currentPolygonCoordinatesRef.current = [];
    }
  };

  const handleGeoFenceIconClick = () => {
    if (!isShowGeoFenceSettings) {
      //reset
      currentPolygonIdRef.current = '';
      polygonColorRef.current = { r: 58, g: 95, b: 11 }; //reset to default
      mainGroupRef.current = null;

      handleShowGeoFenceSettings();
    } else {
      handleHideGeoFenceSettings();
    }
  };

  const handleRemoveVehiclePolygonPair = (vehicleId) => {
    const updateKeyValuePairs = listVehiclePolygonKeyValue.filter(
      (pair) => pair.key !== vehicleId
    );

    setListVehiclePolygonKeyValue(updateKeyValuePairs);

    console.log(
      'ujj length of pair list is: ' + listVehiclePolygonKeyValue.length
    );

    markers.current.forEach((marker) => {
      const data = marker.getData();

      //ujj had to do toUppercase since vehicleId in Geofence is saved in uppercase and vehicleId in Vehicle is saved in anycase
      if (data && data.vehicleId.toUpperCase() === vehicleId.toUpperCase()) {
        data.state = '';
        data.polygonId = '';
      }
    });

    removeUserAssociatedPolygonList(vehicleId);
  };

  const handleGeoFenceDeleteFromList = (polygonId) => {
    mainGroupListRef.current.forEach((maingroup) => {
      if (maingroup.getData() === polygonId) {
        console.log('ujj maingroup: ' + maingroup.getData());
        mainGroupRef.current = maingroup;
      }
    });

    handleGeoFenceDeleteClick();
  };

  const handleGeoFenceDeleteClick = () => {
    if (mainGroupRef.current && mapRef.current) {
      //remove geofence from map
      mapRef.current.removeObject(mainGroupRef.current);

      //remove polygon from polygonListGeofenceRef
      const polygonIndex = polygonListGeofenceRef.current.findIndex(
        (polygon) => polygon.getData() === mainGroupRef.current.getData()
      );
      if (polygonIndex !== -1) {
        polygonListGeofenceRef.current[polygonIndex].dispose();
        polygonListGeofenceRef.current.splice(polygonIndex, 1);
      }

      //remove user accociated with this geofencing
      listVehiclePolygonKeyValue.forEach((pair) => {
        if (pair.value === mainGroupRef.current.getData()) {
          console.log(
            'ujj length polygon to be removed is : ' +
              pair.value +
              ' ##vehicle: ' +
              pair.key
          );
          removeUserAssociatedPolygonList(pair.key);
        }
      });

      //delete geo fence from server
      deleteGeoFenceFromServer(mainGroupRef.current.getData());

      //finally
      mainGroupRef.current = null;
    } else {
      toast.info('Select geofence on map');
    }
  };

  const removeUserAssociatedPolygonList = (vehicleId) => {
    //remove user accociated with this geofencing

    vehicleListGeoFenceWarnRef.current =
      vehicleListGeoFenceWarnRef.current.filter((id) => id !== vehicleId);
    console.log(
      'ujj length ref is: ' + vehicleListGeoFenceWarnRef.current.length
    );
    const updatedList = vehicleListGeoFenceWarnRef.current;
    setVehicleListGeoFenceWarn(updatedList);
    setVehicleListGeoFenceWarn(vehicleListGeoFenceWarnRef.current);
  };

  const handleShowGeoFenceSettings = () => {
    setIsShowGeoFenceSettings(true);

    setTimeout(() => {
      setGeoFenceModalAnimation('visible');
    }, 100); // Ensure this matches the transition duration
  };

  const handleHideGeoFenceSettings = () => {
    setGeoFenceModalAnimation('');
    setTimeout(() => {
      setIsShowGeoFenceSettings(false);
    }, 300); // Ensure this matches the transition duration
  };

  const handleShowAdvancedGeoFence = () => {
    setIsShowAdvancedGeoFenceSettings(true);
    //reset
    currentPolygonCoordinatesRef.current = [];

    setTimeout(() => {
      setGeoFenceModalAdvancedAnimation('visible');
    }, 100);
  };

  const handleCloseAdvancedGeoFence = () => {
    setGeoFenceModalAdvancedAnimation('');
    setTimeout(() => {
      setIsShowAdvancedGeoFenceSettings(false);
    }, 300);
  };

  const handleShowGeoFenceList = () => {
    setIsShowGeoFenceList(true);
    setTimeout(() => {
      setGeoFenceListAnimation('visible');
    }, 100);
  };

  const handleHideGeoFenceList = () => {
    setGeoFenceListAnimation('');
    setTimeout(() => {
      setIsShowGeoFenceList(false);
    }, 300); // Ensure this matches the transition duration
  };

  //create random name for polygons so that they can be differentiated in array
  function createRandomPolygonName() {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let result = '';
    const charactersLength = characters.length;

    for (let i = 0; i < 6; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
  }

  //just set fleetPlanType so that user can mark on map the edges
  const setQuickGeoFenceOnMap = (type) => {
    console.log('ujj quick geofence creating polygon type is ' + type);
    fleetPlanTypeRef.current = type;
    polygonColorRef.current = { r: 58, g: 95, b: 11 }; //reset to default

    //create new polygons
    currentPolygonCoordinatesRef.current = [];

    polygonLineStringRef.current = new window.H.geo.LineString();
    currentPolygonRef.current = null;
    currentPolygonIdRef.current = createRandomPolygonName();
    polygonColorRef.current = { r: 58, g: 95, b: 11 };
  };

  const setAdvancedGeoFenceOnMap = (polygonId, polygonType, polygonColor) => {
    console.log(
      'ujj advanced geofence creating polygon type is ' + polygonType
    );
    fleetPlanTypeRef.current = polygonType;

    //create new polygons
    polygonLineStringRef.current = new window.H.geo.LineString();
    currentPolygonRef.current = null;
    currentPolygonIdRef.current = polygonId;
    polygonColorRef.current = polygonColor;
  };

  const createPolygonResizable = (map, polygonGeometry) => {
    // let svgCircle =
    //   '<svg width="20" height="20" version="1.1" xmlns="http://www.w3.org/2000/svg">' +
    //   '<circle cx="10" cy="10" r="7" fill="transparent" stroke="#f18d11" stroke-width="4"/>' +
    //   '</svg>';
    // let polygon = new window.H.map.Polygon(
    //   new f([
    //     -33.840137, 150.883886, 0, -33.837449, 150.881416, 0, -33.838151,
    //     150.885802, 0,
    //   ]),
    //   {
    //     style: {
    //       strokeColor: '#f18d11',
    //       lineWidth: 2,
    //       fillColor: 'rgba(58, 95, 11, 0.5)', // Green fill color with 50% opacity
    //     },
    //     data: 'polygon',
    //   }
    // );
    // Create a new polygon if it doesn't exist

    currentPolygonRef.current = new window.H.map.Polygon(
      polygonLineStringRef.current,
      {
        style: {
          strokeColor: '#f18d11',
          lineWidth: 2,
          fillColor: `rgba(${polygonColorRef.current.r}, ${polygonColorRef.current.g}, ${polygonColorRef.current.b}, 0.5)`, // default Green fill color with 50% opacity
        },
        data: currentPolygonIdRef.current,
      }
    );

    polygonListGeofenceRef.current.push(currentPolygonRef.current);
    currentPolygonRef.current.setGeometry(polygonGeometry);

    verticeGroupRef.current = new window.H.map.Group({
      visibility: false,
    });

    //remove old polygon on map. shouldnot be removed since need mulitple polygon is map.
    // if (mainGroupRef.current) {
    //   map.removeObject(mainGroupRef.current);
    // }

    mainGroupRef.current = new window.H.map.Group({
      volatility: true, // mark the group as volatile for smooth dragging of all it's objects
      objects: [currentPolygonRef.current, verticeGroupRef.current],
      data: currentPolygonIdRef.current,
    });

    // ensure that the polygon can receive drag events
    currentPolygonRef.current.draggable = true;

    // add group with polygon and it's vertices (markers) on the map
    map.addObject(mainGroupRef.current);

    //also add maingroup to list so that i can be deleted later if needed
    mainGroupListRef.current.push(mainGroupRef.current);

    currentPolygonRef.current.addEventListener('tap', (evt) => {
      console.log(
        'ujj geofence tapped resizable polygon is: ' + evt.target.getData()
      );

      mainGroupListRef.current.forEach((maingroup) => {
        if (maingroup.getData() === evt.target.getData()) {
          console.log('ujj maingroup: ' + maingroup.getData());
          mainGroupRef.current = maingroup;
        }
      });

      //if not marking vehicle / if not any polygon selected then select polygon and markvehicle true
      isMarkVehicleRef.current = true;
      currentPolygonIdRef.current = evt.target.getData();

      handleShowGeoFenceSettings();
    });

    //todo ujj listener not working on all polygons.
    // let polygonTimeout;

    // // event listener for main group to show markers if moved in with mouse (or touched on touch devices)
    // mainGroupRef.current.addEventListener(
    //   'pointerenter',
    //   function (evt) {
    //     if (polygonTimeout) {
    //       clearTimeout(polygonTimeout);
    //       polygonTimeout = null;
    //     }

    //     // show vertice markers
    //     verticeGroupRef.current.setVisibility(true);
    //   },
    //   true
    // );

    // // event listener for main group to hide vertice markers if moved out with mouse (or released finger on touch devices)
    // // the vertice markers are hidden on touch devices after specific timeout
    // mainGroupRef.current.addEventListener(
    //   'pointerleave',
    //   function (evt) {
    //     var timeout = evt.currentPointer.type === 'touch' ? 1000 : 0;

    //     // hide vertice markers
    //     polygonTimeout = setTimeout(function () {
    //       verticeGroupRef.current.setVisibility(false);
    //     }, timeout);
    //   },
    //   true
    // );

    // // event listener for vertice markers group to change the cursor to pointer
    // verticeGroupRef.current.addEventListener(
    //   'pointerenter',
    //   function (evt) {
    //     document.body.style.cursor = 'pointer';
    //   },
    //   true
    // );

    // // event listener for vertice markers group to change the cursor to default
    // verticeGroupRef.current.addEventListener(
    //   'pointerleave',
    //   function (evt) {
    //     document.body.style.cursor = 'default';
    //   },
    //   true
    // );

    // // event listener for vertice markers group to resize the geo polygon object if dragging over markers
    // verticeGroupRef.current.addEventListener(
    //   'drag',
    //   function (evt) {
    //     var pointer = evt.currentPointer,
    //       geoLineString = currentPolygonRef.current
    //         .getGeometry()
    //         .getExterior(),
    //       geoPoint = map.screenToGeo(pointer.viewportX, pointer.viewportY);

    //     // set new position for vertice marker
    //     evt.target.setGeometry(geoPoint);

    //     // set new position for polygon's vertice
    //     geoLineString.removePoint(evt.target.getData()['verticeIndex']);
    //     geoLineString.insertPoint(
    //       evt.target.getData()['verticeIndex'],
    //       geoPoint
    //     );
    //     currentPolygonRef.current.setGeometry(
    //       new window.H.geo.Polygon(geoLineString)
    //     );

    //     // currentPolygonRef.current = polygon;

    //     // stop propagating the drag event, so the map doesn't move
    //     evt.stopPropagation();
    //   },
    //   true
    // );

    // // Event listener for dragend
    // verticeGroupRef.current.addEventListener(
    //   'dragend',
    //   function (evt) {
    //     console.log('ujj marker Drag ended');
    //     markers.current.forEach((marker) => {
    //       checkMarkerOnPolygon(marker, currentPolygonRef.current);
    //     });
    //   },
    //   true
    // );
  };

  const checkMarkerOnPolygon = (marker, polygon) => {
    if (polygon) {
      let extPoly = polygon.getGeometry().getExterior();
      let seqPointsPoly = [];
      extPoly.eachLatLngAlt((lat, lng, alt, idy) => {
        seqPointsPoly.push({ y: lat, x: lng });
      });

      // Create a marker (you'll have your marker object)
      let p = { y: marker.getData().latitude, x: marker.getData().longitude };
      // Check if the point is inside the polygon
      const isInsidePolygon = isPointInPolygon(p, seqPointsPoly);

      if (isInsidePolygon) {
        console.log(
          'ujj marker ' +
            marker.getData().vehicleId +
            ' is inside polygon ' +
            polygon.data
        );

        //removing out vehicles if its on the list. note vehicleId should be in uppercase
        vehicleListGeoFenceWarnRef.current =
          vehicleListGeoFenceWarnRef.current.filter(
            (id) => id !== marker.getData().vehicleId.toUpperCase()
          );
        console.log(
          'ujj removing vehicle: ' + marker.getData().vehicleId.toUpperCase()
        );
        const updatedList = vehicleListGeoFenceWarnRef.current;
        setVehicleListGeoFenceWarn(updatedList);
        setVehicleListGeoFenceWarn(vehicleListGeoFenceWarnRef.current);
        // playTTSMessage(
        //   marker.getData().vehicleId + ' vehicle is inside geo fence'
        // );
      } else {
        console.log(
          'ujj marker ' +
            marker.getData().vehicleId +
            ' is outside the polygon ' +
            polygon.data
        );

        //check if doesnot exists then add. note vehicle id should be in uppercase
        if (
          !vehicleListGeoFenceWarnRef.current.includes(
            marker.getData().vehicleId.toUpperCase()
          )
        ) {
          console.log(
            'ujj outside polygon adding vehicle: ' +
              marker.getData().vehicleId.toUpperCase()
          );
          vehicleListGeoFenceWarnRef.current.push(
            marker.getData().vehicleId.toUpperCase()
          );

          const updatedList = vehicleListGeoFenceWarnRef.current;
          setVehicleListGeoFenceWarn(updatedList);
        }

        // playTTSMessage(
        //   'Warning!' +
        //     marker.getData().vehicleId +
        //     ' went outside the geo fence'
        // );
      }
    }
  };

  function isPointInPolygon(testPoint, polygPoints) {
    let result = false;
    let j = polygPoints.length - 1;
    for (let i = 0, len = j + 1; i < len; i++) {
      let p = polygPoints[i];
      let lP = polygPoints[j];
      if (
        (p.y < testPoint.y && lP.y >= testPoint.y) ||
        (lP.y < testPoint.y && p.y >= testPoint.y)
      ) {
        if (
          p.x + ((testPoint.y - p.y) / (lP.y - p.y)) * (lP.x - p.x) <
          testPoint.x
        ) {
          result = !result;
        }
      }
      j = i;
    }
    return result;
  }

  // geo fence post selected vehicle to server
  const postFleetPlanVehicleRegosToServer = async (selectedVehicleId) => {
    console.log('ujj geofence adding to server: ' + selectedVehicleId);
    try {
      if (currentPolygonIdRef.current !== '' && selectedVehicleId !== '') {
        const server = process.env.REACT_APP_API_SERVER;
        const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;

        const credentials = btoa(`${username}:${password}`);
        //todo fetch organisation id
        const response = await fetch(
          `${server}/geofence/${orgId}/${selectedVehicleId}/${currentPolygonIdRef.current}`,
          {
            method: 'POST',
            headers: {
              Authorization: `Basic ${credentials}`,
            },
          }
        );

        if (response.ok) {
          console.log(
            `ujj ${selectedVehicleId} geofence added to ${currentPolygonIdRef.current}`
          );
          showCustomPrompt();
        } else {
          //handle error response
          console.log(
            'ujj add Error in saving Geofence Data: ',
            response.statusText
          );
        }
      }
    } catch (error) {
      //Handle network errors or other exceptions
      console.error(
        'ujj add Error saving geofence vehicle rego data:',
        error.message
      );
    }
  };

  const playTTSMessage = (msg) => {
    if ('speechSynthesis' in window) {
      const speech = new SpeechSynthesisUtterance();

      speech.text = msg;
      // speech.voice = speechSynthesis.getVoices().filter(function (voice) {
      //   return voice.name == 'Google UK English Female';
      // })[0];
      speech.rate = 1.5;

      window.speechSynthesis.speak(speech);
    } else {
      console.log('Text-to-speech not supported in this browser.');
    }
  };

  const handleSearchAddress = (e) => {
    console.log('ujj keycode: ' + e.keyCode);
    if (e.key === 'Enter' || e.keyCode === 13) {
      e.preventDefault(); // Prevent the default Enter key behavior (like form submission)

      setIsShowAutoCompleteResults(true);
      const query = e.target.value;

      if (searchAddressControlRef.current) {
        // searchAddressControlRef.current.geocode(query);

        searchAddressControlRef.current.options.geocoder.geocode(
          query,
          (results) => {
            if (results.length > 0) {
              //   const { lat, lng } = results[0].center;
              //   const newCenter = { lat, lng };
              //   const hereMap = mapRef.current;
              //   hereMap.setCenter(newCenter);
              setAutoCompleteResults(results);
            }
          }
        );
      }
    }
  };

  const handleSelectAddress = (result) => {
    if (mapRef.current) {
      const { center } = result || {};

      if (center) {
        const { lat, lng } = center;
        console.log('ujj center: ' + lat + ',' + lng);

        if (lat && lng) {
          // .setView([lat, lng], 13);
          // mapRef.current.setCenter({ lat, lng });

          let minLat = lat - 0.005;
          let maxLat = lat + 0.005;
          let minLng = lng - 0.005;
          let maxLng = lng + 0.005;

          // Create a bounding box
          const boundingBox = new window.H.geo.Rect(
            minLat,
            minLng,
            maxLat,
            maxLng
          );

          mapRef.current.getViewModel().setLookAtData(
            {
              bounds: boundingBox,
            },
            {
              duration: 1000,
              ease: window.H.util.animation.easeInQuad,
            }
          );
        }
      }
    }
  };

  const handleSearchAddressClear = () => {
    setIsShowAutoCompleteResults(false);
    const inputField = document.getElementById(
      'livemap-id-input-search-address'
    );

    inputField.value = '';
  };

  const handleStreetView = (latitude, longitude) => {
    if (latitude !== 0 && longitude !== 0) {
      const googleStreetViewUrl = `https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${latitude},${longitude}`;
      window.open(googleStreetViewUrl, '_blank');
    }
  };

  const handleCustomPromptDone = () => {
    isMarkVehicleRef.current = false;
    hideCustomPrompt();
  };

  const showCustomPrompt = () => {
    setIsShowCustomPrompt(true);
    setTimeout(() => {
      setCustomPromptAnimation('visible');
    }, 300); // Ensure this matches the transition duration
  };

  const hideCustomPrompt = () => {
    setCustomPromptAnimation('');
    setTimeout(() => {
      setIsShowCustomPrompt(false);
    }, 300); // Ensure this matches the transition duration
  };

  const handleCustomPromptClose = () => {
    hideCustomPrompt();
  };

  const handleTest = () => {
    // mapMatchCoordinate();
    // reverseGeocode({
    //   latitude: -33.86104882716654,
    //   longitude: 150.92966067920202,
    // });
    reverseGeocode({
      latitude: -33.860957627603575,
      longitude: 150.93023133590617,
    });

    // calculateRoutePlanner();
  };

  const calculateRoutePlanner = () => {
    // Map initialization code
    const platform = new window.H.service.Platform({
      apikey: process.env.REACT_APP_API_KEY,
    });

    const router = platform.getRoutingService(null, 8);
    const routeRequestParams = {
      routingMode: 'fast',
      transportMode: 'truck',
      // origin: '-33.83867007966921,150.87170655224827',
      origin: '-33.840044948984776,150.88220790611624',
      destination: '-33.83520199659885,150.85075765591958',
      return:
        'polyline,turnByTurnActions,actions,instructions,summary,travelSummary,routeHandle,passthrough,routingZones,truckRoadTypes',
      spans: 'truckAttributes',
    };

    router.calculateRoute(
      Object.assign(routeRequestParams, {
        'truck[axleCount]': 2,
      }),
      onSuccess,
      onError
    );
  };

  function onSuccess(result) {
    const route = result.routes[0];
    console.log('route: ', route);
    addRouteShapeToMap(route);
    addManueversToMap(route);
    addWaypointsToPanel(route);
    addManueversToPanel(route);
    addSummaryToPanel(route);
  }

  const addRouteShapeToMap = (route) => {
    route.sections.forEach((section) => {
      const encodedPolyline = section.polyline;
      let linestring =
        window.H.geo.LineString.fromFlexiblePolyline(encodedPolyline);

      let polyline = new window.H.map.Polyline(linestring, {
        style: {
          lineWidth: 4,
          strokeColor: 'rgba(127, 0, 255, 0.7)',
        },
      });

      extractRoadCoordinates(linestring);

      routesGroupRef.current.removeAll();

      routesGroupRef.current.addObject(polyline);
      mapRef.current.getViewModel().setLookAtData(
        {
          bounds: routesGroupRef.current.getBoundingBox(),
        },
        {
          duration: 1000,
          ease: window.H.util.animation.easeInQuad,
        }
      );
    });
  };

  const extractRoadCoordinates = (linestring) => {
    const coordinateRouteArray = linestring.getLatLngAltArray();
    criticalRouteTurnAngleCoordinates += `/${coordinateRouteArray[0]},${coordinateRouteArray[1]}`;

    startPointUrlString += `/@${coordinateRouteArray[0]},${coordinateRouteArray[1]},18z?entry=ttu`;
    calculateTurnAngle(coordinateRouteArray);

    openGoogleMapsForPolyline();
  };

  let criticalRouteTurnAngleCoordinates = [];
  let startPointUrlString = '';

  const addCriticalRouteTurnAngleCoordinates = (lat, lng) => {
    criticalRouteTurnAngleCoordinates += `/'${lat},${lng}'`;
  };

  const openGoogleMapsForPolyline = () => {
    // Construct Google Maps URL with coordinates

    const googleMapsURL = `https://www.google.com/maps/dir${criticalRouteTurnAngleCoordinates}${startPointUrlString}`;

    // Open the URL in a new tab
    window.open(googleMapsURL, '_blank');
  };

  const calculateBearing = (lat1, lon1, lat2, lon2) => {
    const dLon = lon2 - lon1;
    const y = Math.sin(dLon) * Math.cos(lat2);
    const x =
      Math.cos(lat1) * Math.sin(lat2) -
      Math.sin(lat1) * Math.cos(lat2) * Math.cos(dLon);
    let brng = Math.atan2(y, x);
    brng = brng * (180 / Math.PI);
    brng = (brng + 360) % 360;
    return brng;
  };

  const calculateTurnAngle = (coordinates) => {
    const turnAngles = [];
    for (let i = 0; i < coordinates.length - 6; i += 3) {
      const [lat1, lon1] = coordinates.slice(i, i + 2);
      const [lat2, lon2] = coordinates.slice(i + 3, i + 5);
      const [lat3, lon3] = coordinates.slice(i + 6, i + 8);

      const bearing1 = calculateBearing(lat1, lon1, lat2, lon2);
      const bearing2 = calculateBearing(lat1, lon1, lat3, lon3);
      let turnAngle = bearing2 - bearing1;
      if (turnAngle < -180) turnAngle += 360;
      if (turnAngle > 180) turnAngle -= 360;

      console.log(
        'ujj angle linestring: ' + turnAngle + ' for: ' + lat1 + ',' + lon1
      );

      if (turnAngle > 15 || turnAngle < -15) {
        // criticalRouteTurnAngleCoordinates += `/${lat1},${lon1}`;
        console.log('ujj points are: ' + lat1 + ',' + lon1);
        //take middle one
        addCriticalRouteTurnAngleCoordinates(lat2, lon2);
      }

      if (i + 3 + 6 === coordinates.length) {
        addCriticalRouteTurnAngleCoordinates(lat3, lon3);
      }
      turnAngles.push(turnAngle);
    }
    return turnAngles;
  };

  function addManueversToMap(route) {
    // Implementation of addManueversToMap function...
  }

  function addWaypointsToPanel(route) {
    // Implementation of addWaypointsToPanel function...
  }

  function addSummaryToPanel(route) {
    // Implementation of addSummaryToPanel function...
  }

  function addManueversToPanel(route) {
    // Implementation of addManueversToPanel function...
  }

  function onError(error) {
    alert("Can't reach the remote server");
  }

  // const matchRoute = async () => {
  //   const coordinates = [
  //     [150.8940788620672, -33.84005529816218],
  //     // Add more coordinates as needed
  //   ];

  //   const requestBody = {
  //     coordinates,
  //     timestamps: [], // Optionally, you can provide timestamps for each coordinate
  //   };

  //   try {
  //     const response = await fetch(
  //       'http://router.project-osrm.org/match/v1/driving/',
  //       {
  //         method: 'POST',
  //         headers: {
  //           'Content-Type': 'application/json',
  //         },
  //         body: JSON.stringify(requestBody),
  //       }
  //     );

  //     if (!response.ok) {
  //       throw new Error('Network response was not ok');
  //     }

  //     const data = await response.json();

  //     if (data.code === 'Ok' && data.matchings && data.matchings.length > 0) {
  //       // Extract the matched route geometry
  //       const matchedGeometry = data.matchings[0].geometry;
  //       console.log('ujj matched route:' + matchedGeometry);
  //     } else {
  //       console.log('No matching route found.');
  //     }
  //   } catch (error) {
  //     console.error('Error:', error);
  //   }
  // };

  const mapMatchCoordinate = async () => {
    // const latitude = -33.83994595214284;
    // const longitude = 150.89393759485807;
    // const latitude = -33.84000159618125;
    // const longitude = 150.89389686132586;
    const latitude1 = -33.86104882716654;
    const longitude1 = 150.92966067920202;
    const latitude = -33.86263717811286;
    const longitude = 150.9197876113682;
    const osrmUrl = `http://router.project-osrm.org/route/v1/driving/${longitude},${latitude};${longitude},${latitude}`;

    try {
      const response = await fetch(osrmUrl);
      const data = await response.json();

      if (data.code === 'Ok' && data.routes && data.routes.length > 0) {
        const startLocation = data.waypoints[0].location;
        const lat = startLocation[1];
        const lng = startLocation[0];

        // Simulating OpenCage Geocoding API response
        const dummyAddress = `coord: ${lat}, ${lng}`;

        console.log(dummyAddress);

        reverseGeocode({ latitude: lat, longitude: lng });
      } else {
        console.log('Address not found');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const reverseGeocode = async ({ latitude, longitude }) => {
    const api_key = 'e70509af59064642a6ec8a40f3c42f63';
    const api_url = `https://api.opencagedata.com/geocode/v1/json?`;

    const request_url = `${api_url}q=${latitude},${longitude}&key=${api_key}&language=en&pretty=1`;

    try {
      const response = await fetch(request_url);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();

      console.log('ujj address: ' + JSON.stringify(data));

      if (data.results && data.results.length > 0) {
        const result0 = data.results[0];
        const formattedAddress = result0.formatted;
        const geometry = result0.geometry;
        console.log('ujj address' + formattedAddress);
        console.log('ujj address' + geometry.lat + ',' + geometry.lng);
      } else {
        console.log('Address not found');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className='map-container'>
      {/* Map */}
      <div id='map' className='map'></div>

      {/* markerbox */}
      {selectedMarker && (
        <MarkerBox
          markerData={selectedMarker.markerData}
          onClose={closeMarkerDetailsBox}
          markerPosition={selectedMarker.position}
          updatedMarkerData={updatedVehicleData}
        />
      )}

      {/* trucktronix */}
      <img
        src={icon_trucktronix}
        alt='trucktronix icon'
        className='trucktronix-logo'
      />

      <div
        className='button-div-btn-vehicle-list'
        onClick={onVehicleIconClick}
        style={{ top: '10px', right: '20px' }}
      >
        <div className='button-div-btn-outer-rim'>
          <div className='button-div-btn-inner-rim'>
            <i className='bi bi-chevron-double-left'></i>
          </div>
        </div>
      </div>

      {isShowGeoFenceSettings && (
        <div className={`geoFenceModal ${geoFenceModalAnimation}`}>
          <GeoFenceSettings
            setQuickGeoFence={setQuickGeoFenceOnMap}
            fleetPlanOnMapDone={handleDoneFleetPlan}
            showGeoFenceList={handleShowGeoFenceList}
            showAdvancedControl={handleShowAdvancedGeoFence}
            currentPolygonIdRef={currentPolygonIdRef}
            currentPolygonCoordinatesRef={currentPolygonCoordinatesRef}
            deleteGeoFence={handleGeoFenceDeleteClick}
          />
        </div>
      )}

      {isShowAdvancedGeoFenceSettings && (
        <div
          className={`geoFenceAdvancedModal ${geoFenceModalAdvancedAnimation}`}
        >
          <GeoFenceAdvancedSettings
            setAdvancedGeoFence={setAdvancedGeoFenceOnMap}
            setMarkVehicle={() => {
              isMarkVehicleRef.current = true;
            }}
            doneAdvancedfleetPlan={handleDoneAdvancedFleetPlan}
            createAdvancedFleetPlan={handleCreateAdvancedFleetPlan}
            triggerAdvancedGeoFenceCancel={handleAdvancedGeoFenceCancel}
            closeAdvancedGeofence={handleCloseAdvancedGeoFence}
            currentPolygonCoordinatesRef={currentPolygonCoordinatesRef}
          />
        </div>
      )}

      {isShowGeoFenceList && (
        <div className={`geoFenceListModal ${geoFenceListAnimation}`}>
          <GeofenceListModal
            // isOpen={isGeofenceOpen}
            listVehiclePolygonKeyValue={listVehiclePolygonKeyValue}
            onClose={handleHideGeoFenceList}
            recenterMapToGeoFence={handleRecenterMapToGeoFence}
            geoFenceDeleteFromList={handleGeoFenceDeleteFromList}
            removeVehiclePolygonPair={handleRemoveVehiclePolygonPair}
          />
        </div>
      )}

      {/* for search address */}
      <div ref={geocoderMapRef} style={{ display: 'none' }} />

      <div className='livemap-div-search-address'>
        <input
          id='livemap-id-input-search-address'
          className='livemap-input-search-address'
          type='text'
          placeholder='Search address...'
          onKeyDown={handleSearchAddress}
          onChange={(e) => {
            setIsShowAutoCompleteResults(false);
          }}
        />
        <i
          className='bi bi-x-circle-fill address-cancel'
          onClick={() => {
            handleSearchAddressClear();
          }}
        ></i>
      </div>

      {isShowAutoCompleteResults && (
        <div className='livemap-input-search-address-results'>
          {autocompleteResults.map((result, index) => (
            <div key={index} onClick={() => handleSelectAddress(result)}>
              <div>
                <i className='bi bi-geo-alt-fill'></i>

                <span>{result.name}</span>
              </div>
              <div className='blue-line'></div>
            </div>
          ))}
        </div>
      )}

      {/* bottom floating menus */}
      <FloatingMenus
        onVehicleIconClick={onVehicleIconClick}
        onGeoFenceIconClick={handleGeoFenceIconClick}
        onAccountIconClick={onAccountIconClick}
        onFleetTableIconClick={onFleetTableIconClick}
        onHistoryIconClick={onHistoryIconClick}
        onFleetManagerIconClick={onFleetManagerIconClick}
        onHelpIconClick={onHelpIconClick}
      />

      {/* Account Modal */}
      <AccountModal
        isAccountModalOpen={isAccountModalOpen}
        closeAccountModal={() => setAccountModalOpen(false)}
      />

      {/* vehicle list card */}
      <VehicleListCard
        showVehicleList={showVehicleList}
        recentLocations={recentLocations}
        vehicleListGeoFenceWarn={vehicleListGeoFenceWarn}
        onClose={() => {
          setShowVehicleList(false);
        }}
        onRecenterMap={recenterMap}
        onVehicleDataUpdateMap={updateMarkerBoxData}
        onVehicleClickMap={showMarkerBoxOnMap}
        streetViewVehiclePass={handleStreetView}
        showVehicleSettings={() => {
          setIsShowVehicleSettings((prev) => !prev);
          handleTest();
        }}
        onSelectedVehicleId={(vehicleId) => {
          selectedTrackVehicleIdRef.current = vehicleId;
        }}
      />

      {/* <VehicleTrackSettings showVehicleTrackSettings={isShowVehicleSettings} /> */}

      {isShowCustomPrompt && (
        <div className={`modelCustomPrompt ${customPromptAnimation}`}>
          <CustomPrompt
            message={'Select more vehicle?'}
            onClose={handleCustomPromptClose}
            onDone={handleCustomPromptDone}
          />
        </div>
      )}
    </div>
  );
};

export default LiveMap;
