import React, { useEffect, useState } from 'react';
import './history.css';
import { toast } from 'react-toastify';
import HistoryRegoTable from './HistoryRegoTable';
import LoadingSpinner from '../../component/LoadingSpinner';
import logoImage from '../images/trucktronix icon.png';

const History = () => {
  const [regos, setRegos] = useState([]);
  const [selectedRego, setSelectedRego] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [activeRego, setActiveRego] = useState('');
  const [regoContainerVisible, setRegoContainerVisible] = useState(true);
  const [loading, setLoading] = useState(true);

  const organizationId = sessionStorage.getItem('org_id');

  useEffect(() => {
    const fetchDataRego = async () => {
      try {
        const server = process.env.REACT_APP_API_SERVER;
        const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;
        const credentials = btoa(`${username}:${password}`);
        const response = await fetch(`${server}/rego/${organizationId}`, {
          method: 'GET',
          headers: {
            Authorization: `Basic ${credentials}`,
          },
        });

        console.log('ujj log is: ' + response);

        if (response.ok) {
          const data = await response.json();
          setRegos(data);
        } else {
          toast.error(
            `An error occurred while fetching employee data: ${response.status}`
          );
        }
      } catch (error) {
        console.log('ujj error log is: ' + error);
        toast.error(`Error: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };
    fetchDataRego();
  }, []);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const toggleRegoContainer = () => {
    setRegoContainerVisible(!regoContainerVisible);
  };

  //Filter regos based on the search term
  const filteredRegos = regos.filter((rego) =>
    rego.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className='history-div-main-container'>
      {/* header */}
      <div className='history-div-header'>
        {/* button to show or hide rego list */}
        <div className='div-btn-toggle'>
          <div className='div-btn-toggle-outer-rim'>
            <div className='div-btn-toggle-inner-rim'>
              <div
                className={`history-div-btn-rego-list-toggle ${
                  regoContainerVisible ? 'active' : ''
                }`}
                onClick={toggleRegoContainer}
              >
                <span></span>
                {/* <span className='history-span-bar-hide'></span> */}
              </div>
            </div>
          </div>
        </div>

        <img src={logoImage} alt='Trucktronix' className='logo-image' />
        <h4>History</h4>
      </div>
      {/*history container body */}
      <div className='history-div-container-body'>
        {/* rego list */}
        <div
          className={`history-div-container-rego ${
            regoContainerVisible ? '' : 'collapsed'
          }`}
        >
          <div className='div-list-rego-outer-rim'>
            <div className='div-list-rego-inner-rim'>
              <span className='history-span-list-rego-title'>Rego List </span>
              <div className='white-line'></div>

              <input
                type='text'
                className='history-input-search-rego'
                placeholder='Search Rego...'
                value={searchTerm}
                onChange={handleSearch}
              />
              {loading ? (
                <LoadingSpinner />
              ) : (
                <ul>
                  {Array.isArray(filteredRegos) &&
                    filteredRegos.map((rego, index) => (
                      <li
                        key={index}
                        onClick={() => {
                          setSelectedRego({ rego });
                          setActiveRego(rego);
                        }}
                      >
                        <div
                          className={`div-rego-outer-rim ${
                            activeRego === rego ? 'active' : ''
                          }`}
                        >
                          <div
                            className={`div-rego-inner-rim ${
                              activeRego === rego ? 'active' : ''
                            }`}
                          >
                            {rego}
                          </div>
                        </div>
                      </li>
                    ))}
                </ul>
              )}
            </div>
          </div>
        </div>
        {/* table and controls */}
        <HistoryRegoTable rego={selectedRego} />
      </div>
    </div>
  );
};

export default History;
