// AddEmployeeModal.js
import React, { useEffect, useRef, useState } from 'react';
import './AddEmployeeModal.css';
import { toast } from 'react-toastify';
import { storage } from '../../Firebase';
import {
  getDownloadURL,
  ref as storageRef,
  uploadBytesResumable,
} from 'firebase/storage';
import { FaCloudUploadAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import uploadImg from '../../assets/upload-img.png';

const AddEmployeeModal = ({ onClose, onEmployeeAdded }) => {
  const organizationId = sessionStorage.getItem('org_id');
  const [image, setImage] = useState(null);
  const [updating, setUpdating] = useState(false);
  const [vehicleOptions, setVehicleOptions] = useState([]);
  const [newEmployee, setNewEmployee] = useState({
    name: '',
    rego: '',
    email: '',
    phone: '',
    organizationId: organizationId,
    passcode: '',
    imageUrl: '',
  });

  // Define custom styles for the Select component
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: '1px solid #ccc',
      borderRadius: '4px',
      boxShadow: state.isFocused ? '0 0 0 1px #3498db' : 'none',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#3498db' : '#fff',
      color: state.isSelected ? '#fff' : '#333',
      cursor: 'pointer',
    }),
  };

  useEffect(() => {
    //Fetch the list of vehicles and populate the dropdown options
    const fetchVehicles = async () => {
      try {
        const server = process.env.REACT_APP_API_SERVER;
        // const server = 'http://localhost:4567';

        const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;
        const credentials = btoa(`${username}:${password}`);

        const response = await fetch(`${server}/vehicles/${organizationId}`, {
          method: 'GET',
          headers: {
            Authorization: `Basic ${credentials}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          // console.log("vehicle data ", data);
          const options = data.map((vehicle) => ({
            value: vehicle.vehicleId,
            label: vehicle.vehicleId,
          }));
          setVehicleOptions(options);
        } else {
          const errorMessage = await response.text();
          toast.error(`Failed to fetch vehicles: ${errorMessage}`);
        }
      } catch (error) {
        toast.error(error.message);
      }
    };
    fetchVehicles();
  }, [organizationId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewEmployee({ ...newEmployee, [name]: value });
  };

  // Create a ref for the hidden file input
  const fileInputRef = useRef(null);

  const handleImageClick = () => {
    // Trigger the hidden file input when the image is clicked
    fileInputRef.current.click();
  };

  /**
   * Set chosen image to a variable
   * @param {*} e
   */
  const handleImgChoose = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };
  /**
   *
   */
  const handleAddEmployee = async () => {
    if (image) {
      const imageRef = storageRef(
        storage,
        `employees/${organizationId}/${newEmployee.rego}/` + image.name
      );
      //upload image
      await uploadBytesResumable(imageRef, image);

      //get download url
      const imageUrl = await getDownloadURL(imageRef);
      newEmployee.imageUrl = imageUrl;
    }

    if (
      newEmployee.name &&
      // newEmployee.rego &&
      newEmployee.email &&
      newEmployee.phone &&
      organizationId &&
      newEmployee.passcode
    ) {
      try {
        setUpdating(true);
        // const server = 'http://localhost:4567';
        const server = process.env.REACT_APP_API_SERVER;
        const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;
        const credentials = btoa(`${username}:${password}`);

        const response = await fetch(server + '/employees', {
          method: 'POST',
          body: JSON.stringify({
            name: newEmployee.name,
            rego: newEmployee.rego || '',
            email: newEmployee.email || '',
            phone: newEmployee.phone || '',
            organizationId: organizationId,
            passcode: newEmployee.passcode || '',
            imageUrl: newEmployee.imageUrl || '',
          }),

          headers: {
            Authorization: `Basic ${credentials}`,
            'Content-Type': 'application/json',
          },
        });
        if (response.ok) {
          toast.success('Employee added successfully!');
          onClose();
          onEmployeeAdded();
        } else {
          //Error occurred while adding new employee
          if (response.status === 400) {
            toast.error('Invalid employee data');
          } else if (response.status === 409) {
            toast.error(
              'Employee already exist with the provided email. '
            );
          } else {
            const errorMessage = await response.text();
            toast.error(`Failed to add employee: ${errorMessage}`);
          }
        }
      } catch (error) {
        toast.error(error.message);
      } finally {
        setUpdating(false);
      }
    } else {
      toast.error('Required parameters are missing!');
    }
  };

  return (
    <div className='employee-add-modal'>
      <div className='eam-header-container'>
        <h3>Add Employee</h3>
        <div className='eam-img-container'>
          <input
            type='file'
            id='file'
            onChange={handleImgChoose}
            ref={fileInputRef}
            style={{ display: 'none' }}
          />
          {/* <div className="eam-uploadContainer">

                        <input
                            type='file'
                            id='file'
                            onChange={handleImgChoose}
                            style={{ display: 'none' }}
                        />
                        <label htmlFor="file"
                            className="eam-img-label"
                        >
                            Image:
                            <FaCloudUploadAlt className='icon' />
                        </label>
                    </div> */}
          <div className='eam-uploadContainer' onClick={handleImageClick}>
            <img
              className='eam-img-Emp'
              src={
                image ? URL.createObjectURL(image) : uploadImg
                // 'https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg'
              }
              alt=''
            />
          </div>
        </div>
      </div>
      <div className='eam-input-container'>
        <label>Rego:</label>
        <Select
          options={vehicleOptions}
          value={{ value: newEmployee.rego, label: newEmployee.rego }}
          onChange={(selectedOption) =>
            setNewEmployee({ ...newEmployee, rego: selectedOption.value })
          }
          styles={customStyles}
          className='react-select-container' // Remove unnecessary classnames
        />
      </div>

      <div className='eam-input-container'>
        <label>Name:</label>
        <input
          type='text'
          name='name'
          required
          value={newEmployee.name}
          onChange={handleChange}
        />
      </div>

      <div className='eam-input-container'>
        <label>Email:</label>
        <input
          type='text'
          name='email'
          required
          value={newEmployee.email}
          onChange={handleChange}
        />
      </div>

      <div className='eam-input-container'>
        <label>Phone:</label>
        <input
          type='text'
          required
          name='phone'
          value={newEmployee.phone}
          onChange={handleChange}
        />
      </div>
      <div className='eam-input-container'>
        <label>Passcode:</label>
        <input
          type='text'
          name='passcode'
          required
          value={newEmployee.passcode}
          onChange={handleChange}
        />
      </div>
      <div className='eam-btnContainer'>
        <div className='aem-div-btn add' onClick={handleAddEmployee}>
          <div className='aem-outer-rim'>
            <div className='aem-inner-rim'>
              {updating ? 'Adding...' : 'Add Employee'}
            </div>
          </div>
        </div>

        <div className='aem-div-btn cancel' onClick={onClose}>
          <div className='aem-outer-rim'>
            <div className='aem-inner-rim'>Cancel</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddEmployeeModal;
