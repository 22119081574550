import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
// import ThreeDModel from '../../component/login/ThreeDImage';
import './Login.css';
import logoFM from '../images/logo_fleet_master.png';
import vdo from '../../assets/video.mp4';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [orgId, setOrgId] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const server = process.env.REACT_APP_API_SERVER;
      // const server = "http://localhost:4567"
      const response = await fetch(`${server}/loginOrg`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: email,
          pwd: password,
          organizationId: orgId,
        }),
      });
      // The fetch API considers responses with status codes in the range 200-299 as successful.
      if (response.ok) {
        sessionStorage.setItem('user_email', email);
        sessionStorage.setItem('org_id', orgId);
        navigate('/');
      } else {
        // Login failed, display an error message.
        if (response.status === 401) {
          toast.error('Username and password do not match.');
        } else if (response.status === 404) {
          toast.warning('User not found.');
        } else {
          console.error(
            `An error occurred during authentication: ${response.message}`
          );
          toast.error('An error occurred during authentication.');
        }
      }
    } catch (error) {
      console.error('Unexpected error:', error);
      toast.error(`An unexpected error occurred: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='app-container'>
      <div className='video-container'>
        {/* Video tag to display the video */}
        <video autoPlay muted loop className='background-video'>
          <source src={vdo} type='video/mp4' />
          Your browser does not support the video tag.
        </video>
      </div>
      {/* <ThreeDModel /> */}
      <div className='login-form-container'>
        <form className='login-form' onSubmit={handleLoginSubmit}>
          <img src={logoFM} alt='Logo' className='login-logo-image' />
          <h1 className='form__title'>Fleet Master Client</h1>
          <div className='form__message form__message--error'></div>
          <div className='form__input-group'>
            <input
              type='email'
              className='form__input'
              autoFocus
              placeholder='Email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <div className='form__input-error-message'></div>
          </div>

          <div className='form__input-group'>
            <input
              type='password'
              className='form__input'
              placeholder='Password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <div className='form__input-error-message'></div>
          </div>
          <div className='form__input-group'>
            <input
              type='text'
              className='form__input'
              autoFocus
              placeholder='Organization Id'
              value={orgId}
              onChange={(e) => setOrgId(e.target.value)}
              required
            />
            <div className='form__input-error-message'></div>
          </div>
          <button className='form__button' type='submit' disabled={loading}>
            {loading ? 'Loading...' : 'Continue'}
          </button>
          <p className='form__text'>
            <a href='/reset' className='form__link' id='linkResetAccount'>
              Forgot your Password?
            </a>
          </p>
        </form>
      </div>
    </div>
  );
};

export default Login;
