import React, { useEffect, useRef, useState } from 'react';
import { MapContainer, TileLayer, Marker, Polyline } from 'react-leaflet';
import { useLocation } from 'react-router-dom';

import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import './PlayHistory.css';
import './leaflet.motion.min.js';
import vehicleLogo from '../../assets/logo_vehicle.png';
import vehicleCar from '../../assets/icon_truck.png';
import iconCompass from '../../assets/icon_compass.png';
import iconSpeedometerBody from '../../assets/icon_speedometer_body.png';
import iconSpeedometerPin from '../../assets/icon_speedometer_pin.png';
import icon_trucktronix from '../images/icon_trucktronix.png';
import { effect, signal, untracked } from '@preact/signals-react';
import Chart from 'chart.js/auto';

//for chart
import { Bar } from 'react-chartjs-2';
import {
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
} from 'chart.js';
import moment from 'moment-timezone';

Chart.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

//for route details
const signalSteerWeight = signal(0);
const signalDriveWeight = signal(0);
const signalTrailerAWeight = signal(0);
const signalTrailerBWeight = signal(0);
const signalTotalWeight = signal(0);
const signalScaleUnit = signal('kg');
const signalStatus = signal('offline');
const signalTimestamp = signal('');
const signalAddress = signal('');
const signalSpeed = signal(0);
const signalRotationAngleSpeedometer = signal(0);

//for analysis
const signalTotalOnlineCount = signal(0);
const signalTotalPausedCount = signal(0);
const signalTotalOfflineCount = signal(0);
const diffTimeStamp = signal(0);
const fnTotalOnlineCount = () =>
  signalTotalOnlineCount.peek() + diffTimeStamp.value;
const fnTotalPausedCount = () =>
  signalTotalPausedCount.peek() + diffTimeStamp.value;
const fnTotalOfflineCount = () =>
  signalTotalOfflineCount.peek() + diffTimeStamp.value;

const signalSteerMaxWeight = signal(0);
const signalDriveMaxWeight = signal(0);
const signalTrailerAMaxWeight = signal(0);
const signalTrailerBMaxWeight = signal(0);
const signalOverloadWeightUnit = signal('kg');
const signalSteerOverloads = signal(0);
const signalDriveOverloads = signal(0);
const signalTrailerAOverloads = signal(0);
const signalTrailerBOverloads = signal(0);
const fnTotalSteerOverload = () => signalSteerOverloads.peek() + 1;
const fnTotalDriveOverload = () => signalDriveOverloads.peek() + 1;
const fnTotalTrailerAOverload = () => signalTrailerAOverloads.peek() + 1;
const fnTotalTrailerBOverload = () => signalTrailerBOverloads.peek() + 1;

// const signalVehicleImageUrl = signal();
const signalVehicleImageUrl = null;
const motionStateSignal = signal(null);
const signalMotionDurationSpeed = signal(1);

let count = 0;

//for street view
const streetViewLatSignal = signal(0);
const streetViewLngSignal = signal(0);

// Maincomponent
const PlayHistory = () => {
  const location = useLocation();

  const {
    startDate,
    endDate,
    rego,
    organizationId,
    fleetData,
    routeArray,
    routeArrayLatLng,
  } = location.state || {};

  console.log(
    'ujj snake Component mounted with props:',
    startDate,
    endDate,
    rego,
    organizationId,
    fleetData
  );

  const mapRefTrack = useRef();
  const mapRefRoute = useRef();
  const polylineRef = useRef();
  const polylineRouteRef = useRef();
  const motionDurationRef = useRef(2000);
  const chartComponentRef = useRef(null);

  const isMotionReset = useRef(false);

  //for track map animation listener
  let bearing = 0;
  let tempTimestampCheck;
  let tempForwardFleetDataOriginal = [...fleetData];

  // Get the rotation angle of the icon animating truck icon
  const getRotationAngle = () => {
    const img = document.getElementById('marker-img');
    if (img) {
      const style = window.getComputedStyle(img);
      const transform = style.getPropertyValue('transform');
      if (transform && transform !== 'none') {
        // Parse the transform matrix to get the rotation angle
        const matrix = transform.split('(')[1].split(')')[0].split(',');
        const angle = Math.round(
          Math.atan2(matrix[1], matrix[0]) * (180 / Math.PI)
        );
        return angle;
      }
    }
    return 0; // Default angle if rotation is not found
  };

  //get speed angle for animation speedometer pin
  const setSpeedAnalog = (speed) => {
    signalRotationAngleSpeedometer.value = (speed * 225) / 200; //max is 200km/h, 225 is from 0 deg to 225 max deg
  };

  //get matching object for given lat and lng
  const findMatchingObject = (timestamp) => {
    const index = tempForwardFleetDataOriginal.findIndex(
      (obj) => obj.timestamp === timestamp
    );
    if (index !== -1) {
      const obj = tempForwardFleetDataOriginal[index];

      //also remove that object from tempForwardFleetDataOriginal list
      tempForwardFleetDataOriginal.splice(index, 1);

      return obj;
    }
    return null;
  };

  let prevLat, prevLng;
  const getVehicleDetailsFromTimestamp = (timestamp) => {
    //###
    //for compass
    // let bearing = calculateBearing(prevLat, prevLng, lat, lng);
    let bearing = getRotationAngle();

    const iconCompass = document.getElementById('imgCompassIcon');
    if (iconCompass) {
      iconCompass.style.transform = `rotate(${bearing}deg)`;
    }

    //###
    //for vehicle details at that point
    const vehicleDetails = findMatchingObject(timestamp);
    if (vehicleDetails != null) {
      if (
        tempTimestampCheck == null ||
        tempTimestampCheck !== vehicleDetails.timestamp
      ) {
        // count++;
        // console.log(
        //   'ujj snake count: ' + vehicleDetails.status + ' :' + count
        // );
        if (tempTimestampCheck != null) {
          let diff =
            Math.abs(
              calculateTimestampFromStringToSecond(vehicleDetails.timestamp)
            ) -
            Math.abs(calculateTimestampFromStringToSecond(tempTimestampCheck));

          console.log('ujj diff' + diff);
          if (diff > 0) {
            diffTimeStamp.value = diff;
          }
        }
        // console.log(
        //   'ujj snake caught: timestamp: ' +
        //     vehicleDetails.timestamp +
        //     ' tempTimestampCheck: ' +
        //     tempTimestampCheck +
        //     ' timediff: ' +
        //     diffTimeStamp.peek()
        // );

        tempTimestampCheck = vehicleDetails.timestamp;

        signalStatus.value = vehicleDetails.status;
        signalAddress.value = vehicleDetails.address;
        signalTimestamp.value = getLocalTimestamp(vehicleDetails.timestamp);
        signalSpeed.value = Math.ceil(vehicleDetails.speed); //todo ujj not needed ceil since speed is int

        setSpeedAnalog(vehicleDetails.speed);
        signalTotalWeight.value = vehicleDetails.totalWeight;
        signalSteerWeight.value = vehicleDetails.steerWeight;
        signalDriveWeight.value = vehicleDetails.driveWeight;
        signalTrailerAWeight.value = vehicleDetails.trailerAWeight;
        signalTrailerBWeight.value = vehicleDetails.trailerBWeight;
        signalScaleUnit.value = vehicleDetails.scaleUnit;

        if (
          signalSteerMaxWeight > 0 &&
          vehicleDetails.steerWeight > 0 &&
          vehicleDetails.steerWeight > signalSteerMaxWeight.value
        ) {
          signalSteerOverloads.value = untracked(() => fnTotalSteerOverload());
        }

        if (
          signalDriveMaxWeight > 0 &&
          vehicleDetails.driveWeight > 0 &&
          vehicleDetails.driveWeight > signalDriveMaxWeight.value
        ) {
          signalDriveOverloads.value = untracked(() => fnTotalDriveOverload());
        }

        if (
          signalTrailerAMaxWeight > 0 &&
          vehicleDetails.trailerAWeight > 0 &&
          vehicleDetails.trailerAWeight > signalTrailerAMaxWeight.value
        ) {
          signalTrailerAOverloads.value = untracked(() =>
            fnTotalTrailerAOverload()
          );
        }

        if (
          signalTrailerBMaxWeight > 0 &&
          vehicleDetails.trailerBWeight > 0 &&
          vehicleDetails.trailerBWeight > signalTrailerBMaxWeight.value
        ) {
          signalTrailerBOverloads.value = untracked(() =>
            fnTotalTrailerBOverload()
          );
        }
        if (chartComponentRef.current) {
          //update chart####################
          if (vehicleDetails.status === 'online') {
            signalTotalOnlineCount.value = untracked(() =>
              fnTotalOnlineCount()
            );
          } else if (vehicleDetails.status === 'paused') {
            signalTotalPausedCount.value = untracked(() =>
              fnTotalPausedCount()
            );
          } else {
            signalTotalOfflineCount.value = untracked(() =>
              fnTotalOfflineCount()
            );
          }

          chartComponentRef.current.updateChart();
        }
        //###########################
      }
    }
  };

  const snakeInEventHandler = (ev) => {
    if (!isMotionReset.current) {
      const { lat, lng } = ev;

      //##
      //recenter map for new lat,lng
      if (
        (prevLat === 0 && prevLng === 0) ||
        (prevLat !== lat && prevLng !== lng)
      ) {
        prevLat = lat;
        prevLng = lng;
        if (mapRefTrack.current) {
          mapRefTrack.current.setView([lat, lng], 17);
        }
      }

      //###
      //for streeview
      streetViewLatSignal.value = lat;
      streetViewLngSignal.value = lng;

      // console.log(
      //   lastEventTime +
      //     ' ujj location is : ' +
      //     streetViewLatSignal.value +
      //     ', ' +
      //     streetViewLngSignal.value
      // );
    }
  };

  //fetching vehicle data
  useEffect(() => {
    console.log('ujj fetching vehicle data...');
    fetchVehicleData();
  }, []);

  const fetchVehicleData = async () => {
    try {
      const server = process.env.REACT_APP_API_SERVER;

      const username = process.env.REACT_APP_API_USERNAME;
      const password = process.env.REACT_APP_API_PASSWORD;

      const credentials = btoa(`${username}:${password}`);
      const response = await fetch(
        `${server}/vehicles/${organizationId}/${rego.rego}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Basic ${credentials}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        signalSteerMaxWeight.value = data.steerMaxWeight;
        signalDriveMaxWeight.value = data.driveMaxWeight;
        signalTrailerAMaxWeight.value = data.trailerAMaxWeight;
        signalTrailerBMaxWeight.value = data.trailerBMaxWeight;
        // signalVehicleImageUrl.value = data.imageUrl;
        // console.log('ujj vehicle data: ' + data.imageUrl);
      }
    } catch (error) {
      // console.log('ujj error fetching vehicle data: ', error);
    }
  };

  //reversed is original (ascending date)
  // const fleetDataOriginal = [...fleetData];

  // const routeArray = fleetData.map(({ latitude, longitude }) => [
  //   latitude,
  //   longitude,
  // ]);

  const gpsIconStartEnd = new L.divIcon({
    html: '<i class="bi bi-geo-alt-fill" style="color: #0c78b6;"></i>',
    iconSize: [32, 32],
    iconAnchor: [8, 12],
    popupAnchor: [0, -32],
    className: 'custom-bootstrap-icon', // Add a custom class for styling
  });
  const gpsIconPaused = new L.divIcon({
    html: '<i class="bi bi-geo-alt-fill" style="color: #fa7f00;"></i>',
    iconSize: [32, 32],
    iconAnchor: [8, 12],
    popupAnchor: [0, -32],
    className: 'custom-bootstrap-icon', // Add a custom class for styling
  });
  const gpsIconStopped = new L.divIcon({
    html: '<i class="bi bi-geo-alt-fill" style="color: #EC2D01;"></i>',
    iconSize: [32, 32],
    iconAnchor: [8, 12],
    popupAnchor: [0, -32],
    className: 'custom-bootstrap-icon', // Add a custom class for styling
  });

  const gpsIconCenter = new L.divIcon({
    html: '<i class="bi bi-geo-alt-fill" style="color: green;"></i>',
    iconSize: [32, 32],
    iconAnchor: [8, 12],
    popupAnchor: [0, -32],
    className: 'custom-bootstrap-icon', // Add a custom class for styling
  });

  const calculateBearing = (lat1, lon1, lat2, lon2) => {
    // const lat1 = point1.lat;
    // const lon1 = point1.lng;
    // const lat2 = point2.lat;
    // const lon2 = point2.lng;

    const angleRad = Math.atan2(lon2 - lon1, lat2 - lat1);
    let angleDeg = (angleRad * 180) / Math.PI;

    // angleDeg += 90;

    return angleDeg;
  };

  const calculateTimestampFromStringToSecond = (dateString) => {
    const timestampMillis = Date.parse(dateString);

    return timestampMillis / 1000; //convert timestamp msec to sec
  };

  //convert utc timestamp from server to localtimestamp
  const getLocalTimestamp = (utcTimestamp) => {
    if (utcTimestamp != null && utcTimestamp !== '') {
      return (
        moment
          // .utc(utcTimestamp, 'MMM D, YYYY, h:mm:ss A')
          .utc(utcTimestamp, moment.ISO_8601)
          .local()
          .format('MMM D, YYYY, h:mm:ss A')
      );
    } else {
      return '';
    }
  };

  const createSequenceGroup = (fleetDataMain) => {
    const seqGroup = L.motion.seq({ className: 'motion-path' });

    if (routeArrayLatLng.length > 0) {
      // Marker for the first coordinate
      polylineRef.current = L.motion
        .polyline(
          [routeArrayLatLng[0], routeArrayLatLng[0]],
          {
            color: 'orangered',
            data: {
              timestamp: fleetDataMain[0].timestamp,
            },
          },
          null,
          {
            removeOnEnd: false,
            icon: gpsIconStartEnd,
          }
        )
        .motionDuration(motionDurationRef.current);

      polylineRef.current.bindPopup('start');
      seqGroup.addLayer(polylineRef.current, true);

      // Polyline connecting the coordinates
      for (let i = 0; i < routeArrayLatLng.length - 1; i++) {
        if (fleetDataMain[i].status === 'paused') {
          polylineRef.current = L.motion
            .polyline(
              [routeArrayLatLng[i], routeArrayLatLng[i]],
              {
                color: 'orangered',
                data: {
                  timestamp: fleetDataMain[i].timestamp,
                },
              },
              null,
              {
                // removeOnEnd: false,
                // icon: gpsIconPaused,
                removeOnEnd: true,
                icon: L.divIcon({
                  html: `<img id="marker-img" src="${vehicleCar}" style="transform: rotate(${bearing}deg); transition: transform 0.3s ease;" alt="Truck Icon">`,
                  iconSize: [32, 50],
                  iconAnchor: [8, 24],
                  popupAnchor: [0, 0],
                  className: 'truck-bootstrap-icon', // Add a custom class for styling
                }),
              }
            )
            .motionDuration(motionDurationRef.current);

          polylineRef.current.bindPopup('paused').openPopup();
          seqGroup.addLayer(polylineRef.current, true);
        } else if (fleetDataMain[i].status === 'offline') {
          polylineRef.current = L.motion
            .polyline(
              [routeArrayLatLng[i], routeArrayLatLng[i]],
              {
                color: 'orangered',
                data: {
                  timestamp: fleetDataMain[i].timestamp,
                },
              },
              null,
              {
                // removeOnEnd: false,
                // icon: gpsIconStopped,

                removeOnEnd: true,
                icon: L.divIcon({
                  html: `<img id="marker-img" src="${vehicleCar}" style="transform: rotate(${bearing}deg); transition: transform 0.3s ease;" alt="Truck Icon">`,
                  iconSize: [32, 50],
                  iconAnchor: [8, 24],
                  popupAnchor: [0, 0],
                  className: 'truck-bootstrap-icon', // Add a custom class for styling
                }),
              }
            )
            .motionDuration(motionDurationRef.current);

          polylineRef.current.bindPopup('stopped');
          seqGroup.addLayer(polylineRef.current, true);
        } else {
          // for online case calculate bearing
          const map = mapRefRoute.current;
          const point1 = L.latLng(
            routeArrayLatLng[i].lat,
            routeArrayLatLng[i].lng
          );
          const point2 = L.latLng(
            routeArrayLatLng[i + 1].lat,
            routeArrayLatLng[i + 1].lng
          );
          const length = map.distance(point1, point2);

          if (length > 5) {
            bearing = calculateBearing(
              routeArrayLatLng[i].lat,
              routeArrayLatLng[i].lng,
              routeArrayLatLng[i + 1].lat,
              routeArrayLatLng[i + 1].lng
            );
          }
        }

        polylineRef.current = L.motion
          .polyline(
            [routeArrayLatLng[i], routeArrayLatLng[i + 1]],
            {
              color: 'orange',
              data: {
                timestamp: fleetDataMain[i].timestamp,
              },
            },
            null,
            {
              removeOnEnd: true,
              icon: L.divIcon({
                html: `<img id="marker-img" src="${vehicleCar}" style="transform: rotate(${bearing}deg); transition: transform 0.3s ease;" alt="Truck Icon">`,
                iconSize: [32, 50],
                iconAnchor: [8, 24],
                popupAnchor: [0, 0],
                className: 'truck-bootstrap-icon', // Add a custom class for styling
              }),
            }
          )
          .motionDuration(motionDurationRef.current);
        seqGroup.addLayer(polylineRef.current, true);

        polylineRef.current.on('snakein', snakeInEventHandler);
      }

      polylineRef.current = L.motion
        .polyline(
          [
            routeArrayLatLng[routeArrayLatLng.length - 1],
            routeArrayLatLng[routeArray.length - 1],
          ],
          {
            color: 'orangered',
            data: {
              timestamp: fleetDataMain[routeArrayLatLng.length - 1].timestamp,
            },
          },
          null,
          {
            removeOnEnd: false,
            icon: gpsIconStartEnd,
          }
        )
        .motionDuration(motionDurationRef.current);

      polylineRef.current.bindPopup('end');
      seqGroup.addLayer(polylineRef.current, true);
    }

    return seqGroup;
  };

  const polylineDataMap = new Map();

  const createSequenceGroupRoute = (fleetDataMain) => {
    const seqGroup = L.motion.seq({ className: 'motion-path' });

    if (routeArrayLatLng.length > 0) {
      // Marker for the first coordinate
      polylineRouteRef.current = L.motion
        .polyline(
          [routeArrayLatLng[0], routeArrayLatLng[0]],
          {
            color: 'orangered',
          },
          null,
          {
            removeOnEnd: false,
            icon: gpsIconStartEnd,
          }
        )
        .motionDuration(motionDurationRef.current);
      polylineRouteRef.current.bindPopup('start');
      seqGroup.addLayer(polylineRouteRef.current, true);
      // Store custom data associated with the polyline
      polylineDataMap.set(polylineRouteRef.current, {
        timestamp: fleetDataMain[0].timestamp,
      });
      // Polyline connecting the coordinates
      for (let i = 0; i < routeArrayLatLng.length - 1; i++) {
        if (fleetDataMain[i].status === 'paused') {
          polylineRouteRef.current = L.motion
            .polyline(
              [routeArray[i], routeArray[i]],
              {
                color: 'orangered',
              },
              null,
              {
                removeOnEnd: false,
                icon: gpsIconPaused,
              }
            )
            .motionDuration(motionDurationRef.current);
          polylineRouteRef.current.bindPopup('paused');
          seqGroup.addLayer(polylineRouteRef.current, true);
          // Store custom data associated with the polyline
          polylineDataMap.set(polylineRouteRef.current, {
            timestamp: fleetDataMain[i].timestamp,
          });
        } else if (fleetDataMain[i].status === 'offline') {
          polylineRouteRef.current = L.motion
            .polyline(
              [routeArray[i], routeArray[i]],
              {
                color: 'orangered',
              },
              null,
              {
                removeOnEnd: false,
                icon: gpsIconStopped,
              }
            )
            .motionDuration(motionDurationRef.current);
          polylineRouteRef.current.bindPopup('stopped');
          seqGroup.addLayer(polylineRouteRef.current, true);
          // Store custom data associated with the polyline
          polylineDataMap.set(polylineRouteRef.current, {
            timestamp: fleetDataMain[i].timestamp,
          });
        }

        polylineRouteRef.current = L.motion
          .polyline(
            [routeArray[i], routeArray[i + 1]],
            {
              color: 'transparent',
            },
            null,
            {
              removeOnEnd: true,
              // showMarker: true,
              icon: L.divIcon({
                html: `<i class="bi bi-circle-fill" style="color: #0c78b6;"></i>`,
                iconSize: [32, 32],
                iconAnchor: [6, 8],
                popupAnchor: [0, -32],
                className: 'custom-bootstrap-icon', // Add a custom class for styling
              }),
            }
          )
          .motionDuration(motionDurationRef.current);
        seqGroup.addLayer(polylineRouteRef.current, true);
        // Store custom data associated with the polyline
        polylineDataMap.set(polylineRouteRef.current, {
          timestamp: fleetDataMain[i].timestamp,
        });

        // polylineRouteRef.current.on('snakein', snakeInEventHandler);
      }

      polylineRouteRef.current = L.motion
        .polyline(
          [
            routeArray[routeArray.length - 1],
            routeArray[routeArray.length - 1],
          ],
          {
            color: 'orangered',
          },
          null,
          {
            removeOnEnd: false,
            icon: gpsIconStartEnd,
          }
        )
        .motionDuration(motionDurationRef.current);
      polylineRouteRef.current.bindPopup('end');
      seqGroup.addLayer(polylineRouteRef.current, true);
      // Store custom data associated with the polyline
      polylineDataMap.set(polylineRouteRef.current, {
        timestamp: fleetDataMain[routeArrayLatLng.length - 1].timestamp,
      });
    }

    seqGroup.on('motion-started', (ev) => {
      console.log('ujj start motion event :' + ev);
    });

    seqGroup.on('motion-paused', (ev) => {
      console.log('ujj pause Motion event :' + ev);
    });

    seqGroup.on('motion-resumed', (ev) => {
      console.log('ujj resume Motion event :' + ev);
    });

    seqGroup.on('motion-ended', (ev) => {
      console.log('ujj end motion event :' + ev);
      motionStateSignal.value = '';
    });

    seqGroup.on('motion-section', motionSectionHandler);

    seqGroup.on('motion-capture', (ev) => {
      console.log('ujj capture motion event :' + ev);
    });

    return seqGroup;
  };

  const motionSectionHandler = (ev) => {
    if (!isMotionReset.current) {
      // Access polyline and its associated data
      const polyline = ev.layer;
      const polylineData = polylineDataMap.get(polyline);
      if (polylineData) {
        const timestamp = polylineData.timestamp;
        getVehicleDetailsFromTimestamp(timestamp);
      } else {
        console.log('ujj section motion event: no data');
      }
    }
  };

  let seqGroup;
  let seqGroupRoute;

  const handleClickFast = () => {
    if (motionStateSignal.value === 'play') {
      if (motionDurationRef.current - 400 > 0) {
        seqGroup.motionPause();
        seqGroupRoute.motionPause();

        motionDurationRef.current = motionDurationRef.current - 400;
        signalMotionDurationSpeed.value = 6 - motionDurationRef.current / 400; //max is 5 min is 1

        polylineRef.current.motionDuration(motionDurationRef.current);
        polylineRouteRef.current.motionDuration(motionDurationRef.current);

        seqGroup.motionResume();
        seqGroupRoute.motionResume();
      }
    }
  };

  const handleClickSlow = () => {
    if (motionStateSignal.value === 'play') {
      if (motionDurationRef.current + 400 <= 2000) {
        seqGroup.motionPause();
        seqGroupRoute.motionPause();

        motionDurationRef.current = motionDurationRef.current + 400;
        signalMotionDurationSpeed.value = 6 - motionDurationRef.current / 400; //max is 5 min is 1

        polylineRef.current.motionDuration(motionDurationRef.current);
        polylineRouteRef.current.motionDuration(motionDurationRef.current);

        seqGroup.motionResume();
        seqGroupRoute.motionResume();
      }
    }
  };

  const handleClickPlay = () => {
    isMotionReset.current = false;

    if (motionStateSignal.value === null || motionStateSignal.value === '') {
      motionStateSignal.value = 'play';

      document.querySelector('.playhistory-i-play').style.color = '#f18d11';
      document.querySelector('.playhistory-i-pause').style.color = '#98f3f8b3';
      document.querySelector('.playhistory-i-stop').style.color = '#98f3f8b3';

      handleClickForward();
    } else if (motionStateSignal.value === 'pause') {
      motionStateSignal.value = 'play';

      document.querySelector('.playhistory-i-play').style.color = '#f18d11';
      document.querySelector('.playhistory-i-pause').style.color = '#98f3f8b3';
      document.querySelector('.playhistory-i-stop').style.color = '#98f3f8b3';

      seqGroup.motionResume();
      seqGroupRoute.motionResume();
    }
  };

  const handleClickPause = () => {
    motionStateSignal.value = 'pause';
    document.querySelector('.playhistory-i-pause').style.color = '#f18d11';
    document.querySelector('.playhistory-i-play').style.color = '#98f3f8b3';
    document.querySelector('.playhistory-i-stop').style.color = '#98f3f8b3';

    seqGroup.motionPause();
    seqGroupRoute.motionPause();
  };

  const handleClickStop = () => {
    // window.location.reload();

    isMotionReset.current = true;

    document.querySelector('.playhistory-i-stop').style.color = '#f18d11';
    document.querySelector('.playhistory-i-pause').style.color = '#98f3f8b3';
    document.querySelector('.playhistory-i-play').style.color = '#98f3f8b3';

    console.log('ujj seqgroup check');
    if (seqGroup) {
      seqGroup.off('motion-section', motionSectionHandler);
      // polylineRef.current.off('snakein', snakeInEventHandler);

      // const listeners = polylineRef.current._events.data;
      // const index = listeners.indexOf(snakeInEventHandler);
      // if (index !== -1) {
      //   listners.splice(index, 1);
      // }

      // polylineRef.current._path.removeEventListener(
      //   'snakein',
      //   snakeInEventHandler
      // );

      seqGroup.clearLayers();
      console.log('ujj seqgroup check 1');
      seqGroup.motionStop();
      console.log('ujj seqGroup check 2');
    }

    console.log('ujj seqGroupRoute check');

    if (seqGroupRoute) {
      seqGroupRoute.clearLayers();
      console.log('ujj seqGroupRoute check 1');
      seqGroupRoute.motionStop();
      console.log('ujj seqGroupRoute check 2');
    }

    motionStateSignal.value = null;

    //reset
    signalTotalOnlineCount.value = 0;
    signalTotalPausedCount.value = 0;
    signalTotalOfflineCount.value = 0;

    signalSteerOverloads.value = 0;
    signalDriveOverloads.value = 0;
    signalTrailerAOverloads.value = 0;
    signalTrailerBOverloads.value = 0;

    tempForwardFleetDataOriginal = [...fleetData];

    //reset
    mapRefTrack.current.setView([routeArray[0][0], routeArray[0][1]], 17);
  };

  const handleClickForward = () => {
    fleetData.map(({ latitude, longitude }) => [latitude, longitude]);
    const bounds = L.latLngBounds(routeArray);

    mapRefTrack.current.fitBounds(bounds);
    if (seqGroup) {
      seqGroup.clearLayers();
    }
    // seqGroupReverse.clearLayers();
    // Create motion sequences
    seqGroup = createSequenceGroup(fleetData);
    // Start sequence after 1 second
    seqGroup.addTo(mapRefTrack.current);
    seqGroup.motionStart();

    mapRefRoute.current.fitBounds(bounds);
    if (seqGroupRoute) {
      seqGroupRoute.clearLayers();
    }
    // seqGroupRouteReverse.clearLayers();
    seqGroupRoute = createSequenceGroupRoute(fleetData);
    // Start sequence after 1 second
    seqGroupRoute.addTo(mapRefRoute.current);
    seqGroupRoute.motionStart();

    //reset
    signalTotalOnlineCount.value = 0;
    signalTotalPausedCount.value = 0;
    signalTotalOfflineCount.value = 0;
    tempForwardFleetDataOriginal = [...fleetData];
    signalSteerOverloads.value = 0;
    signalDriveOverloads.value = 0;
    signalTrailerAOverloads.value = 0;
    signalTrailerBOverloads.value = 0;
  };

  //for small screen
  const toggleControlDetails = (which) => {
    const detailsElement = document.getElementById(
      'id-playhistory-div-route-details'
    );
    const vehicleElement = document.getElementById(
      'id-playhistory-div-vehicle-details'
    );
    const routeMapElement = document.getElementById(
      'id-playhistory-div-routemap'
    );
    const analyticsElement = document.getElementById(
      'id-playhistory-div-analytics'
    );
    const chartElement = document.getElementById(
      'id-playhistory-div-chart-analysis'
    );

    if (which === 'details') {
      if (detailsElement) {
        detailsElement.classList.add('visible');

        vehicleElement.classList.remove('visible');
        routeMapElement.classList.remove('visible');
        analyticsElement.classList.remove('visible');
        chartElement.classList.remove('visible');
      }
    } else if (which === 'vehicle') {
      if (vehicleElement) {
        vehicleElement.classList.add('visible');

        detailsElement.classList.remove('visible');
        routeMapElement.classList.remove('visible');
        analyticsElement.classList.remove('visible');
        chartElement.classList.remove('visible');
      }
    } else if (which === 'analytics') {
      if (analyticsElement) {
        analyticsElement.classList.add('visible');

        detailsElement.classList.remove('visible');
        vehicleElement.classList.remove('visible');
        routeMapElement.classList.remove('visible');
        chartElement.classList.remove('visible');
      }
    } else if (which === 'routemap') {
      if (routeMapElement) {
        routeMapElement.classList.add('visible');

        detailsElement.classList.remove('visible');
        vehicleElement.classList.remove('visible');
        analyticsElement.classList.remove('visible');
        chartElement.classList.remove('visible');
      }
    } else if (which === 'chart') {
      if (chartElement) {
        chartElement.classList.add('visible');

        detailsElement.classList.remove('visible');
        vehicleElement.classList.remove('visible');
        analyticsElement.classList.remove('visible');
        routeMapElement.classList.remove('visible');
      }
    }
  };

  return (
    <div className='playhistory-div-container'>
      <div className='playhistory-div-mobile-controls'>
        <div
          className='playhistory-div-mobile-controls-btn'
          onClick={() => toggleControlDetails('details')}
        >
          <div className='button-div-btn-outer-rim'>
            <div className='button-div-btn-inner-rim'>
              <i className='bi bi-geo-alt'></i>
            </div>
          </div>
        </div>
        <div
          className='playhistory-div-mobile-controls-btn'
          onClick={() => toggleControlDetails('vehicle')}
        >
          <div className='button-div-btn-outer-rim'>
            <div className='button-div-btn-inner-rim'>
              <i className='bi bi-truck-front'></i>
            </div>
          </div>
        </div>
        <div
          className='playhistory-div-mobile-controls-btn'
          onClick={() => toggleControlDetails('routemap')}
        >
          <div className='button-div-btn-outer-rim'>
            <div className='button-div-btn-inner-rim'>
              <i className='bi bi-globe'></i>
            </div>
          </div>
        </div>
        <div
          className='playhistory-div-mobile-controls-btn'
          onClick={() => toggleControlDetails('analytics')}
        >
          <div className='button-div-btn-outer-rim'>
            <div className='button-div-btn-inner-rim'>
              <i className='bi bi-info-circle'></i>
            </div>
          </div>
        </div>
        <div
          className='playhistory-div-mobile-controls-btn'
          onClick={() => toggleControlDetails('chart')}
        >
          <div className='button-div-btn-outer-rim'>
            <div className='button-div-btn-inner-rim'>
              <i className='bi bi-graph-down-arrow'></i>
            </div>
          </div>
        </div>
      </div>
      {/* First row */}
      <div className='playhistory-div-upper'>
        <div
          className='playhistory-div-route-details'
          id='id-playhistory-div-route-details'
        >
          <div className='playhistory-div-route-details-header'>
            <img src={icon_trucktronix} alt='trucktronix icon' />
            <span>DETAILS</span>
          </div>
          <DetailsComponent />
        </div>
        <div className='playhistory-div-trackmap'>
          <span className='playhistory-map-span-header'>Track Map</span>

          <div className='playhistory-div-vehicle-details-compass-outer-rim'>
            <div className='playhistory-div-vehicle-details-compass-inner-rim'>
              <div className='playhistory-div-vehicle-details-compass'>
                <span>N</span>
                <span>E</span>
                <span>S</span>
                <span>W</span>
                <img id='imgCompassIcon' src={iconCompass} alt='compass' />
              </div>
            </div>
          </div>

          <MapContainer
            // key={animationKey}
            ref={mapRefTrack}
            id='leaflet-map' // Set the ID for the map container
            style={{
              width: 'auto',
              height: '100%',
              border: '1px solid #ccc',
              borderRadius: '4px',
            }}
            center={[routeArray[0][0], routeArray[0][1]]} // Set your initial map center
            zoom={18} // Set your initial map zoom level
          >
            <TileLayer
              url='http://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png'
              attribution='&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, &copy; <a href="http://cartodb.com/attributions">CartoDB</a>'
            />
            <Marker
              position={[routeArray[0][0], routeArray[0][1]]}
              icon={gpsIconCenter}
            />
          </MapContainer>
        </div>
        <div
          className='playhistory-div-vehicle-details'
          id='id-playhistory-div-vehicle-details'
        >
          <span>VEHICLE</span>

          <div className='outer-rim'>
            <div className='outer-rim-2'>
              <div className='inner-rim'>
                <div className='playhistory-div-vehicle-details-card'>
                  {/* header */}
                  <div className='playhistory-div-vehicle-details-card-header'>
                    <span
                      style={{
                        color: '#88b0c0',
                        fontSize: '14px',
                        marginRight: '6px',
                      }}
                    >
                      Rego:
                    </span>
                    {rego && typeof rego.rego === 'string' ? rego.rego : 'null'}
                    <div className='white-line'></div>
                  </div>

                  {/* vehicle image container */}
                  <div className='playhistory-div-vehicle-details-image-container'>
                    {signalVehicleImageUrl ? (
                      <img src={signalVehicleImageUrl.peek()} alt={'vehicle'} />
                    ) : (
                      <img src={vehicleLogo} alt='Vehicle Logo' />
                    )}
                  </div>

                  {/* Weight section */}
                  <div className='playhistory-div-route-details-weight-section'>
                    {/* steer */}
                    <div className='playhistory-div-route-details-axle'>
                      <span className='playhistory-div-route-details-axle-name'>
                        Steer
                      </span>
                      <span className='playhistory-div-route-details-axle-weight'>
                        {signalSteerWeight}{' '}
                        <span style={{ color: '#88b0c0', fontSize: '11px' }}>
                          {signalScaleUnit}
                        </span>
                      </span>
                      <div className='playhistory-div-route-details-axle-orange-line'></div>
                    </div>

                    {/* drive */}
                    <div className='playhistory-div-route-details-axle'>
                      <span className='playhistory-div-route-details-axle-name'>
                        Drive
                      </span>
                      <span className='playhistory-div-route-details-axle-weight'>
                        {signalDriveWeight}{' '}
                        <span style={{ color: '#88b0c0', fontSize: '11px' }}>
                          {signalScaleUnit}
                        </span>
                      </span>
                      <div className='playhistory-div-route-details-axle-orange-line'></div>
                    </div>

                    {/* Trailer A */}
                    <div className='playhistory-div-route-details-axle'>
                      <span className='playhistory-div-route-details-axle-name'>
                        Trailer A
                      </span>
                      <span className='playhistory-div-route-details-axle-weight'>
                        {signalTrailerAWeight}{' '}
                        <span style={{ color: '#88b0c0', fontSize: '11px' }}>
                          {signalScaleUnit}
                        </span>
                      </span>
                      <div className='playhistory-div-route-details-axle-orange-line'></div>
                    </div>

                    {/* Trailer B */}
                    <div className='playhistory-div-route-details-axle'>
                      <span className='playhistory-div-route-details-axle-name'>
                        Trailer B
                      </span>
                      <span className='playhistory-div-route-details-axle-weight'>
                        {signalTrailerBWeight}{' '}
                        <span style={{ color: '#88b0c0', fontSize: '11px' }}>
                          {signalScaleUnit}
                        </span>
                      </span>
                      <div className='playhistory-div-route-details-axle-orange-line'></div>
                    </div>

                    <span className='playhistory-span-route-details-card-total'>
                      <div className='playhistory-total-weight-name'>Total</div>
                      <div className='playhistory-total-weight-value'>
                        {signalTotalWeight}
                        <div
                          style={{
                            color: '#87ceeb',
                            fontSize: '12px',
                            marginLeft: '6px',
                          }}
                        >
                          {signalScaleUnit}
                        </div>
                      </div>
                    </span>
                  </div>

                  {/* <div className='playhistory-div-control-slider'>slider</div>  */}
                  <div
                    className='outer-rim-btn'
                    style={{ marginBottom: '8px' }}
                  >
                    <div className='playhistory-div-control-btn-header'>
                      <span>Replay Controls</span>
                      <span style={{ color: 'white' }}>
                        {motionStateSignal}
                      </span>
                      <span style={{ color: 'white' }}>
                        Speed: x{signalMotionDurationSpeed}
                      </span>
                    </div>
                    <div className='outer-rim-btn-2'>
                      <div className='inner-rim-btn'>
                        {/* backward */}
                        <div
                          className='playhistory-btn-replay-control'
                          onClick={handleClickSlow}
                        >
                          <i
                            className='bi bi-rewind-fill'
                            style={{ fontSize: '2.5rem', zIndex: '1' }}
                          ></i>
                        </div>

                        {/* stop */}
                        <div
                          className='playhistory-btn-replay-control'
                          onClick={handleClickStop}
                        >
                          <i
                            className='bi bi-skip-start-fill playhistory-i-stop'
                            style={{ fontSize: '2.5rem', zIndex: '1' }}
                          ></i>
                        </div>

                        {/* play */}
                        <div
                          className='playhistory-btn-replay-control'
                          onClick={handleClickPlay}
                        >
                          <i
                            className='bi bi-play-fill playhistory-i-play'
                            style={{ fontSize: '2.5rem', zIndex: '1' }}
                          ></i>
                        </div>

                        {/* pause */}
                        <div
                          className='playhistory-btn-replay-control'
                          onClick={handleClickPause}
                        >
                          <i
                            className='bi bi-pause-fill playhistory-i-pause'
                            style={{ fontSize: '2.5rem', zIndex: '1' }}
                          ></i>
                        </div>

                        {/* forward */}
                        <div
                          className='playhistory-btn-replay-control'
                          onClick={handleClickFast}
                        >
                          <i
                            className='bi bi-fast-forward-fill'
                            style={{ fontSize: '2.5rem', zIndex: '1' }}
                          ></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* #### */}
              </div>
            </div>
          </div>
          {/* ###### */}
        </div>
      </div>

      {/* Second row */}
      <div className='playhistory-div-lower'>
        <div
          className='playhistory-div-analytics'
          id='id-playhistory-div-analytics'
        >
          <span>ANALYTICS</span>
          <AnalyticsComponent />
        </div>
        <div
          className='playhistory-div-routemap'
          id='id-playhistory-div-routemap'
        >
          <span className='playhistory-map-span-header'>Route Map</span>
          <MapContainer
            // key={animationKey}
            ref={mapRefRoute}
            id='leaflet-map' // Set the ID for the map container
            style={{
              width: 'auto',
              height: '100%',
              border: '1px solid #ccc',
              borderRadius: '4px',
            }}
            center={[routeArray[0][0], routeArray[0][1]]} // Set your initial map center
            zoom={15} // Set your initial map zoom level
          >
            <TileLayer
              url='http://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png'
              attribution='&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, &copy; <a href="http://cartodb.com/attributions">CartoDB</a>'
            />
            <Marker
              position={[routeArray[0][0], routeArray[0][1]]}
              icon={gpsIconCenter}
            />
            <Polyline
              pathOptions={{ color: 'orange' }}
              positions={routeArray}
            />
          </MapContainer>
        </div>
        <div
          className='playhistory-div-chart-analysis'
          id='id-playhistory-div-chart-analysis'
        >
          <span>CHART</span>
          <ChartComponent ref={chartComponentRef} />
        </div>
      </div>
    </div>
  );
};

// Analytics component section
const AnalyticsComponent = () => {
  // const { organizationId, rego } = props;

  const [isAnalyticsDetailsActive, setAnalyticsDetailsActive] = useState(false);

  const handleAnalyticsDetailsBtn = () => {
    // setAnalyticsDetailsActive(!isAnalyticsDetailsActive);
  };
  return (
    <div className='outer-rim'>
      <div className='outer-rim-2'>
        <div className='inner-rim'>
          <div className='playhistory-div-analytics-card'>
            <div
              className={`playhistory-div-analytics-card-details ${
                isAnalyticsDetailsActive ? 'active' : ''
              }`}
            ></div>
            <div className='playhistory-div-analytics-buttons'>
              <div
                className='div-analytics-button'
                onClick={handleAnalyticsDetailsBtn}
              >
                <div className='outer-rim-btn-2'>
                  <div className='inner-rim-btn'>
                    <span className='span-analytics-button-header'>
                      Steer Overload
                    </span>
                    <div className='white-line'></div>
                    <div className='div-analytics-button-body'>
                      <div className='div-analytics-button-max-weight'>
                        <span>Max Weight</span>
                        <span>{signalSteerMaxWeight}</span>
                        <span>{signalOverloadWeightUnit}</span>
                      </div>

                      <div className='div-analytics-button-faults'>
                        <span>Overloads</span>
                        <span>{signalSteerOverloads}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className='div-analytics-button'
                onClick={handleAnalyticsDetailsBtn}
              >
                <div className='outer-rim-btn-2'>
                  <div className='inner-rim-btn'>
                    <span className='span-analytics-button-header'>
                      Drive Overload
                    </span>
                    <div className='white-line'></div>
                    <div className='div-analytics-button-body'>
                      <div className='div-analytics-button-max-weight'>
                        <span>Max Weight</span>
                        <span>{signalDriveMaxWeight}</span>
                        <span>{signalOverloadWeightUnit}</span>
                      </div>

                      <div className='div-analytics-button-faults'>
                        <span>Overloads</span>
                        <span>{signalDriveOverloads}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className='div-analytics-button'
                onClick={handleAnalyticsDetailsBtn}
              >
                <div className='outer-rim-btn-2'>
                  <div className='inner-rim-btn'>
                    <span className='span-analytics-button-header'>
                      Trailer A Overload
                    </span>
                    <div className='white-line'></div>
                    <div className='div-analytics-button-body'>
                      <div className='div-analytics-button-max-weight'>
                        <span>Max Weight</span>
                        <span>{signalTrailerAMaxWeight}</span>
                        <span>{signalOverloadWeightUnit}</span>
                      </div>

                      <div className='div-analytics-button-faults'>
                        <span>Overloads</span>
                        <span>{signalTrailerAOverloads}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className='div-analytics-button'
                onClick={handleAnalyticsDetailsBtn}
              >
                <div className='outer-rim-btn-2'>
                  <div className='inner-rim-btn'>
                    <span className='span-analytics-button-header'>
                      Trailer B Overload
                    </span>
                    <div className='white-line'></div>
                    <div className='div-analytics-button-body'>
                      <div className='div-analytics-button-max-weight'>
                        <span>Max Weight</span>
                        <span>{signalTrailerBMaxWeight}</span>
                        <span>{signalOverloadWeightUnit}</span>
                      </div>

                      <div className='div-analytics-button-faults'>
                        <span>Overloads</span>
                        <span>{signalTrailerBOverloads}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div
          className='div-analytics-button'
          onClick={handleAnalyticsDetailsBtn}
        >
          <span className='span-analytics-button-header'>Speed fault</span>
          <div className='div-analytics-button-body'>
            <div className='div-analytics-button-max-weight'>
              <span>Max speed</span>
              <span>80 </span>
              <span>kph</span>
            </div>

            <div className='div-analytics-button-faults'>
              <span>Faults</span>
              <span>0</span>
            </div>
          </div>
        </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ChartComponent = React.forwardRef((props, ref) => {
  const chartRef = useRef(null);
  const [onlineTime, setOnlineTime] = useState('0 s'); // Initialize onlineTime state //todo ujj remove
  const [idleTime, setIdleTime] = useState('0 s');
  const [restTime, setRestTime] = useState('0 s');

  const updateChart = () => {
    if (chartRef.current) {
      if (signalTotalOnlineCount.peek() < 60) {
        setOnlineTime(signalTotalOnlineCount.peek() + ' s');
      } else {
        const minutes = Math.floor(signalTotalOnlineCount.peek() / 60);
        if (minutes > 60) {
          const hour = Math.floor(minutes / 60);
          const min = minutes % 60;
          const seconds = signalTotalOnlineCount.peek() % 60;
          if (seconds !== 0) {
            setOnlineTime(`${hour} hr ${min} m ${seconds} s`);
          } else {
            if (min !== 0) {
              setOnlineTime(`${hour} hr ${minutes} m`);
            } else {
              setOnlineTime(`${hour} hr`);
            }
          }
        } else {
          const seconds = signalTotalOnlineCount.peek() % 60;
          if (seconds !== 0) {
            setOnlineTime(`${minutes} m ${seconds} s`);
          } else {
            setOnlineTime(`${minutes} m`);
          }
        }
      }

      if (signalTotalPausedCount.peek() < 60) {
        setIdleTime(signalTotalPausedCount.peek() + ' s');
      } else {
        const minutes = Math.floor(signalTotalPausedCount.peek() / 60);
        const seconds = signalTotalPausedCount.peek() % 60;
        if (seconds !== 0) {
          setIdleTime(`${minutes} m ${seconds} s`);
        } else {
          setIdleTime(`${minutes} m`);
        }
      }

      if (signalTotalOfflineCount.peek() < 60) {
        setRestTime(signalTotalOfflineCount.peek() + ' s');
      } else {
        const minutes = Math.floor(signalTotalOfflineCount.peek() / 60);
        const seconds = signalTotalOfflineCount.peek() % 60;
        if (seconds !== 0) {
          setRestTime(`${minutes} m ${seconds} s`);
        } else {
          setRestTime(`${minutes} m ${seconds} s`);
        }
      }

      chartRef.current.data.datasets[0].data[0] = signalTotalOnlineCount.peek();
      chartRef.current.data.datasets[0].data[1] = signalTotalPausedCount.peek();
      chartRef.current.data.datasets[0].data[2] =
        signalTotalOfflineCount.peek();

      chartRef.current.update();
    }
  };

  React.useImperativeHandle(ref, () => ({
    updateChart,
  }));

  return (
    <div className='outer-rim'>
      <div className='outer-rim-2'>
        <div className='inner-rim'>
          <div className='playhistory-div-chart-anaylysis-activity'>
            <div className='playhistory-div-chart-analysis-activity-count'>
              <div className='online'>
                <span>Online</span> <br /> {onlineTime}
              </div>
              <div className='idle'>
                <span>Idle</span> <br /> {idleTime}
              </div>
              <div className='offline'>
                <span>Offline</span> <br /> {restTime}
              </div>
            </div>

            <div className='playhistory-div-chart-analysis-activity-bar'>
              <Bar
                ref={chartRef}
                data={{
                  labels: ['Online', 'Idle', 'Offline'],
                  datasets: [
                    {
                      label: 'Activity (calculated in sec)',
                      backgroundColor: ['green', 'orange', 'red'],

                      data: [
                        signalTotalOnlineCount.value,
                        signalTotalPausedCount.value,
                        signalTotalOfflineCount.value,
                      ],
                    },
                  ],
                }}
                options={{
                  maintainAspectRatio: false,
                  aspectRatio: 1,
                  plugins: {
                    legend: {
                      labels: {
                        color: '#87ceeb',
                      },
                    },
                  },
                  scales: {
                    x: {
                      ticks: {
                        color: '#88b0c0',
                      },
                    },
                    y: {
                      ticks: {
                        color: '#88b0c0',
                      },
                    },
                  },
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

//#######################################################################

function DetailsComponent() {
  let statusDetails = 'online';
  effect(() => {
    const imgSpeedPinIcon = document.getElementById('imgSpeedPinIcon');
    if (imgSpeedPinIcon) {
      imgSpeedPinIcon.style.transform = `rotate(${signalRotationAngleSpeedometer}deg)`;
    }

    const statusIconElement = document.querySelector(
      '.playhistory-div-route-details-status-icon'
    );
    const statusDivElement = document.querySelector(
      '.playhistory-div-route-details-status'
    );

    statusDetails = signalStatus.value;
    if (statusIconElement) {
      switch (statusDetails) {
        case 'online':
          // statusDivElement.style.backgroundColor = '#3a5f0b';
          statusIconElement.style.backgroundColor = '#3a5f0b';
          break;
        case 'paused':
          // statusDivElement.style.backgroundColor = '#fa7f00';
          statusIconElement.style.backgroundColor = '#fa7f00';
          break;
        default:
          // statusDivElement.style.backgroundColor = '#ec2d01';
          statusIconElement.style.backgroundColor = '#ec2d01';
          break;
      }
    }
  });

  const handleStreetView = () => {
    if (streetViewLatSignal.peek() !== 0 && streetViewLngSignal.peek() !== 0) {
      const googleStreetViewUrl = `https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${streetViewLatSignal.peek()},${streetViewLngSignal.peek()}`;
      window.open(googleStreetViewUrl, '_blank');
    }
  };

  return (
    <div className='outer-rim'>
      <div className='outer-rim-2'>
        <div className='inner-rim'>
          <div className='playhistory-div-route-details-card'>
            {/* {currentVehicleDetailsRef.current ? (
              <DetailsComponent propDetail={currentVehicleDetailsRef.current} />
            ) : (
              <p>finding route...</p>
            )} */}

            <div className='playhistory-div-route-details-top'>
              <span className='playhistory-span-route-details-timestamp'>
                {signalTimestamp}
              </span>

              <div className='playhistory-div-route-details-status'>
                <div className='playhistory-div-route-details-status-icon'></div>
                <span>{signalStatus}</span>
              </div>
            </div>

            <div className='playhistory-div-route-details-address-section'>
              <span>Address</span>
              <div className='playhistory-div-route-details-address-body'>
                <span className='playhistory-span-route-details-address-name'>
                  {signalAddress}
                </span>
                <div
                  className='playhistory-div-route-details-streetview'
                  onClick={handleStreetView}
                >
                  <div className='button-div-btn-outer-rim'>
                    <div className='button-div-btn-inner-rim'>
                      <i className='bi bi-eye-fill'></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* todo ujj */}
            {/* <div className='playhistory-div-route-details-meters'> */}
            {/* <div className='playhistory-div-vehicle-details-compass-outer-rim'>
                <div className='playhistory-div-vehicle-details-compass-inner-rim'>
                  <div className='playhistory-div-vehicle-details-compass'>
                    <span>N</span>
                    <span>E</span>
                    <span>S</span>
                    <span>W</span>
                    <img id='imgCompassIcon' src={iconCompass} alt='compass' />
                  </div>
                </div>
              </div> */}

            <div className='playhistory-div-vehicle-details-speedometer-outer-rim'>
              <div className='playhistory-div-vehicle-details-speedometer-inner-rim'>
                <div className='playhistory-div-vehicle-details-speedometer'>
                  <img src={iconSpeedometerBody} alt='speedometer' />
                  <img
                    id='imgSpeedPinIcon'
                    src={iconSpeedometerPin}
                    alt='speedometer-pin'
                  />

                  <span>{signalSpeed}</span>
                </div>
              </div>
            </div>
            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PlayHistory;
