import React from 'react';

const FloatingMenus = ({
  onVehicleIconClick,
  onGeoFenceIconClick,
  onAccountIconClick,
  onFleetTableIconClick,
  onHistoryIconClick,
  onFleetManagerIconClick,
  onHelpIconClick,
}) => {
  return (
    <div className='floating-menus-div-container'>
      {/* Vehicle Icon */}
      <div
        className={`icon-background truck`}
        onClick={onVehicleIconClick}
        role='button'
        tabIndex={0} // Add tabindex for accessibility
      >
        <div className='floatingmenu-div-outer-rim'>
          <div className='floatingmenu-div-inner-rim'>
            <i className='bi bi-truck' style={{ fontSize: '1.25rem' }}></i>
            <span className='floatingmenu-span-text'>Vehicles</span>
          </div>
        </div>
      </div>

      {/* geo fence icon click */}
      <div
        className={`icon-background geo-fence`}
        onClick={onGeoFenceIconClick}
        role='button'
        tabIndex={1} // Add tabindex for accessibility
      >
        <div className='floatingmenu-div-outer-rim'>
          <div className='floatingmenu-div-inner-rim'>
            <i
              className='bi bi-bounding-box-circles'
              style={{ fontSize: '1.25rem' }}
            ></i>
            <span className='floatingmenu-span-text'>FleetPlan</span>
          </div>
        </div>
      </div>

      {/* FleetTable Icon */}
      <div
        className='icon-background table'
        onClick={onFleetTableIconClick}
        role='button'
        tabIndex={2}
      >
        <div className='floatingmenu-div-outer-rim'>
          <div className='floatingmenu-div-inner-rim'>
            <i className='bi bi-table' style={{ fontSize: '1.25rem' }}></i>
            <span className='floatingmenu-span-text'>FleetTable</span>
          </div>
        </div>
      </div>

      {/* History Icon */}
      <div
        className='icon-background history'
        onClick={onHistoryIconClick}
        role='button'
        tabIndex={3}
      >
        <div className='floatingmenu-div-outer-rim'>
          <div className='floatingmenu-div-inner-rim'>
            <i className='bi bi-clock' style={{ fontSize: '1.25rem' }}></i>
            <span className='floatingmenu-span-text'>History</span>
          </div>
        </div>
      </div>

      {/* Employees Icon */}
      <div
        className='icon-background employee'
        onClick={() => onFleetManagerIconClick()}
        role='button'
        tabIndex={4}
      >
        <div className='floatingmenu-div-outer-rim'>
          <div className='floatingmenu-div-inner-rim'>
            <i
              className='bi bi-person-gear'
              style={{ fontSize: '1.25rem' }}
            ></i>
            <span className='floatingmenu-span-text'>FleetManager</span>
          </div>
        </div>
      </div>

      {/* Account Icon */}
      <div
        className='icon-background account'
        onClick={() => onAccountIconClick()}
        role='button'
        tabIndex={5}
      >
        <div className='floatingmenu-div-outer-rim'>
          <div className='floatingmenu-div-inner-rim'>
            <i className='bi bi-person' style={{ fontSize: '1.25rem' }}></i>
            <span className='floatingmenu-span-text'>Account</span>
          </div>
        </div>
      </div>

      {/* Help Icon */}
      <div
        className='icon-background help'
        role='button'
        tabIndex={6}
        onClick={() => onHelpIconClick()}
      >
        <div className='floatingmenu-div-outer-rim'>
          <div className='floatingmenu-div-inner-rim'>
            <i
              className='bi bi-patch-question'
              style={{ fontSize: '1.25rem' }}
            ></i>
            <span className='floatingmenu-span-text'>Help</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FloatingMenus;
