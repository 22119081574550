// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getAuth} from 'firebase/auth';
import {getDatabase} from 'firebase/database';
import {getStorage} from 'firebase/storage';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDIkStNDr2gTRT3_VHRiBpEGbXtQtcTXcM",
  authDomain: "fleetmaster-12f38.firebaseapp.com",
  projectId: "fleetmaster-12f38",
  storageBucket: "fleetmaster-12f38.appspot.com",
  messagingSenderId: "15564493901",
  appId: "1:15564493901:web:07af47f617f6d483237804"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth=getAuth(app);
export const db=getDatabase(app);
export const storage=getStorage(app);

export default app;