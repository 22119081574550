// EmployeeTable.jsx
import React, { useEffect, useState } from 'react';
import EmployeeCard from './EmployeeCard';
import logoImage from '../../assets/trucktronix icon.png';
import { toast } from 'react-toastify';
import './EmployeeData.css';
import AddEmployeeModal from './AddEmployeeModal';
import UpdateEmployeeModal from './UpdateEmployeeModal';
import ViewEmployeeModal from './ViewEmployeeModal';

const EmployeeData = ({ updateEmployeesCount }) => {
  const [employees, setEmployees] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchEmployee, setSearchEmployee] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false); // State for Update Employee Modal
  const [selectedEmployee, setSelectedEmployee] = useState(null); // State to store the selected employee
  // const [viewedEmployee, setViewedEmployee] = useState(null);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false); // State for Update Employee Modal
  const [modalAnimation, setModalAnimation] = useState(''); //for close animation

  const organizationId = sessionStorage.getItem('org_id');

  const fetchData = async () => {
    try {
      const server = process.env.REACT_APP_API_SERVER;
      const username = process.env.REACT_APP_API_USERNAME;
      const password = process.env.REACT_APP_API_PASSWORD;

      const credentials = btoa(`${username}:${password}`);
      const response = await fetch(`${server}/employees/${organizationId}`, {
        method: 'GET',
        headers: {
          Authorization: `Basic ${credentials}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setEmployees(data);
        updateEmployeesCount(data.length);
      } else if (response.status === 404) {
        toast.info("Please add  employees first!");
      }
      else {
        toast.error(
          `An error occurred while fetching employee data: ${response.status}`
        );
      }
    } catch (error) {
      toast.error(`Error: ${error.message}`);
      // console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  //Add a new function to handle search
  const handleSearchEmployee = (e) => {
    setSearchEmployee(e.target.value);
  };

  //Filter employees based on the search term
  const filteredEmployees = employees.filter(
    (employee) =>
      employee.empId.toLowerCase().includes(searchEmployee.toLowerCase()) ||
      employee.name.toLowerCase().includes(searchEmployee.toLowerCase()) ||
      employee.rego.toLowerCase().includes(searchEmployee.toLowerCase()) ||
      employee.email.toLowerCase().includes(searchEmployee.toLowerCase())
  );
  // useEffect(() => {
  //   console.log('Search Term:', searchEmployee);
  //   console.log('Filtered Employees:', filteredEmployees);
  // }, [searchEmployee, filteredEmployees]);

  useEffect(() => {
    fetchData();
  }, []);

  const openModal = () => {
    setIsModalOpen(true);
    setModalAnimation('');
  };
  const closeModal = () => {
    setModalAnimation('slideOut');
    setTimeout(() => {
      setIsModalOpen(false);
    }, 6000); // Ensure this matches the transition duration
  };

  const onCloseUpdateEmployeeModal = () => {
    setModalAnimation('slideOut');
    setTimeout(() => {
      setIsUpdateModalOpen(false);
    }, 600); // Ensure this matches the transition duration
  };

  const onCloseViewEmployeeModal = () => {
    setModalAnimation('slideOut');
    setTimeout(() => {
      setIsViewModalOpen(false);
    }, 600);
  };
  // const handleEmployeeAdded = () => {
  //   fetchData();
  // };
  /**
   *
   * @param {*} rego
   */
  const handleDeleteEmployee = async (empId) => {
    //Show confirmation dialog before proceeding with the deletion
    if (!window.confirm('Are you sure you want to delete this employee?'))
      return;
    try {
      const server = process.env.REACT_APP_API_SERVER;
      // const server = 'http://localhost:4567';
      const username = process.env.REACT_APP_API_USERNAME;
      const password = process.env.REACT_APP_API_PASSWORD;

      const credentials = btoa(`${username}:${password}`);

      const response = await fetch(
        `${server}/employees/${organizationId}/${empId}`,
        {
          method: 'DELETE',
          headers: {
            Authorization: `Basic ${credentials}`,
          },
        }
      );
      if (response.ok) {
        //Remove the deleted employee from the local state
        setEmployees((prevEmployees) =>
          prevEmployees.filter((employee) => employee.empId !== empId)
        );
        updateEmployeesCount(employees.length - 1);
        toast.success('Employee deleted successfully!');
      } else if (response.status === 404) {
        toast.error("Employee not found in db.");
      } else {
        toast.error('Failed to delete employee');
      }
    } catch (error) {
      toast.error(`Error:${error.message}`);
      // console.log(error)
    }
  };

  const handleUpdateEmployee = (employee) => {
    setSelectedEmployee(employee);
    setIsUpdateModalOpen(true);
    setModalAnimation('');
  };

  const handleViewEmployee = (employee) => {
    setSelectedEmployee(employee);
    setIsViewModalOpen(true);
    setModalAnimation('');
  };

  return (
    <div className='employeedata-div-main-container'>
      <div className='employeedata-div-header'>
        <span>Employees</span>
        <input
          className='employeedata-input-search-employee'
          type='text'
          placeholder='Search Employee by name, email, rego or empId...'
          value={searchEmployee}
          onChange={handleSearchEmployee}
        />
        <div className='employeedata-button-add-employee' onClick={openModal}>
          <div className='employeecard-div-btn-outer-rim'>
            <div className='employeecard-div-btn-inner-rim'>Add Employee</div>
          </div>
        </div>
      </div>
      {isLoading ? (
        <div className='loading-container'>
          <div className='loading-content'>
            <i className='fas fa-spinner fa-spin'></i>
            <p>Loading data...</p>
          </div>
        </div>
      ) : filteredEmployees.length === 0 ? (
        <p className='ed-no-data'>No employees to display</p>
      ) : (
        <div className='employeedata-div-employee-card-list'>
          {filteredEmployees.map((employee) => (
            <EmployeeCard
              key={employee.empId}
              employee={employee}
              handleDeleteEmployee={handleDeleteEmployee}
              handleUpdateEmployee={() => handleUpdateEmployee(employee)}
              handleViewEmployee={() => handleViewEmployee(employee)}
            />
          ))}
        </div>
      )}
      {isModalOpen && (
        <div className={`modal ${modalAnimation}`}>
          <AddEmployeeModal onClose={closeModal} onEmployeeAdded={fetchData} />
        </div>
      )}
      {isUpdateModalOpen && (
        <div className={`modal ${modalAnimation}`}>
          <UpdateEmployeeModal
            onClose={onCloseUpdateEmployeeModal}
            onUpdate={fetchData}
            employee={selectedEmployee}
          />
        </div>
      )}
      {isViewModalOpen && (
        <div className={`modal ${modalAnimation}`}>
          <ViewEmployeeModal
            onClose={onCloseViewEmployeeModal}
            employee={selectedEmployee}
          />
        </div>
      )}
    </div>
  );
};

export default EmployeeData;
