import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { storage } from '../../Firebase';
import { FaCloudUploadAlt } from 'react-icons/fa';
import './UpdateProfile.css';
import logoImage from '../images/trucktronix icon.png';
import iconEmployee from '../../assets/icon_employee.jpg';

const UpdateProfile = () => {
  const navigate = useNavigate();
  const organizationId = sessionStorage.getItem('org_id');
  const [image, setImage] = useState(null);
  const email = sessionStorage.getItem('user_email');
  const [admin, setAdmin] = useState(null);
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);

  /**
   * Set chosen image to a variable
   * @param {*} e
   */
  const handleImgChoose = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };

  //fetch profile details
  const fetchProfile = async () => {
    try {
      const server = process.env.REACT_APP_API_SERVER;
      const username = process.env.REACT_APP_API_USERNAME;
      const password = process.env.REACT_APP_API_PASSWORD;
      const credentials = btoa(`${username}:${password}`);

      const response = await fetch(
        `${server}/orgs/${organizationId}/${email}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Basic ${credentials}`,
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        // console.log("update data: ", data);
        setAdmin(data);
      } else {
        console.error(
          `Error fetching admin data: ${response.status}`,
          response.message
        );
        toast.error('An error occurred while fetching admin data');
      }
    } catch (error) {
      console.error(`Error fetching admin data: ${error.message}`);
      toast.error('An error occurred while fetching admin data');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProfile();
  }, []);

  /**
   * Update the admin details
   */
  const handleUpdate = async () => {
    if (admin.name && admin.email && admin.phone && organizationId) {
      try {
        if (image) {
          // console.log("state image: ", image.name);
          const imageRef = ref(
            storage,
            `admins/${organizationId}/${admin.rego}/` + image.name
          );
          //upload image
          await uploadBytesResumable(imageRef, image);
          //get download url
          const imageUrl = await getDownloadURL(imageRef);
          admin.imageUrl = imageUrl;
        }
        setUpdating(true);
        // const server = "http://localhost:4567";
        const server = process.env.REACT_APP_API_SERVER;
        const username = process.env.REACT_APP_API_USERNAME;
        const password = process.env.REACT_APP_API_PASSWORD;
        const credentials = btoa(`${username}:${password}`);

        // const response = await fetch(`${server}/organization/${email}`, {
        const response = await fetch(
          `${server}/orgs/${organizationId}/${admin.email}`,
          {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Basic ${credentials}`,
            },
            body: JSON.stringify({
              email: admin.email,
              name: admin.name,
              orgName: admin.orgName,
              phone: admin.phone,
              address: admin.address,
              role: admin.role,
              organizationId: organizationId,
              imageUrl: admin.imageUrl || '',
            }),
          }
        );

        if (response.ok) {
          toast.success('Profile details updated successfully');
          navigate(`/my-profile`);
        } else {
          const errorMessage = await response.text(); // or response.json() if it returns JSON
          toast.error(
            `An error occurred while updating profile details: ${errorMessage}`
          );
        }
      } catch (error) {
        toast.error(`Error updating profile: ${error.message}`);
        // console.log(error);
      } finally {
        setUpdating(false);
      }
    } else {
      toast.error('Required parameters are missing!');
    }
  };

  return (
    <div className='update-employee-container'>
      <div className='user-profile-div-header'>
        <img src={logoImage} alt='Trucktronix' className='logo-image' />
        <h4>Update Profile</h4>
      </div>

      <div className='update-employee-body'>
        <div className='fleetmanager-div-body-details-outer-rim'>
          <div className='fleetmanager-div-body-details-inner-rim'>
            {loading ? (
              // <p>Loading...</p>
              <div className='loading-container'>
                <div className='loading-content'>
                  <i className='fas fa-spinner fa-spin'></i>
                  <p>Loading data...</p>
                </div>
              </div>
            ) : admin ? (
              <div className='up-employee-details'>
                <div className='uem-header-container'>
                  <h3>Update Profile</h3>

                  <div className='up-img-container'>
                    <div className='up-uploadContainer'>
                      <label htmlFor='file' className='up-img-label'>
                        {/* Image: */}
                        <FaCloudUploadAlt className='up-icon' />
                      </label>
                      <input
                        type='file'
                        id='file'
                        onChange={handleImgChoose}
                        style={{ display: 'none' }}
                      />
                    </div>
                    <img
                      className='up-img-Emp'
                      src={
                        image
                          ? URL.createObjectURL(image)
                          : admin.imageUrl
                          ? admin.imageUrl
                          : iconEmployee
                      }
                      alt={admin.name || 'Profile'}
                    />
                  </div>
                </div>

                <div className='up-field'>
                  <label>Email:</label>
                  <input
                    disabled
                    type='text'
                    value={admin.email}
                    onChange={(e) =>
                      setAdmin({ ...admin, email: e.target.value })
                    }
                  />
                </div>
                <div className='up-field'>
                  <label>Admin name:</label>
                  <input
                    type='text'
                    value={admin.name}
                    onChange={(e) =>
                      setAdmin({ ...admin, name: e.target.value })
                    }
                  />
                </div>
                {/* <div className='up-field'>
                  <label>Org Name:</label>
                  <input
                    type='text'
                    value={admin.orgName}
                    onChange={(e) =>
                      setAdmin({ ...admin, orgName: e.target.value })
                    }
                  />
                </div> */}

                <div className='up-field'>
                  <label>Phone:</label>
                  <input
                    type='text'
                    value={admin.phone}
                    onChange={(e) =>
                      setAdmin({ ...admin, phone: e.target.value })
                    }
                  />
                </div>
                <div className='up-field'>
                  <label>Address:</label>
                  <input
                    type='text'
                    value={admin.address}
                    onChange={(e) =>
                      setAdmin({ ...admin, address: e.target.value })
                    }
                  />
                </div>
                <div className='up-field'>
                  <label>Role:</label>
                  <input
                    type='text'
                    value={admin.role}
                    onChange={(e) =>
                      setAdmin({ ...admin, role: e.target.value })
                    }
                  />
                </div>
                <div className='up-button-container'>
                  <div
                    className='update-profile-div-btn cancel'
                    onClick={() => navigate(`/my-profile`)}
                  >
                    <div className='update-profile-outer-rim'>
                      <div className='update-profile-inner-rim'>Go Back</div>
                    </div>
                  </div>

                  <div
                    className='update-profile-div-btn add'
                    onClick={handleUpdate}
                  >
                    <div className='update-profile-outer-rim'>
                      <div className='update-profile-inner-rim'>
                        {updating ? 'Updating...' : 'Save'}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <p>No data available.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default UpdateProfile;
